import { Card } from 'lib/components/card/card'
import { fileTypeSrc } from 'lib/ticket/utils'
import { useMemo } from 'react'
import { FilterDropdown } from './filter-dropdown'

interface FormatsFilterProps {
  formats: string[]
  onSelectFormats: (formats: string[]) => void
  selectedFormats: string[]
}

export default function FormatsFilter({ formats, onSelectFormats, selectedFormats }: FormatsFilterProps) {
  const title = useMemo(() => {
    if (!selectedFormats || selectedFormats?.length === 0) return 'Format(s)'
    if (selectedFormats.length <= 2) return selectedFormats.join(', ')
    return `${selectedFormats.slice(0, 2).join(', ')} + ${selectedFormats.length - 2} more`
  }, [selectedFormats])

  return (
    <FilterDropdown title={title} size="lg">
      <FilterDropdown.Content
        onSelectFilter={onSelectFormats}
        filterOptions={formats}
        selectedFilterOptions={selectedFormats}
        title="Format(s)"
      />
    </FilterDropdown>
  )
}

export function SelectableFormat({
  format,
  onClick,
  isSelected,
}: {
  format: string
  onClick: (format: string) => void
  isSelected: boolean
}): JSX.Element {
  const classNames = {
    cardBody: `tw-pt-4`,
    image: `tw-w-20 tw-h-20`,
    cardFooter: `tw-flex tw-flex-col tw-text-center`,
  }

  return (
    <Card size="md" onClick={() => onClick(format)} key={format} isSelected={isSelected} isSquare>
      <Card.Body className={classNames.cardBody} centered>
        <img className={classNames.image} src={fileTypeSrc(format)} alt={format} />
      </Card.Body>
      <Card.Footer className={classNames.cardFooter}>{`.${format}`}</Card.Footer>
    </Card>
  )
}
