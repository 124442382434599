import { baseRequest, BaseResponse, getApi, Metadata } from '../api'

interface AutoAssignerTimeZone {
  id: number
  endTime: string
  name: string
  position: number
  startTime: string
}

export interface AutoAssignerTimeZonesResponse extends BaseResponse {
  autoAssignerTimeZones: AutoAssignerTimeZone[]
  meta: Metadata
}

export async function getAutoAssignerTimeZones(): Promise<AutoAssignerTimeZonesResponse> {
  const autoAssignerTimeZonesUrl = '/api/internal/admin/auto_assigner_time_zones'
  const response = await baseRequest(autoAssignerTimeZonesUrl, getApi)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    autoAssignerTimeZones: data as unknown as AutoAssignerTimeZone[],
    meta: meta as Metadata,
  }
}
