import { ReactElement, useMemo, useState } from 'react'
import { Download } from 'lucide-react'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { GenAiResultTypes, getGenAiResultType } from 'lib/api/gen-ai/gen-ai-requests'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { IconLinkButton } from 'lib/components/buttons/icon-link-button'
import Tooltip from 'lib/components/tooltip/tooltip'
import { filestackCdnUrl } from 'lib/util/filestack'
import { LoadingScreen } from '../../requests/empty-screens'
import CreateAssistOriginalPromptModal from '../ai/modals/create-assist-original-prompt-modal'
import { EditAssistOriginalPromptModal } from '../ai/modals/edit-assist-results-modal'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useMediaContext } from './media-provider'
import MultipageNavigation from 'lib/components/multipage/multipage-navigation'
import MediaViewer from './media-viewer'
import Banner from 'lib/components/banner/banner'

interface MediaContainerProps {
  show: boolean
  showOverflow: boolean
  closeMainContent: () => void
}

const MEDIA_VIEW_CONTAINER_WIDTH = '800px'

const classNames = {
  buttons: {
    button: 'btn btn-icon tw-absolute tw-px-0 tw-cursor-pointer',
    chevron: 'tw-h-6 tw-w-6',
    close: 'tw-bg-white tw-rounded-r-none tw-rounded-l-md tw-border-1 tw-border-r-0 tw-border-gray-200',
    container: 'tw-absolute tw-right-0 tw-top-1/2 tw-z-20',
  },
  mediaView: {
    aside: 'tw-flex-none tw-relative tw-duration-500 tw-bg-gray-50',
    container: 'tw-h-full tw-border-gray-200 tw-border-0 tw-border-r tw-border-solid',
    controls: 'tw-text-right',
    header: `tw-flex tw-items-center tw-justify-between
      tw-py-6 tw-px-4 tw-break-all tw-border-solid tw-border-0 tw-border-b tw-border-gray-200`,
    figure: 'tw-w-full tw-text-center tw-p-0 tw-m-0 tw-relative',
    ticketFileName: 'tw-neutral-800 tw-flex tw-gap-2 tw-whitespace-nowrap tw-items-center',
  },
}

const styles = {
  requestMainCollapsed: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-out',
    width: '0px',
  },

  requestMainExpanded: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-in',
    width: MEDIA_VIEW_CONTAINER_WIDTH,
  },
}

function CloseButton({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <div className={classNames.buttons.container}>
      <button
        type="button"
        onClick={onClick}
        className={`${classNames.buttons.button} ${classNames.buttons.close}`}
        style={{ right: '-1px' }}
      >
        <ChevronLeftIcon className={classNames.buttons.chevron} />
      </button>
    </div>
  )
}

const headerHeight = 100

export default function MediaContainer({ show, showOverflow, closeMainContent }: MediaContainerProps): ReactElement {
  const { extractedPreviewIndex, selectedFile, setExtractedPreviewIndex } = useMediaContext()
  const [showPromptModal, setShowPromptModal] = useState<boolean>(false)

  const { isCollaboratorView } = useAnnotationsContext()

  const currentTicketFile = useMemo(() => {
    if (selectedFile?.isExtractable) {
      if (extractedPreviewIndex === -1 || !selectedFile.extractedPages?.length) {
        return null
      }
      const index = Math.min(extractedPreviewIndex, selectedFile.extractedPages.length)
      return selectedFile.extractedPages[index]
    }
    return selectedFile
  }, [selectedFile, extractedPreviewIndex])

  const bannerHeight = selectedFile?.preview ? 80 : 0

  const genAiResultType: GenAiResultTypes = useMemo(() => getGenAiResultType(selectedFile), [selectedFile])

  const downloadUrl = useMemo(() => {
    if (selectedFile?.handle) {
      return `${filestackCdnUrl}/content=t:attachment/${selectedFile.handle}`
    } else if (selectedFile?.previewUrl && !selectedFile?.previewUrl.includes('file_placeholders')) {
      return `${selectedFile.previewUrl}`
    }
  }, [selectedFile?.handle, selectedFile?.previewUrl])

  return (
    <>
      <aside
        className={`${classNames.mediaView.aside} ${showOverflow ? '' : 'tw-overflow-hidden'}`}
        style={show && !!selectedFile ? styles.requestMainExpanded : styles.requestMainCollapsed}
      >
        {selectedFile && (
          <div
            className={`${classNames.mediaView.container} ${showOverflow ? '' : 'tw-overflow-y-auto'}`}
            style={{ width: MEDIA_VIEW_CONTAINER_WIDTH }}
          >
            {genAiResultType === GenAiResultTypes.CreateAssist && (
              <CreateAssistOriginalPromptModal
                open={showPromptModal}
                setOpen={() => setShowPromptModal(false)}
                requestId={selectedFile.imageableId}
              />
            )}
            {genAiResultType === GenAiResultTypes.EditAssist && (
              <EditAssistOriginalPromptModal
                open={showPromptModal}
                setOpen={() => setShowPromptModal(false)}
                requestId={selectedFile.imageableId}
              />
            )}
            <header className={classNames.mediaView.header}>
              <strong>{selectedFile.name}</strong>
              {selectedFile.isExtractable && selectedFile.extractedPages.length !== 0 && (
                <MultipageNavigation
                  index={extractedPreviewIndex}
                  count={selectedFile.extractedPages.length}
                  setIndex={setExtractedPreviewIndex}
                />
              )}
              {!isCollaboratorView && !selectedFile.preview && downloadUrl && (
                <Tooltip content="Download" direction="up">
                  <IconLinkButton
                    color="transparent"
                    url={downloadUrl}
                    className="tw-mx-1 tw-p-3"
                    download={selectedFile.name}
                    dataTestId="download-file"
                  >
                    <Download className="lu-light lu-sm" />
                  </IconLinkButton>
                </Tooltip>
              )}
              {genAiResultType !== null && (
                <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']} viewportBottomThreshold={230}>
                  <IconFlyoutMenu.Button onClick={() => setShowPromptModal(true)}>
                    View Original Prompt
                  </IconFlyoutMenu.Button>
                </IconFlyoutMenu>
              )}
            </header>
            {selectedFile?.preview && (
              <div className="tw-">
                <Banner type="warning" heading="This is a preview. Your designer will continue working on this soon." />
              </div>
            )}
            {!currentTicketFile && (
              <div className="tw-text-center">
                <LoadingScreen />
              </div>
            )}
            {currentTicketFile && (
              <figure
                className={classNames.mediaView.figure}
                style={{ height: `calc(100% - ${headerHeight + bannerHeight}px)` }}
              >
                <MediaViewer currentTicketFile={currentTicketFile} selectedFile={selectedFile} />
              </figure>
            )}
            <CloseButton onClick={closeMainContent} />
          </div>
        )}
      </aside>
    </>
  )
}
