import Dropdown, { DropdownButton } from 'lib/components/dropdown/dropdown'
import React, { useState } from 'react'
import Search from './search'

export type ValueType = string

export interface OptionType<T> {
  value: ValueType
  label: string
  data?: T
}

interface MultiSelectProps<T> {
  options: OptionType<T>[]
  onChange: (selectedOptions: ValueType[]) => void
  value?: ValueType[]
  searchPlaceholder?: string
  title: string
  optionComponent?: React.FC<OptionType<T>>
  searchable?: boolean
  multi?: boolean
  button?: DropdownButton
  footer?: React.ReactNode
}

const MultiSelect = <T,>({
  options,
  onChange,
  searchPlaceholder = 'Search',
  title,
  optionComponent,
  multi,
  value,
  searchable = true,
  button,
  footer,
}: MultiSelectProps<T>) => {
  const selectedOptions = value || []
  const [search, setSearch] = useState('')

  const handleChange = (selected: ValueType[]) => {
    onChange(selected)
  }

  const handleSelect = (option: OptionType<T>, close: () => void) => {
    if (selectedOptions.find((v) => v === option.value)) {
      handleChange(selectedOptions.filter((v) => v !== option.value))
    } else {
      handleChange([...selectedOptions, option.value])
    }

    if (!multi) {
      close()
    }
  }

  const getOptions = (close: () => void) => {
    return options
      .filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
      .map((option) => {
        const cmp = optionComponent ? optionComponent : () => <div>{option.label}</div>

        return (
          <div className="tw-flex" key={option.value} onClick={() => handleSelect(option, close)}>
            <input readOnly type="checkbox" checked={selectedOptions.includes(option.value)} className="tw-m-1.5" />
            {cmp(option)}
          </div>
        )
      })
  }

  return (
    <Dropdown
      icon="chevron"
      title={title}
      size="auto"
      button={button}
      render={(close) => {
        return (
          <>
            <div>{title}</div>
            {searchable && <Search onChange={setSearch} value={search} placeholder={searchPlaceholder} />}
            {getOptions(close)}
            {footer}
          </>
        )
      }}
    />
  )
}

export default MultiSelect
