import { ReactElement, useCallback, useState } from 'react'
import Button from 'components/core/button'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'
import { ClipboardList, Reply, Send, StickyNote, X } from 'lucide-react'
import IconButton from 'lib/components/buttons/icon-button'
import { useAdminUserContext } from 'providers/admin-user-provider'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { useToastContext } from 'providers/toast-provider'

enum EditorType {
  Reply = 'Reply',
  Note = 'Note',
  Task = 'Task',
}

const classNames = {
  button: {
    default: 'tw-flex tw-items-center tw-gap-2 tw-justify-center',
    active: 'tw-ring-2 tw-ring-cornflower-100 tw-ring-offset-1',
  },
  actionButtonsContainer:
    'tw-flex tw-items-center tw-justify-end tw-gap-2 tw-pt-2 tw-right-12 tw-bottom-20 tw-absolute tw-max-w-27',
  icon: 'lu-light lu-md',
}

export default function AdminTimelineFooter(): ReactElement {
  const [activeEditorType, setActiveEditorType] = useState<EditorType>(null)
  const [editorText, setEditorText] = useState<string>('')

  const { user } = useAdminUserContext()
  const { createConversation, createPrivateDetailTask } = useAdminTimelineContext()
  const { alert, notice } = useToastContext()

  const isAdmin = user.isDPAdmin || user.isDPSuperAdmin
  const canViewButtons = user.isCreative || user.isDPManager || isAdmin

  const handleSubmit = useCallback(async () => {
    try {
      if (activeEditorType === EditorType.Reply || activeEditorType === EditorType.Note) {
        const params = {
          private: activeEditorType === EditorType.Note,
        }

        await createConversation(editorText, params)
      } else if (activeEditorType === EditorType.Task) {
        await createPrivateDetailTask(editorText)
      } else {
        return
      }
      setEditorText('')
      setActiveEditorType(null)
      notice(`${activeEditorType} created successfully`)
    } catch (error) {
      console.error(`Error creating ${activeEditorType.toLowerCase()} `, error)
      alert(`Error creating ${activeEditorType.toLowerCase()}, try again later.`)
    }
  }, [activeEditorType, alert, createConversation, createPrivateDetailTask, editorText, notice])

  function handleEditorTypeChange(type: EditorType) {
    if (activeEditorType === type) {
      setActiveEditorType(null)
    } else {
      setActiveEditorType(type)
    }
  }

  return (
    <div>
      {activeEditorType && (
        <div className="tw-mb-4 tw-overflow-auto tw-max-h-80">
          <WYSIWYGTextarea
            defaultValue={editorText}
            onChange={setEditorText}
            placeholder={`Start typing ${activeEditorType.toLowerCase()}`}
          />
          <div className={classNames.actionButtonsContainer}>
            <IconButton color="secondary" onClick={() => setActiveEditorType(null)} dataTestid="cancel">
              <X className={classNames.icon} />
            </IconButton>
            <IconButton color="primary" onClick={handleSubmit} disabled={editorText.length < 12} dataTestid="submit">
              <Send className={classNames.icon} />
            </IconButton>
          </div>
        </div>
      )}
      {canViewButtons && (
        <div className="tw-flex tw-gap-4">
          {(user.isCreative || isAdmin) && (
            <>
              <Button
                color={activeEditorType === EditorType.Reply ? 'lightPurple' : 'lightGray'}
                onClick={() => handleEditorTypeChange(EditorType.Reply)}
                className={`${classNames.button.default} ${
                  activeEditorType === EditorType.Reply ? classNames.button.active : ''
                }`}
              >
                <Reply className={classNames.icon} />
                <div>Reply</div>
              </Button>
              <Button
                color={activeEditorType === EditorType.Note ? 'lightPurple' : 'lightGray'}
                onClick={() => handleEditorTypeChange(EditorType.Note)}
                className={`${classNames.button.default} ${
                  activeEditorType === EditorType.Note ? classNames.button.active : ''
                }`}
              >
                <StickyNote className={classNames.icon} /> Note
              </Button>
            </>
          )}
          {(user.isDPManager || isAdmin) && (
            <Button
              color={activeEditorType === EditorType.Task ? 'lightPurple' : 'lightGray'}
              onClick={() => handleEditorTypeChange(EditorType.Task)}
              className={`${classNames.button.default} ${
                activeEditorType === EditorType.Task ? classNames.button.active : ''
              }`}
            >
              <ClipboardList className={classNames.icon} /> Private task
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
