import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'

import { Ticket } from 'interfaces/ticket'
import QueueTypePlaceholder, { Placeholder } from 'components/core/queue-type-placeholder'
import CompanyTaggingProvider, { ChangeTagAction } from 'providers/company-tagging-provider'
import CompanyTaggingPopover from 'components/elements/company-tagging/company-tagging-popover'

import styles from './table-content.module.scss'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import { CompanyTag } from 'lib/api/company-tags/company-tags'
import { useEffect, useState } from 'react'

interface TicketLinkElementProps {
  ticket: Ticket
  showTags: boolean
}

export default function TicketLinkElement({ ticket, showTags }: TicketLinkElementProps): JSX.Element {
  const [selectedTags, setSelectedTags] = useState<CompanyTag[]>(ticket.tags)

  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { updateTicketsTags } = useSingleQueueContext()

  const { subject, skillName, url, thumbnail, tags } = ticket

  const ticketId = ticket.status === 'draft' ? ticket.ticketId : ticket.id

  async function updateSelectedTagsOnTicket(tagToUpdate: CompanyTag, action: ChangeTagAction) {
    if (action === ChangeTagAction.Select) {
      setSelectedTags([...selectedTags, tagToUpdate])
    } else if (action === ChangeTagAction.Unselect) {
      setSelectedTags(selectedTags.filter((tag) => tag.id !== tagToUpdate.id))
    } else {
      updateTicketsTags(tagToUpdate, action)
    }
  }

  useEffect(() => {
    setSelectedTags(tags)
  }, [tags])

  return (
    <div className="tw-flex tw-items-center">
      <a href={url} className="tw-text-eggplant hover:tw-text-eggplant-600">
        <div className={styles.thumbnailContainer}>
          {thumbnail.placeholder ? (
            <QueueTypePlaceholder
              fileName={thumbnail.url as Placeholder}
              className={styles.placeholderImage}
              color={'#ADA6AD'}
            />
          ) : (
            <img className={styles.thumbnailImage} src={thumbnail.url} alt="thumbnail" />
          )}
        </div>
      </a>
      <div>
        <a href={url} className="tw-text-eggplant hover:tw-text-eggplant-600">
          <div className="tw-align-middle">{subject || `Untitled ${skillName}`}</div>
        </a>
        {showTags && isFeatureFlagEnabled('company_tags') && (
          <CompanyTaggingProvider
            selectedTags={selectedTags}
            taggableId={Number(ticketId)}
            taggableType="Ticket"
            updateTaggableState={updateSelectedTagsOnTicket}
          >
            <CompanyTaggingPopover type="large" />
          </CompanyTaggingProvider>
        )}
      </div>
    </div>
  )
}
