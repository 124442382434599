import { Switch } from '@headlessui/react'

const classNames = {
  switch: {
    disabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-neutral-200',
    enabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-gherkin-500',
  },
  switchGroup: 'tw-flex tw-items-center',
  switchLabel: 'tw-mr-1 tw-mb-0 tw-text-black tw-uppercase',
  switchToggle: {
    disabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw--translate-x-1',
    enabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-translate-x-4',
  },
  switchWrapper: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md tw-grid tw-grid-cols-3 tw-gap-4',
}

interface SourceSwitchProps {
  source: string
  setSource: (value: string) => void
}

export default function SourceSwitch({ source, setSource }: SourceSwitchProps) {
  return (
    <div className={classNames.switchWrapper}>
      <Switch.Group>
        <div className={classNames.switchGroup}>
          <Switch.Label className={classNames.switchLabel}>Source</Switch.Label>
          <Switch
            checked={source === 'true'}
            onChange={(value) => setSource(value.toString())}
            className={source === 'true' ? classNames.switch.enabled : classNames.switch.disabled}
            style={{ width: '38px', height: '18px' }}
          >
            <span className="sr-only">{source}</span>
            <span
              className={source === 'true' ? classNames.switchToggle.enabled : classNames.switchToggle.disabled}
              aria-hidden="true"
              style={{
                width: '14px',
                height: '14px',
                transform: `translateX(${source === 'true' ? '14px' : '-4px'})`,
              }}
            ></span>
          </Switch>
        </div>
      </Switch.Group>
    </div>
  )
}
