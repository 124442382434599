import { useEffect } from 'react'

import { CompanyTag } from 'lib/api/company-tags/company-tags'
import TagInput, { Tag } from 'lib/components/tag-input/tag-input'

import { useCompanyTaggingContext } from 'providers/company-tagging-provider'
import CompanyTaggingSearchResults from './company-tagging-search-results'
import { errorMessage } from './input-validation'

export default function CompanyTaggingTypeahead() {
  const { createAndSelectCompanyTag, searchResults, searchTerm, selectedTags, setSearchTerm, unselectCompanyTag } =
    useCompanyTaggingContext()

  const errorMessageText = errorMessage(searchTerm)

  const selectedTagsAsTags: Tag[] = selectedTags.map((tag) => ({ id: tag.id, name: tag.name }))

  async function handleSubmit() {
    if (searchResults.length > 0 || !searchTerm || !!errorMessageText) return
    await createAndSelectCompanyTag(searchTerm)
  }

  async function handleRemoveTag(tag: Tag) {
    await unselectCompanyTag(tag as CompanyTag)
  }

  useEffect(() => {
    return () => {
      setSearchTerm('')
    }
  }, [setSearchTerm])

  return (
    <div>
      <h4>Tags</h4>
      <div className="tw-max-h-40 tw-overflow-y-auto">
        <TagInput
          focusOnMount
          selectedTags={selectedTagsAsTags}
          removeTag={handleRemoveTag}
          handleSubmit={handleSubmit}
          inputValue={searchTerm}
          onInputChange={setSearchTerm}
          placeholder="Search for a tag..."
          tagColor="primary"
          error={!!errorMessageText}
        />
      </div>
      <CompanyTaggingSearchResults />
      {errorMessageText && <p className="tw-text-red-500 tw-text-sm tw-mb-2 tw-px-2">{errorMessageText}</p>}
    </div>
  )
}
