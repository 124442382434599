import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'
import { CurrentUser } from 'interfaces/app'
import { Drawer } from 'lib/components/drawer/drawer'
import { createTicket, updateTicket } from 'lib/api/tickets/tickets'
import SkillCategories from '../skills/skill-categories'
import SkillsProvider from '../skills/skills-provider'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import SearchSkillsByName from '../skills/search-skills-by-name'
import React from 'react'

interface NewRequestButtonProps {
  user: CurrentUser
  type?: 'button' | 'link'
  text?: string
}

async function createAndRedirect(skill, preferredFormats): Promise<void> {
  const payload = {
    skill_id: skill.id,
  }
  const { error, ticket } = await createTicket(payload)

  if (error) {
    console.error('The ticket could not be created.', payload, error)
    throw new Error(error as string)
  }

  // @ts-ignore
  const preferredAvailableFormats = ticket.availableFormats.filter((format) => preferredFormats.includes(format))
  if (preferredAvailableFormats.length > 0) {
    try {
      // @ts-ignore
      await updateTicket(ticket.id, { formats: preferredAvailableFormats })
    } catch (updateError) {
      console.error('The ticket could not be updated.', updateError)
    }
  }

  window.location.assign(`/tickets/${ticket.id}`)
}

function OpenModalButton({
  setIsOpen,
  type,
  text,
}: {
  setIsOpen: (isOpen: boolean) => void
  type: string
  text: string
}) {
  const linkClassName = `
    tw-outline-none
    tw-border-none
    tw-bg-transparent
    tw-font-semibold
    tw-text-cornflower-500
    hover:tw-text-cornflower-700
  `

  function openModal() {
    setIsOpen(true)
  }

  if (type === 'link') {
    return (
      <button type="button" className={linkClassName} onClick={openModal}>
        {text}
      </button>
    )
  }

  return (
    <Button type="button" onClick={openModal} rounded>
      <FontAwesomeIcon icon={['fal', 'plus']} className="tw-mr-2" />
      {text}
    </Button>
  )
}

export default function NewRequestButton({
  user,
  type = 'button',
  text = 'New Request',
}: NewRequestButtonProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  async function handleSelect(skill) {
    try {
      setIsOpen(false)
      setIsLoading(true)

      await createAndRedirect(skill, user.preferredFormats)
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  return (
    <>
      {isLoading ? <LoadingScreen size="xl" /> : <OpenModalButton type={type} text={text} setIsOpen={setIsOpen} />}
      <SkillsProvider>
        <Drawer isOpen={isOpen} setOpen={setIsOpen} size="xl">
          <Drawer.Header setOpen={setIsOpen}>
            <Drawer.Title className="tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-full">
              <div>
                What are you looking to <span className="tw-font-bold">create</span>?
              </div>
              <SearchSkillsByName />
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>{isOpen && <SkillCategories onSelect={handleSelect} size="lg" showOtherSkills />}</Drawer.Body>
        </Drawer>
      </SkillsProvider>
    </>
  )
}
