import { Skill } from 'interfaces/skill'
import { baseRequest, BaseResponse, deleteApi, getApi, Metadata, postApi, putApi } from 'lib/api/api'

export const routes = {
  edit: (id: number) => `/admin/skills/${id}/edit`,
  index: '/admin/skills',
  new: '/admin/skills/new',
}

const apiRoutes = {
  create: '/api/internal/admin/skills',
  destroy: (id: number) => `/api/internal/admin/skills/${id}`,
  index: '/api/internal/admin/skills',
  update: (id: number) => `/api/internal/admin/skills/${id}`,
}

export interface SkillsResponse extends BaseResponse {
  skills: Skill[]
  meta: Metadata
}

export async function getSkills(params): Promise<SkillsResponse> {
  const response = await baseRequest(apiRoutes.index, getApi, params)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    skills: data as unknown as Skill[],
    meta: meta as Metadata,
  }
}

export async function updateSkill(id, params) {
  return await baseRequest(apiRoutes.update(id), putApi, params)
}

export async function createSkill(params) {
  return await baseRequest(apiRoutes.create, postApi, params)
}

export async function destroySkill(id) {
  return await baseRequest(apiRoutes.destroy(id), deleteApi)
}
