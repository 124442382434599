import { useEffect, useRef } from 'react'
import { ArrowLeft, X } from 'lucide-react'

import IconButton from 'lib/components/buttons/icon-button'

import { useCompanyTaggingContext, ViewStates } from 'providers/company-tagging-provider'
import CompanyTaggingTypeahead from './company-tagging-typeahead'
import EditTag from './edit-company-tag'
import { DeleteCompanyTag } from './delete-company-tag'

interface CompanyTagsProps {
  closePopover: () => void
}

export default function CompanyTags({ closePopover }: CompanyTagsProps) {
  const { currentView, setCurrentView } = useCompanyTaggingContext()
  const containerRef = useRef<HTMLDivElement>()

  function handleBackClick() {
    currentView.viewState === ViewStates.Edit
      ? setCurrentView({ viewState: ViewStates.Search, tag: null })
      : setCurrentView({ viewState: ViewStates.Edit, tag: currentView.tag })
  }

  function handleClose() {
    setCurrentView({ viewState: ViewStates.Search, tag: null })
    closePopover()
  }

  useEffect(() => {
    return () => {
      setCurrentView({ viewState: ViewStates.Search })
    }
  }, [setCurrentView])

  return (
    <div ref={containerRef}>
      {currentView.viewState === ViewStates.Search && <CompanyTaggingTypeahead />}

      {(currentView.viewState === ViewStates.Edit || currentView.viewState === ViewStates.Delete) && (
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <IconButton color="secondary" size="sm" onClick={handleBackClick} dataTestid="back">
            <ArrowLeft className="lu-md" />
          </IconButton>
          <h4>{currentView.viewState} Tag</h4>
          <IconButton color="secondary" size="sm" onClick={handleClose} dataTestid="cancel">
            <X className="lu-md" />
          </IconButton>
        </div>
      )}

      {currentView.viewState === ViewStates.Edit && currentView.tag && <EditTag tag={currentView.tag} />}
      {currentView.viewState === ViewStates.Delete && currentView.tag && <DeleteCompanyTag tag={currentView.tag} />}
    </div>
  )
}
