import { bindJWT, patchApi } from '../api'
import { snakeCaseKeys } from 'lib/object/utils'
import { CompanyTag } from '../company-tags/company-tags'

const baseUrl = '/api/internal/company_taggings'

export type TaggableType = 'Ticket'

export interface CompanyTagging {
  taggableType: TaggableType
  taggableId: number
  companyTagId?: number
  companyTag?: CompanyTag
}

export async function updateCompanyTagging(tags: CompanyTagging[], jwt = null): Promise<CompanyTagging[]> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const snakeCaseTags = tags.map((tag) => snakeCaseKeys(tag))

  const response = await patchWithJWT(`${baseUrl}`, {
    company_taggings: snakeCaseTags,
  })

  return response.data.data
}
