import { ReactElement, ReactNode } from 'react'

import { CompanyTag } from 'lib/api/company-tags/company-tags'
import Badge from 'lib/components/badge/badge'

interface CompanyTaggingPopoverTitleProps {
  selectedTags: CompanyTag[]
  emptyTagTitleElement: ReactNode
}

export default function CompanyTaggingPopoverTitle({
  selectedTags,
  emptyTagTitleElement,
}: CompanyTaggingPopoverTitleProps): ReactElement {
  const maxCharacters = 75

  if (selectedTags.length === 0) {
    return <>{emptyTagTitleElement}</>
  }

  const numberOfCharacters = selectedTags.reduce((acc, tag) => acc + tag.name.length, 0)

  if (selectedTags.length <= 5) {
    return <SelectedTags selectedTags={selectedTags.slice(0, 5)} truncateText={numberOfCharacters > maxCharacters} />
  }

  const truncatedTags = selectedTags.slice(0, 5)
  const truncatedTagsLength = truncatedTags.reduce((acc, tag) => acc + tag.name.length, 0)

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
      <SelectedTags selectedTags={truncatedTags} truncateText={truncatedTagsLength > maxCharacters} />
      <span className="tw-text-sm tw-text-neutral-500">+ {selectedTags.length - 5} more</span>
    </div>
  )
}

interface SelectedTagsProps {
  selectedTags: CompanyTag[]
  truncateText?: boolean
}

function SelectedTags({ selectedTags, truncateText }: SelectedTagsProps) {
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      {selectedTags.map((tag) => (
        <Badge color="light" key={tag.id}>
          {truncateText && tag.name.length > 15 ? `${tag.name.slice(0, 15)}...` : tag.name}
        </Badge>
      ))}
    </div>
  )
}
