import { SkillSize } from 'interfaces/skill-size'
import { baseRequest, BaseResponse, getApi, Metadata, postApi, putApi } from 'lib/api/api'

export const skillSizeRoutes = {
  edit: (id: number, skillId: number) => `/admin/skills/${skillId}/skill_sizes/${id}/edit`,
  index: (skillId: number) => `/admin/skills/${skillId}/skill_sizes`,
  new: (skillId: number) => `/admin/skills/${skillId}/skill_sizes/new`,
}

const apiRoutes = {
  index: (skillId: number) => `/api/internal/admin/skills/${skillId}/skill_sizes`,
  create: (skillId: number) => `/api/internal/admin/skills/${skillId}/skill_sizes`,
  update: (skillSizeId: number) => `/api/internal/admin/skill_sizes/${skillSizeId}`,
}

export interface SkillSizesResponse extends BaseResponse {
  skillSizes: SkillSize[]
  meta: Metadata
}

export async function getSkillSizes(skillId, params): Promise<SkillSizesResponse> {
  const response = await baseRequest(apiRoutes.index(skillId), getApi, params)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    skillSizes: data as unknown as SkillSize[],
    meta: meta as Metadata,
  }
}

export async function updateSkillSize(skillSizeId, params): Promise<BaseResponse> {
  const response = await baseRequest(apiRoutes.update(skillSizeId), putApi, params)

  return {
    error: response.error,
    status: response.status,
  }
}

export async function createSkillSize(skillId, params): Promise<BaseResponse> {
  const response = await baseRequest(apiRoutes.create(skillId), postApi, params)

  return {
    error: response.error,
    status: response.status,
  }
}
