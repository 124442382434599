import { ReactElement } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/solid'

import { useAdminMediaContext } from '../providers/admin-media-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminMediaViewer from './admin-media-viewer'
import AdminMediaViewerHeader from './admin-media-viewer-header'
import { TriangleAlert } from 'lucide-react'

const MEDIA_VIEW_CONTAINER_WIDTH = '800px'

const classNames = {
  buttons: {
    button: 'btn btn-icon tw-absolute tw-px-0 tw-cursor-pointer',
    chevron: 'tw-h-6 tw-w-6',
    close: 'tw-bg-white tw-rounded-r-none tw-rounded-l-md tw-border-1 tw-border-r-0 tw-border-gray-200',
    container: 'tw-absolute tw-right-0 tw-top-1/2 tw-z-20',
  },
  mediaView: {
    aside: 'tw-flex-none tw-relative tw-duration-500 tw-bg-gray-50',
    container: 'tw-h-full tw-border-gray-200 tw-border-0 tw-border-r tw-border-solid tw-flex tw-flex-col',
    controls: 'tw-text-right',
    figure: 'tw-w-full tw-text-center tw-p-0 tw-m-0 tw-relative',
    metadataContainer: 'tw-flex tw-items-center tw-justify-center tw-gap-2 tw-w-full tw-pt-4',
    metadataItem: 'tw-flex tw-items-center tw-justify-center tw-gap-2',
    figureVideo: 'tw-object-contain tw-w-full tw-h-full',
  },
}

const styles = {
  requestMainCollapsed: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-out',
    width: '0px',
  },

  requestMainExpanded: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-in',
    width: MEDIA_VIEW_CONTAINER_WIDTH,
  },
}

function CloseButton({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <div className={classNames.buttons.container}>
      <button
        type="button"
        onClick={onClick}
        className={`${classNames.buttons.button} ${classNames.buttons.close}`}
        style={{ right: '-1px' }}
      >
        <ChevronLeftIcon className={classNames.buttons.chevron} />
      </button>
    </div>
  )
}

export default function AdminMediaContainer(): ReactElement {
  const { currentSelectedPage, selectedFile } = useAdminMediaContext()
  const { currentView, setMainContent } = useAdminTicketContext()

  return (
    <>
      <aside
        className={`${classNames.mediaView.aside} ${
          currentView.mainContent === 'showWithOverflow' ? '' : 'tw-overflow-hidden'
        }`}
        style={currentView.mainContent && !!selectedFile ? styles.requestMainExpanded : styles.requestMainCollapsed}
      >
        {selectedFile && (
          <div
            className={`${classNames.mediaView.container} ${
              currentView.mainContent === 'showWithOverflow' ? '' : 'tw-overflow-y-auto'
            }`}
            style={{ width: MEDIA_VIEW_CONTAINER_WIDTH }}
          >
            {selectedFile && selectedFile.state === 'rejected' && (
              <div
                className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-bg-yellow-50 tw-text-yellow-800 tw-font-semibold tw-p-4 tw-justify-center"
                data-testid="rejectedNotice"
              >
                <TriangleAlert size="16" />
                {"This file is kept for record and won't be delivered to the client."}
              </div>
            )}
            {selectedFile && <AdminMediaViewerHeader />}
            {selectedFile && <AdminMediaViewer currentTicketFile={currentSelectedPage} selectedFile={selectedFile} />}
            <CloseButton onClick={() => setMainContent(null)} />
          </div>
        )}
      </aside>
    </>
  )
}
