import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useToastContext } from 'providers/toast-provider'
import { featureFlags, features, getFeatureFlags } from 'lib/api/feature-flags/feature-flags'

interface FeatureFlagsContextProps {
  children: ReactNode
}

export type FeatureFlagsContextValue = {
  isFeatureFlagEnabled: (key: features) => boolean
}

const FeatureFlagsContext = createContext({})

export function useFeatureFlagsContext() {
  return useContext(FeatureFlagsContext) as FeatureFlagsContextValue
}

export default function FeatureFlagsProvider({ children }: FeatureFlagsContextProps) {
  const [featureFlags, setFeatureFlags] = useState<featureFlags>()

  const { alert } = useToastContext()

  useEffect(() => {
    async function featchFeatureFlags() {
      try {
        const features = await getFeatureFlags()
        setFeatureFlags(features)
      } catch (error) {
        console.error('Error fetching feature flags', error)
        alert('Oops, something went wrong, try again later')
      }
    }
    featchFeatureFlags()
  }, [alert])

  function isFeatureFlagEnabled(key: string): boolean {
    if (!featureFlags) {
      return false
    }

    return Boolean(featureFlags[key])
  }

  const contextValue: FeatureFlagsContextValue = {
    isFeatureFlagEnabled,
  }

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>
}
