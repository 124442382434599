import { ReactElement, useCallback, useEffect, useRef } from 'react'

import { AppSettings } from 'interfaces/app'
import { AdminUser } from 'providers/admin-user-provider'

import AdminApp from 'components/pages/admin/admin-app/admin-app'
import AdminHeader from './header/admin-header'
import AdminRightPanel from './right-panel/admin-right-panel'
import AdminMediaSidebar from './left-panel/admin-media-sidebar'
import AdminMediaContainer from './center-content/admin-media-container'
import AdminTicketProvider, { useAdminTicketContext } from './providers/admin-ticket-provider'
import AdminMediaProvider, { useAdminMediaContext } from './providers/admin-media-provider'
import ToastProvider from 'providers/toast-provider'
import AdminTimelineProvider from './providers/admin-timeline-provider'
import AdminAnnotationHighlightProvider from './providers/admin-annotation-highlight-provider'

interface AdminTicketViewProps {
  settings: AppSettings
  user: AdminUser
}

const classNames = {
  container: 'tw-h-screen tw-w-screen tw-grid tw-grid-cols-1 tw-grid-rows-2 tw-bg-white',
  main: 'tw-flex tw-flex-row tw-overflow-hidden tw-border-0 tw-border-t tw-border-solid tw-border-gray-200',
}

function AdminTicketContent(): ReactElement {
  const { selectedFile } = useAdminMediaContext()

  const { setMainContent } = useAdminTicketContext()
  const timeoutRef = useRef<number>(null)

  const open = useCallback(() => {
    setMainContent('show')
    timeoutRef.current = window.setTimeout(() => {
      setMainContent('showWithOverflow')
    }, 750)
  }, [setMainContent])

  const close = useCallback(() => {
    setMainContent(null)
  }, [setMainContent])

  useEffect(() => {
    if (selectedFile) {
      open()
    } else {
      close()
    }
  }, [close, open, selectedFile])

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  return (
    <div className={classNames.container} style={{ gridTemplateRows: 'min-content auto' }}>
      <AdminHeader />
      <main className={classNames.main}>
        <AdminMediaSidebar />
        <AdminMediaContainer />
        <AdminRightPanel />
      </main>
    </div>
  )
}

export default function AdminTicketView({ settings, user }: AdminTicketViewProps): ReactElement {
  return (
    <AdminApp settings={settings} user={user}>
      <ToastProvider>
        <AdminTicketProvider>
          <AdminMediaProvider>
            <AdminTimelineProvider>
              <AdminAnnotationHighlightProvider>
                <AdminTicketContent />
              </AdminAnnotationHighlightProvider>
            </AdminTimelineProvider>
          </AdminMediaProvider>
        </AdminTicketProvider>
      </ToastProvider>
    </AdminApp>
  )
}
