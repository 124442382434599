import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const classNames = {
  container: `
      tw-flex tw-justify-center
    `,
  input: `
      tw-block
      tw-appearance-none
      tw-rounded-md
      tw-border
      tw-border-solid
      tw-p-2
      tw-pl-10
      tw-shadow-none
      tw-outline-none
      tw-mt-4
      tw-text-lg
      tw-w-80
      placeholder:tw-text-neutral-500
      focus:tw-ring-2
      focus:tw-ring-cornflower-500
    `,
  icon: 'tw-text-neutral-500 tw-relative tw-top-7 tw-left-8 tw-w-5 tw-h-5',
}

interface SearchProps {
  value: string
  onChange: (string: string) => void
  placeholder: string
  name?: string
}

export default function Search({ value, onChange, placeholder = 'Search', name = 'search' }: SearchProps) {
  return (
    <div className={classNames.container}>
      <FontAwesomeIcon icon={['far', 'search']} className={classNames.icon} />
      <input
        name={name}
        placeholder={placeholder}
        className={classNames.input}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}
