import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState, MouseEvent } from 'react'
import { Send, X } from 'lucide-react'

import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import Textarea from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'

import { useAnnotationsContext } from '../providers/annotations-provider'

interface ImageAnnotationFormProps {
  ticketFile: TicketFile
}

export default function ImageAnnotationForm({ ticketFile }: ImageAnnotationFormProps): ReactElement {
  const textAreaRef = useRef(null)
  const [showError, setShowError] = useState(false)
  const [currentEditAnnotationBodyText, setCurrentEditAnnotationBodyText] = useState('')
  const { addAnnotation, cancelAnnotation } = useAnnotationsContext()

  function onSendClick(event: MouseEvent) {
    event.stopPropagation()
    submit()
  }

  function onCancelClick(event: MouseEvent) {
    event.stopPropagation()

    cancelAnnotation()
  }

  function submit() {
    if (currentEditAnnotationBodyText.trim().length <= 3) {
      setShowError(true)
      return
    }

    addAnnotation(ticketFile, currentEditAnnotationBodyText)
  }

  function handleOnChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setCurrentEditAnnotationBodyText(event.target.value)
    setShowError(false)
  }

  useEffect(() => {
    // This is a hack to get the textarea to focus when the annotation is created
    // and the annotation pane is not opened yet
    const timeout = setTimeout(() => {
      textAreaRef.current.focus()
    }, 100)

    return () => clearTimeout(timeout)
  }, [])

  async function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      submit()
    } else if (e.key === 'Escape') {
      cancelAnnotation()
    }
  }

  return (
    <div className="tw-flex tw-items-center">
      <Textarea
        className="tw-mt-2"
        value={currentEditAnnotationBodyText}
        error={showError}
        onChange={handleOnChange}
        placeholder="Add Annotation here"
        ref={textAreaRef}
        onKeyDown={onKeyDown}
        onClick={(e) => e.stopPropagation()}
      />
      <div className="tw-flex tw-items-center tw-w-26">
        <IconButton color="secondary" className="tw-ml-2" onClick={onCancelClick} dataTestid="cancel-button">
          <X className="lu-light lu-md" />
        </IconButton>
        <IconButton color="primary" className="tw-ml-2" onClick={onSendClick} dataTestid="send-button">
          <Send className="lu-light lu-md" />
        </IconButton>
      </div>
    </div>
  )
}
