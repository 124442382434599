import { useMemo } from 'react'
import Button from 'components/core/button'
import OpenButton from 'components/elements/open-button'
import { assetsDownloadUrl, DesignRequestStatus, EditMode } from 'lib/api/tickets/tickets'
import MediaList from './media-list'
import MediaUploaders from './media-uploaders'
import { useMediaContext } from './media-provider'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import { generateRangeTo } from 'lib/util/range'

interface MediaLeftSidebarProps {
  showMainContent: boolean
  openMainContent: () => void
}

const classNames = {
  aside:
    'tw-flex-none tw-w-60 tw-relative tw-bg-gray-50 tw-flex tw-flex-col tw-border-r-gray-200 tw-border-0 tw-border-r tw-border-solid',
  header: 'tw-flex-none tw-h-min tw-m-4 tw-mb-0',
  h2: 'tw-text-base tw-mb-1',
  container: 'tw-flex-auto tw-overflow-y-scroll',
  footer: 'tw-flex-none tw-h-min tw-p-5 tw-bg-gray-50',
}

export default function MediaLeftSidebar({ showMainContent, openMainContent }: MediaLeftSidebarProps) {
  const { editMode, files, selectedFile, selectedVersion, setSelectedVersion, showRevisions, ticket } =
    useMediaContext()

  const showDownloadButton = useMemo(() => {
    const visibleFiles = selectedVersion ? files.filter((file) => file.ticketVersion === selectedVersion) : files
    return visibleFiles.findIndex((ticket) => !ticket.preview) !== -1
  }, [files, selectedVersion])

  const versions = useMemo(() => {
    return generateRangeTo(ticket?.lastDeliveredVersion)
  }, [ticket?.lastDeliveredVersion])

  function handleVersionChange(version: number) {
    setSelectedVersion(version)
  }

  async function handleDownloadAllAssetsClick() {
    try {
      const url = await assetsDownloadUrl(ticket?.id, selectedVersion || null)

      if (url) {
        window.open(url, '_blank')
      }
    } catch (e) {
      console.error('Error getting assets download url', e)
    }
  }

  function showMediaUploaders() {
    const isSelectedVersionDelivered = selectedVersion === ticket?.lastDeliveredVersion
    const isEditModeProcessing = editMode === EditMode.processing
    const isEditModeComplete = editMode === EditMode.complete
    const isEditModeDraft = editMode === EditMode.draft
    const needsAttention = ticket.status === DesignRequestStatus.attention

    return (
      (isSelectedVersionDelivered && !isEditModeProcessing && !isEditModeComplete) || isEditModeDraft || needsAttention
    )
  }

  return (
    <aside className={classNames.aside}>
      <header className={classNames.header}>
        <h2 className={classNames.h2}>{showRevisions ? 'Delivered Assets' : 'Media'}</h2>
        {showRevisions && !!files.length ? (
          <>
            <TicketVersionSelectBox
              versions={versions}
              selectedVersion={selectedVersion}
              setSelectedVersion={handleVersionChange}
            />
            <hr className="tw-bg-neutral-400" />
            {showDownloadButton && (
              <Button
                type="button"
                color="lightGray"
                className="tw-w-full tw-font-bold tw-mb-3"
                onClick={handleDownloadAllAssetsClick}
              >
                Download Assets
              </Button>
            )}
          </>
        ) : (
          <br />
        )}
        {!showRevisions && (
          <>
            <p>
              Upload images and/or files and use our annotation tool to provide contextual directions for your designer.
            </p>
            <hr className="tw-bg-neutral-400" />
          </>
        )}
      </header>
      <div className={classNames.container}>
        <MediaList />
      </div>
      {showMediaUploaders() && (
        <footer className={classNames.footer}>
          <p className="tw-font-semibold tw-text-neutral-800 tw-mb-2 tw-text-sm">Need to add additional media?</p>
          <MediaUploaders />
        </footer>
      )}
      {!showMainContent && !!selectedFile && <OpenButton onClick={openMainContent} />}
    </aside>
  )
}
