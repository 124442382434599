import { useCallback, useEffect, useState } from 'react'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import { Skill } from 'interfaces/skill'
import { destroySkill, getSkills, routes } from 'lib/api/admin/skills/skills'
import { snakeCaseKeys } from 'lib/object/utils'
import Pagination from 'components/elements/pagination'
import AdminSkillsPageFilters from './admin-skills-page-filters'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import { LinkButton } from 'components/core/button'
import { skillSizeRoutes } from 'lib/api/admin/skill-sizes/skill-sizes'
import IconButton from 'lib/components/buttons/icon-button'
import { Trash2 } from 'lucide-react'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  header: 'tw-flex tw-justify-between navbar-custom',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white tw-rounded-lg tw-shadow-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

export interface Filters {
  nameCont?: string
}

export const emptyFilters: Filters = {
  nameCont: '',
}

function AdminSkills() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [count, setCount] = useState<number>(0)
  const [filters, setFilters] = useState<Filters>(emptyFilters)
  const [skills, setSkills] = useState<Skill[]>([])
  const { alert, notice } = useToastContext()

  useEffect(() => {
    fetchSkills(currentPage, filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const fetchSkills = useCallback(
    async (currentPage: number, filters: Filters) => {
      setIsLoading(true)
      try {
        const { skills, meta } = await getSkills({ page: currentPage + 1, q: snakeCaseKeys(filters) })
        setSkills(skills)
        setPageCount(Math.ceil(meta.total / meta.pageSize))
        setCount(meta.total)
      } catch (error) {
        console.error(error)
        alert('Failed to fetch skills')
      } finally {
        setIsLoading(false)
      }
    },
    [alert]
  )

  function onPageClick({ selected }) {
    setCurrentPage(selected)
    window.scrollTo(0, 0)
  }

  function handleSubmit(e) {
    e.preventDefault()
    fetchSkills(currentPage, filters)
  }

  function handleClear(e) {
    e.preventDefault()
    setFilters(emptyFilters)
    fetchSkills(0, emptyFilters)
  }

  const deleteConfirmationMessage =
    'This action soft deletes the skill, \
all associated records will exist but the skill will not be visible to users.\n \
Are you sure you want to delete this skill?'

  async function handleDestroy(e, skillId) {
    e.preventDefault()
    if (confirm(deleteConfirmationMessage) == true) {
      setIsLoading(true)
      try {
        const { status } = await destroySkill(skillId)

        if (status === 200) {
          notice('Skill deleted successfully')
          setSkills(skills.filter(({ id }) => id !== skillId))
        }
      } catch (error) {
        console.error(error)
        alert('Failed to delete skill')
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Skills</h4>
        {!isLoading && `Showing ${skills.length} out of ${count} skills`}
      </div>
      <div className={classNames.container}>
        <div className="tw-mb-4 tw-flex tw-justify-between tw-items-center">
          <AdminSkillsPageFilters
            filters={filters}
            setFilters={setFilters}
            onSubmit={handleSubmit}
            handleClear={handleClear}
          />
          <LinkButton url={routes.new} className="tw-my-2">
            Add Skill
          </LinkButton>
        </div>
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <TableHeader />
            <tbody>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                skills.map(
                  ({ id, imageUrl, name, skillSizes, formats, averageTime, skillCategoryName, scopeOfService }) => (
                    <tr key={id} className={classNames.tableRow}>
                      <td className={classNames.tableCell}>
                        <img src={imageUrl} height="50px" />
                      </td>
                      <td className={classNames.tableCell}>
                        <a href={routes.edit(id)}>{name}</a>
                      </td>
                      <td className={classNames.tableCell}>
                        <a href={skillSizeRoutes.index(id)}>View Sizes</a>
                        <p>{skillSizesDetails(skillSizes)}</p>
                      </td>
                      <td className={classNames.tableCell}>{skillCategoryName}</td>
                      <td className={classNames.tableCell}>{scopeOfService}</td>
                      <td className={classNames.tableCell}>{formatNames(formats)}</td>
                      <td className={classNames.tableCell}>{averageTime}</td>
                      <td className={classNames.tableCell}>
                        <IconButton color="danger" size="xs" onClick={(e) => handleDestroy(e, id)}>
                          <Trash2 />
                        </IconButton>
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
        <Pagination pageCount={pageCount} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    </div>
  )
}

const skillSizesDetails = (skillSizes) => skillSizes.map(({ details }) => details).join(', ')
const formatNames = (formats) => formats.map(({ name }) => name).join(', ')

const TableHeader = () => (
  <thead>
    <tr>
      <th className={classNames.tableHeadCell}></th>
      <th className={classNames.tableHeadCell}>Name</th>
      <th className={classNames.tableHeadCell}>Sizes</th>
      <th className={classNames.tableHeadCell}>Category</th>
      <th className={classNames.tableHeadCell}>Scope of Service</th>
      <th className={classNames.tableHeadCell}>Formats</th>
      <th className={classNames.tableHeadCell}>Average Skill Time</th>
      <th className={classNames.tableHeadCell}></th>
    </tr>
  </thead>
)

export default function AdminSkillsPage() {
  return (
    <ToastProvider>
      <AdminSkills />
    </ToastProvider>
  )
}
