import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import { generateRangeTo } from 'lib/util/range'
import { AdminTimelineFilter, AdminTimelineItem } from 'lib/api/admin/timeline/admin-timeline'
import { LinkLikeButton } from 'components/core/button'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminTimelineFooter from './admin-timeline-footer'
import AdminTimelineDisplayItem from './admin-timeline-display-item'
import Switch from 'lib/components/switch'
import SelectBox from 'lib/components/dropdown/select-box'

const timelineSelectOptions = [
  {
    value: AdminTimelineFilter.Notes,
    displayElement: <>Notes</>, // Internal only aka yellow notes
  },
  {
    value: AdminTimelineFilter.Conversations,
    displayElement: <>Conversations</>, // Timeline items with client and creatives but not internal notes
  },
  {
    value: AdminTimelineFilter.all,
    displayElement: <>Conversations & Notes</>, // Everything
  },
]

function timelineKey(item: AdminTimelineItem): string {
  return `${item.taskType}-${item.id}`
}

function TimelineHeader(): ReactElement {
  const { ticket } = useAdminTicketContext()
  const {
    selectedFilter,
    setSelectedFilter,
    selectedAPIFilters,
    setSelectedAPIFilters,
    showIncompleteTasks,
    setShowIncompleteTasks,
    tasks,
  } = useAdminTimelineContext()
  const versions = useMemo(() => generateRangeTo(ticket?.currentVersion), [ticket?.currentVersion])
  const numIncompleteTasks = tasks.filter((task) => !task.completed).length

  const handleVersionChange = (ticketVersion: number) => {
    setSelectedAPIFilters((previous) => ({ ...previous, ticketVersion }))
  }

  function handleTimelineFilterChange(selectedValue: AdminTimelineFilter) {
    setSelectedFilter(selectedValue)
  }

  return (
    <header className="tw-sticky tw-left-0 tw-top-0 tw-w-full tw-bg-white tw-flex tw-flex-col tw-py-4 tw-z-20">
      <div className="tw-px-4 tw-flex tw-justify-between tw-items-center">
        <h2 className="tw-text-xl tw-m-0 tw-border-b-1">Directions</h2>
        <Switch value={showIncompleteTasks} onClick={setShowIncompleteTasks}>
          To Do ({numIncompleteTasks} of {tasks.length})
        </Switch>
      </div>
      <hr className="tw-w-full tw-mb-2 tw-mt-4" />
      <div className="tw-flex tw-items-center tw-gap-6 tw-p-4">
        <div className="tw-w-64">
          <TicketVersionSelectBox
            versions={versions}
            selectedVersion={selectedAPIFilters.ticketVersion}
            setSelectedVersion={handleVersionChange}
          />
        </div>
        <div className="tw-w-64">
          <SelectBox
            options={timelineSelectOptions}
            handleChange={handleTimelineFilterChange}
            selectedValue={selectedFilter}
          />
        </div>
      </div>
    </header>
  )
}

function TimelineContent() {
  const [isNextPageLoading, setIsNextPageLoading] = useState(false)
  const [topItemId, setTopItemId] = useState(null)
  const { orderedTimelineItems, getNextPage, hasNextPage } = useAdminTimelineContext()
  const observerTargetDiv = useRef<HTMLDivElement>(null)
  const topItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hasNextPage && orderedTimelineItems?.length) {
      const observer = new IntersectionObserver(
        async ([targetDiv]) => {
          if (targetDiv.isIntersecting && !isNextPageLoading) {
            setIsNextPageLoading(true)
            setTopItemId(timelineKey(orderedTimelineItems[0]))
            await getNextPage()
            observer.disconnect()
            setTimeout(() => {
              setIsNextPageLoading(false)
            }, 500)
          }
        },
        { threshold: 0.5 }
      )

      if (observerTargetDiv.current && !isNextPageLoading) {
        observer.observe(observerTargetDiv.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, orderedTimelineItems, isNextPageLoading])

  useEffect(() => {
    if (topItemRef.current) {
      topItemRef.current.scrollIntoView()

      setTopItemId(null)
    }
  }, [topItemId])

  if (!orderedTimelineItems) {
    return <div>Loading...</div>
  }

  if (orderedTimelineItems.length === 0) {
    return <div className="tw-pt-4">No directions available</div>
  }

  return (
    <ol className="tw-list-none tw-p-0 tw-overflow-auto">
      {hasNextPage && !isNextPageLoading && (
        <div ref={observerTargetDiv} className="tw-ml-5 tw-bg-white tw-text-center tw-text-sm tw-text-gray-500">
          <LinkLikeButton onClick={getNextPage}>More...</LinkLikeButton>
        </div>
      )}
      {orderedTimelineItems.map((item) => (
        <span key={timelineKey(item)}>
          {timelineKey(item) === topItemId && <div ref={topItemRef} />}
          <AdminTimelineDisplayItem item={item} />
        </span>
      ))}
    </ol>
  )
}

export default function AdminTimeline(): ReactElement {
  return (
    <div>
      <TimelineHeader />
      <div className="tw-px-5 tw-pb-6">
        <TimelineContent />
      </div>
      <div className="tw-py-4 tw-border-0 tw-border-t tw-border-solid tw-border-gray-200 tw-sticky tw-left-0 tw-bottom-0 tw-w-full tw-bg-white tw-z-10">
        <div className="tw-px-5">
          <AdminTimelineFooter />
        </div>
      </div>
    </div>
  )
}
