import { FormEvent, useCallback, useEffect, useState } from 'react'
import Modal from 'lib/components/modals/modal'
import Button from 'components/core/button'
import Radio from 'lib/components/radio/radio'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'
import AdminTimeSelector from './admin-time-selector'
import { StopAction } from 'lib/api/admin/tickets/admin-tickets'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import { useToastContext } from 'providers/toast-provider'
import { AdminTicketFile } from 'lib/api/admin/ticket-files/admin-ticket-files'
import Banner from 'lib/components/banner/banner'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'

interface StopModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const classNames = {
  radioInputGroup: 'tw-flex tw-flex-row tw-gap-2 tw-items-center',
}

export default function AdminStopModal({ open, setOpen }: StopModalProps) {
  const [message, setMessage] = useState<string>('')
  const [selectedAction, setSelectedAction] = useState<StopAction>(null)
  const [selectedTime, setSelectedTime] = useState<number>(0.25)
  const [latestCreativeFiles, setLatestCreativeFiles] = useState<AdminTicketFile[]>([])
  const [areAllTasksComplete, setAreAllTasksComplete] = useState<boolean>(false)
  const { getLatestFiles } = useAdminMediaContext()
  const { stop } = useAdminTicketContext()
  const {
    areAllTasksComplete: areAllTasksCompleteAPICall,
    createConversation,
    getLastDraftMessage,
  } = useAdminTimelineContext()
  const { alert } = useToastContext()

  function cancel() {
    reset()
    setOpen(false)
  }

  function handleMessageChange(html: string) {
    setMessage(html)
  }

  async function handleStopModalFormSubmit(event: FormEvent, setOpen: StopModalProps['setOpen']) {
    event.preventDefault()

    if (selectedAction === StopAction.DELIVER_FINAL && bannerMessages.length > 0) {
      alert(bannerMessage)
      return
    }
    const time = selectedAction === StopAction.WORK_NEXT_DAY ? selectedTime : undefined
    await stop(selectedAction, time)

    if (selectedAction === StopAction.DELIVER_FINAL) {
      await createConversation(message, { draft: true })
    } else {
      await createConversation(message)
    }

    setOpen(false)
  }

  function reset() {
    setMessage('')
    setSelectedAction(null)
    setSelectedTime(0.25)
  }

  const fetchLatestCreativeFiles = useCallback(async () => {
    setLatestCreativeFiles(await getLatestFiles(true))
  }, [getLatestFiles])

  const fetchAreAllTasksComplete = useCallback(async () => {
    const complete = await areAllTasksCompleteAPICall()
    setAreAllTasksComplete(complete)
  }, [areAllTasksCompleteAPICall])

  const submitDisabled = !selectedAction || message.length < 12

  useEffect(() => {
    if (open) {
      fetchAreAllTasksComplete()
      fetchLatestCreativeFiles()
    } else {
      // After the animation is done, clear the files
      setTimeout(() => {
        setLatestCreativeFiles([])
        setAreAllTasksComplete(false)
      }, 500)
    }
  }, [fetchAreAllTasksComplete, fetchLatestCreativeFiles, open])

  const bannerMessages = []

  if (!areAllTasksComplete) {
    bannerMessages.push('You must complete all tasks before submitting the ticket for QR')
  }

  if (latestCreativeFiles.length < 1) {
    bannerMessages.push('You must upload at least one file prior to submitting the ticket for QR')
  }

  const bannerMessage = bannerMessages.length > 0 ? `${bannerMessages.join(' and ')}.` : null

  // const submitDisabled = useMemo(() => {
  //   if (selectedAction === StopAction.WORK_NEXT_DAY) {
  //     return message.length < 12
  //   }
  //
  //   if (selectedAction === StopAction.DELIVER_FINAL) {
  //     console.log('bannerMessage.length', bannerMessages.length)
  //     return bannerMessages.length > 0 || message.length < 12
  //   }
  //
  //   return true
  // }, [bannerMessages.length, message.length, selectedAction])

  const selectAction = async (action: StopAction) => {
    if (action === StopAction.DELIVER_FINAL) {
      const draftMessage = await getLastDraftMessage()
      setMessage(draftMessage?.body || '')
    } else {
      setMessage('')
    }

    setSelectedAction(action)
  }

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header as="div">
        <h4 className="tw-mt-0">Stop work on ticket</h4>
      </Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600">
          <span>What is the reason for stopping work on this ticket?</span>
          <form
            className="tw-flex tw-flex-col tw-gap-6 tw-font-semibold tw-text-md"
            onSubmit={(event) => handleStopModalFormSubmit(event, setOpen)}
          >
            <div className="tw-flex tw-flex-col tw-gap-1">
              <div className={classNames.radioInputGroup}>
                <Radio
                  id="stopmodal_form_need_more_time"
                  checked={selectedAction === StopAction.WORK_NEXT_DAY}
                  name="action"
                  value="work_next_day"
                  type="radio"
                  onChange={() => selectAction(StopAction.WORK_NEXT_DAY)}
                />
                <label htmlFor="stopmodal_form_need_more_time" className="tw-font-normal">
                  Need more time (WIP)
                </label>
              </div>
              <div className={classNames.radioInputGroup}>
                <Radio
                  id="stopmodal_form_ready_for_qr"
                  checked={selectedAction === StopAction.DELIVER_FINAL}
                  name="action"
                  value="deliver_final"
                  type="radio"
                  onChange={() => selectAction(StopAction.DELIVER_FINAL)}
                />
                <label htmlFor="stopmodal_form_ready_for_qr" className="tw-font-normal">
                  Ready for quality review
                </label>
              </div>
            </div>

            <div className={selectedAction ? '' : 'tw-hidden'}>
              {selectedAction === StopAction.WORK_NEXT_DAY && (
                <div className="tw-font-normal tw-flex tw-flex-col tw-gap-2">
                  <AdminTimeSelector
                    label="How much time do you need to complete this ticket?"
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                  />
                </div>
              )}

              <div className="tw-font-normal tw-flex tw-flex-col tw-gap-2">
                {selectedAction === StopAction.WORK_NEXT_DAY && (
                  <div className="tw-pt-6">
                    <span>Add a message for the client. </span>
                    <span className="tw-text-red-600">
                      *Note that any file(s) uploaded will be shown to the client.
                    </span>
                  </div>
                )}

                {selectedAction === StopAction.DELIVER_FINAL && (
                  <div>
                    {bannerMessage && (
                      <div className="tw-pb-4">
                        <Banner type="error">{bannerMessage}</Banner>
                      </div>
                    )}
                    <div>Add a message to be delivered to the client after quality review is approved</div>
                  </div>
                )}

                {selectedAction && (
                  <div className="tw-p-4 tw-pl-0 tw-border tw-border-red-700">
                    <WYSIWYGTextarea
                      defaultValue={message}
                      onChange={handleMessageChange}
                      placeholder="Type in client message here"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="tw-flex tw-flex-row tw-gap-2 tw-justify-end tw-items-center">
              <Button color="neutralGray" type="button" onClick={cancel}>
                Cancel (Resume work)
              </Button>
              <Button color="purple" type="submit" disabled={submitDisabled}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
