import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FilterProps } from './filters'

// Filter takes a timestamp in seconds, but DatePicker expects milliseconds

type Preset = 'today' | 'yesterday' | 'thisWeek' | 'lastWeek' | 'thisMonth' | 'lastMonth'

const DateRange = ({ table, meta }: FilterProps) => {
  const handleDateChange = (update: [Date | null, Date | null]) => {
    const [startDate, endDate] = update

    const filters = table
      .getState()
      .columnFilters.filter((filter) => filter.id !== meta.filterStartKey && filter.id !== meta.filterEndKey)

    if (startDate) {
      filters.push({ id: meta.filterStartKey, value: [Math.floor(startDate.getTime() / 1000)] })
    }

    if (endDate) {
      filters.push({ id: meta.filterEndKey, value: [Math.floor(endDate.getTime() / 1000)] })
    }

    table.setColumnFilters([
      ...table
        .getState()
        .columnFilters.filter((filter) => filter.id !== meta.filterStartKey && filter.id !== meta.filterEndKey),
      ...filters,
    ])
  }

  const applyPreset = (preset: Preset) => {
    const now = new Date()
    let start: Date, end: Date

    switch (preset) {
      case 'today':
        start = new Date(now.setHours(0, 0, 0, 0))
        end = new Date(now.setHours(23, 59, 59, 999))
        break
      case 'yesterday':
        start = new Date(now.setDate(now.getDate() - 1))
        start.setHours(0, 0, 0, 0)
        end = new Date(start)
        end.setHours(23, 59, 59, 999)
        break
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay()))
        start.setHours(0, 0, 0, 0)
        end = new Date(now.setDate(now.getDate() - now.getDay() + 6))
        end.setHours(23, 59, 59, 999)
        break
      case 'lastWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay() - 7))
        start.setHours(0, 0, 0, 0)
        end = new Date(now.setDate(now.getDate() - now.getDay() - 1))
        end.setHours(23, 59, 59, 999)
        break
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1)
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999)
        break
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1)
        end = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999)
        break
      default:
        return
    }

    handleDateChange([start, end])
  }

  const renderCustomHeader = () => (
    <div className="flex justify-between">
      <div className="flex flex-col items-start">
        <button onClick={() => applyPreset('today')}>Today</button>
        <button onClick={() => applyPreset('yesterday')}>Yesterday</button>
        <button onClick={() => applyPreset('thisWeek')}>This Week</button>
        <button onClick={() => applyPreset('lastWeek')}>Last Week</button>
        <button onClick={() => applyPreset('thisMonth')}>This Month</button>
        <button onClick={() => applyPreset('lastMonth')}>Last Month</button>
      </div>
    </div>
  )

  const startDate = table.getState().columnFilters.find((filter) => filter.id === meta.filterStartKey)?.value?.[0]
  const endDate = table.getState().columnFilters.find((filter) => filter.id === meta.filterEndKey)?.value?.[0]

  return (
    <div className="date-filter">
      <DatePicker
        selectsRange={true}
        startDate={startDate ? new Date(Number(startDate * 1000)) : null}
        endDate={endDate ? new Date(Number(endDate) * 1000) : null}
        onChange={handleDateChange}
        dateFormat="MMMM d, yyyy"
        renderCustomHeader={renderCustomHeader}
      />
    </div>
  )
}

export default DateRange
