import { useEffect, useMemo, useRef, useState } from 'react'
import { Card } from 'lib/components/card/card'
import { fileTypeSrc } from 'lib/ticket/utils'

interface FormatsListProps {
  availableFormats: string[]
  onFormatSelect: (string) => void
  selectedFormats: string[]
  disabledFormats?: string[]
}

interface SelectableFormatProps {
  disabled: boolean
  format: string
  onSelect: (string) => void
  isSelected: boolean
}

interface TicketFormatsProps {
  availableFormats: string[]
  onSelect: (string) => void
  selectedTicketFormats: string[]
}

const SOURCE_FORMATS = ['ai', 'psd', 'indd', 'prproj']
const PD_FORMATS = ['canva', 'google_slides', 'key', 'ppt']

const classNames = {
  cardBody: 'tw-pt-4',
  formatsList: 'tw-flex tw-gap-3 tw-flex-wrap',
  hr: 'tw-border-0 tw-border-solid tw-border-gray-300 tw-border-t-2 tw-h-6 tw-mt-6',
  image: 'tw-w-20 tw-h-20',
  cardFooter: 'tw-flex tw-flex-col tw-text-center',
}

function FormatsList({ availableFormats, onFormatSelect, selectedFormats, disabledFormats }: FormatsListProps) {
  function isSelected(format: string) {
    return selectedFormats.some((f) => f === format)
  }

  if (availableFormats.length === 0) {
    return null
  }

  return (
    <div className={classNames.formatsList}>
      {availableFormats.map((format) => (
        <SelectableFormat
          format={format}
          onSelect={onFormatSelect}
          isSelected={isSelected(format)}
          key={format}
          disabled={disabledFormats?.includes(format)}
        />
      ))}
    </div>
  )
}

function partitionFormats(formats: string[]): string[][] {
  return formats.reduce(
    ([deliveryFormats, sourceFormats], format) => {
      if (SOURCE_FORMATS.includes(format)) {
        sourceFormats.push(format)
      } else {
        deliveryFormats.push(format)
      }
      return [deliveryFormats, sourceFormats]
    },
    [[], []]
  )
}

function SelectableFormat({ format, onSelect, isSelected, disabled }: SelectableFormatProps): JSX.Element {
  return (
    <Card size="md" onClick={() => onSelect(format)} key={format} isSelected={isSelected} isSquare disabled={disabled}>
      <Card.Body className={classNames.cardBody} centered>
        <img className={classNames.image} src={fileTypeSrc(format)} alt={format} />
      </Card.Body>
      <Card.Footer className={classNames.cardFooter}>{`.${format}`}</Card.Footer>
    </Card>
  )
}

export default function TicketFormats({
  availableFormats,
  onSelect,
  selectedTicketFormats,
}: TicketFormatsProps): JSX.Element {
  const value = useRef([])
  const [selectedFormats, setSelectedFormats] = useState<string[]>([])
  const [disabledFormats, setDisabledFormats] = useState<string[]>([])
  const [deliveryFormats, sourceFormats] = useMemo(() => partitionFormats(availableFormats), [availableFormats])

  function onDeliveryFormatSelect(format: string) {
    const isPreviouslySelected = value.current.find((f) => f === format)
    if (isPreviouslySelected) {
      value.current = value.current.filter((f) => f !== format)
      if (PD_FORMATS.includes(format)) setDisabledFormats([])
    } else {
      value.current = [...value.current, format]
      if (PD_FORMATS.includes(format)) setDisabledFormats(PD_FORMATS.filter((f) => f !== format))
    }
    setSelectedFormats(value.current)
  }

  function onSourceFormatSelect(format: string) {
    const isPreviouslySelected = value.current.find((f) => f === format)
    value.current = value.current.filter((f) => !sourceFormats.includes(f))
    if (!isPreviouslySelected) {
      value.current = [...value.current, format]
    }
    setSelectedFormats(value.current)
  }

  useEffect(() => {
    if (selectedTicketFormats) {
      value.current = selectedTicketFormats
      if (PD_FORMATS.some((f) => selectedTicketFormats.includes(f))) {
        setDisabledFormats(PD_FORMATS.filter((f) => !selectedTicketFormats.includes(f)))
      }
      setSelectedFormats(value.current)
    }
  }, [selectedTicketFormats])

  useEffect(() => {
    // Gets called when dropdown is dismissed
    return function () {
      if (value.current !== selectedTicketFormats) {
        onSelect(value.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <h3>Format(s)</h3>
      <p>Select all that apply</p>
      <FormatsList
        disabledFormats={disabledFormats}
        availableFormats={deliveryFormats}
        onFormatSelect={onDeliveryFormatSelect}
        selectedFormats={selectedFormats}
      />
      {deliveryFormats.length > 0 && sourceFormats.length > 0 && <div className={classNames.hr}></div>}
      <FormatsList
        disabledFormats={disabledFormats}
        availableFormats={sourceFormats}
        onFormatSelect={onSourceFormatSelect}
        selectedFormats={selectedFormats}
      />
    </>
  )
}
