import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'components/core/text-input/text-input'
import { CustomSize } from 'lib/api/skills/skills'
import IconButton from 'lib/components/buttons/icon-button'
import { ChangeEvent, useEffect, useState } from 'react'

interface CustomSizeInputsProps {
  size: CustomSize
  index: number
  handleRemoveClick: (size: CustomSize) => void
  onValueChange: (size: CustomSize, index: number) => void
  showErrors: boolean
}

const classNames = {
  container: `tw-flex tw-items-end tw-gap-6 tw-mb-4`,
  input: `tw-w-full tw-appearance-none`,
  select: `tw-w-20 tw-p-2 focus:tw-ring-eggplant focus:tw-ring-2 hover:tw-ring-eggplant hover:tw-ring-2 tw-outline-none tw-rounded tw-appearance-none tw-bg-no-repeat`,
}

export default function CustomSizeInputs({
  size,
  index,
  handleRemoveClick,
  onValueChange,
  showErrors,
}: CustomSizeInputsProps): JSX.Element {
  const [showWidthError, setShowWidthError] = useState(!size.width && showErrors)
  const [showHeightError, setShowHeightError] = useState(!size.height && showErrors)

  useEffect(() => {
    if (showErrors) {
      setShowWidthError(!size.width && showErrors)
      setShowHeightError(!size.height && showErrors)
    }
  }, [showErrors, size.height, size.width])

  return (
    <div className={classNames.container}>
      <div className="tw-w-4/12">
        <TextInput
          value={size.width}
          name={`Width-${index}`}
          label="Width"
          size="sm"
          type="number"
          className={classNames.input}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onValueChange({ ...size, width: Number(e.target.value) || '' }, index)
          }
          onBlur={() => setShowWidthError(!size.width)}
          hasError={showWidthError}
          data-testid={`Width-${index}`}
        />
      </div>
      <div className="tw-w-4/12">
        <TextInput
          value={size.height}
          name={`Height-${index}`}
          label="Height"
          size="sm"
          type="number"
          className={classNames.input}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onValueChange({ ...size, height: Number(e.target.value) || '' }, index)
          }
          onBlur={() => setShowHeightError(!size.height)}
          hasError={showHeightError}
          data-testid={`Height-${index}`}
        />
      </div>
      <div className="tw-w-3/12">
        <select
          name="units"
          id={`Units-${index}`}
          aria-label="Units"
          value={size.units}
          className={classNames.select}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => onValueChange({ ...size, units: e.target.value }, index)}
          data-testid={`Units-${index}`}
        >
          <option value="px">px</option>
          <option value="in">in</option>
          <option value="cm">cm</option>
        </select>

        <span className="tw-relative tw--left-6 tw-pointer-events-none">
          <FontAwesomeIcon icon={['far', 'chevron-down']} />
        </span>
      </div>

      <div className="tw-w-1/12 tw-flex tw-justify-end">
        <IconButton
          color="primary"
          icon={['far', 'minus']}
          onClick={() => handleRemoveClick(size)}
          invert
          dataTestid={`Minus-${index}`}
        />
      </div>
    </div>
  )
}
