import React, { MouseEvent, useEffect, useState, useRef } from 'react'
import Button from 'components/core/button'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import { IntegrationKey } from 'lib/api/integration-keys/types'
import { handleCreateApiToken, getIntegrationKeys, deleteIntegrationKey } from 'lib/api/integration-keys/api'

export interface User {
  id: number
  companyRole: string
  email: string
  companyId: number
  userRole: 'dpp_super_admin' | 'dpp_admin' | 'company_admin'
}

export default function IntegrationKeys({
  user,
  initialIntegrationKeys,
}: {
  user: User
  initialIntegrationKeys?: IntegrationKey[]
}) {
  const [integrationKeys, setIntegrationKeys] = useState<IntegrationKey[]>(initialIntegrationKeys || [])
  const [newKeyName, setNewKeyName] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [keyToDelete, setKeyToDelete] = useState<IntegrationKey | null>(null)
  const [visibleKeys, setVisibleKeys] = useState<number[]>([])
  const [openMenu, setOpenMenu] = useState<number | null>(null)
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })
  const menuRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!initialIntegrationKeys) {
      fetchKeys()
    }
  }, [initialIntegrationKeys])

  const fetchKeys = async () => {
    try {
      const keys = await getIntegrationKeys()
      setIntegrationKeys(keys)
    } catch (error) {
      console.error('Failed to fetch integration keys:', error)
    }
  }

  const handleCreateToken = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (newKeyName.trim()) {
      try {
        await handleCreateApiToken(newKeyName.trim())
        await fetchKeys()
        setNewKeyName('')
      } catch (error) {
        console.error('Failed to create new token:', error)
      }
    }
  }

  const handleDeleteToken = async (key: IntegrationKey) => {
    setKeyToDelete(key)
    setShowDeleteModal(true)
    setOpenMenu(null)
  }

  const confirmDelete = async () => {
    if (keyToDelete) {
      try {
        await deleteIntegrationKey(keyToDelete.id)
        await fetchKeys()
      } catch (error) {
        console.error('Failed to delete token:', error)
      }
    }
    setShowDeleteModal(false)
    setKeyToDelete(null)
  }

  const toggleKeyVisibility = (id: number) => {
    setVisibleKeys((prev) => (prev.includes(id) ? prev.filter((keyId) => keyId !== id) : [...prev, id]))
    setOpenMenu(null)
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    setOpenMenu(null)
  }

  const toggleMenu = (id: number, event: React.MouseEvent) => {
    if (openMenu === id) {
      setOpenMenu(null)
    } else {
      setOpenMenu(id)
      const menuWidth = menuRef.current?.offsetWidth || 192
      const buttonRect = (event.target as HTMLElement).getBoundingClientRect()

      const top = buttonRect.bottom + window.scrollY + 8 // 8px below the button
      const left = Math.max(0, buttonRect.right - menuWidth + 16)

      setMenuPosition({ top, left })
    }
  }

  if (!['dpp_super_admin', 'dpp_admin', 'company_admin'].includes(user.userRole)) {
    return null
  }

  return (
    <div ref={containerRef} className="tw-block tw-bg-white tw-mb-6 tw-shadow-sm tw-rounded-lg tw-overflow-hidden">
      <div className="tw-p-6">
        <h4 className="tw-mb-4 tw-text-lg tw-font-semibold">Integration Keys</h4>
        <p className="tw-mb-6 tw-border-b tw-border-picklegreen-200 tw-pb-4">
          You can have up to three Integration Keys per company.
        </p>
        <div className="tw-mb-6 tw-border-b tw-border-picklegreen-200 tw-pb-4">
          <input
            type="text"
            className="tw-mb-3 tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
            value={newKeyName}
            onChange={(e) => setNewKeyName(e.target.value)}
            placeholder="Enter new key name"
          />
          <Button
            color="neutralGray"
            onClick={handleCreateToken}
            disabled={integrationKeys.length >= 3}
            className="tw-w-full"
          >
            Add New Key
          </Button>
        </div>
        {integrationKeys.length > 0 ? (
          <div className="tw-overflow-x-auto">
            <table className="tw-w-full">
              <thead>
                <tr className="tw-border-b tw-border-picklegreen-200">
                  <th className="tw-p-3 tw-text-left">Name</th>
                  <th className="tw-p-3 tw-text-left">Key</th>
                  <th className="tw-p-3 tw-text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {integrationKeys.map((key) => {
                  const isVisible = visibleKeys.includes(key.id)
                  return (
                    <tr key={key.id} className="tw-border-b tw-border-picklegreen-200">
                      <td className="tw-p-3">{key.name}</td>
                      <td className="tw-p-3">
                        <div className="tw-flex tw-items-center">
                          <span
                            className={`tw-font-mono tw-mr-2 ${
                              isVisible ? 'tw-text-3xs tw-transform tw-scale-85 tw-origin-left' : 'tw-text-base'
                            }`}
                            style={{
                              maxWidth: isVisible ? 'none' : '300px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {isVisible ? key.key : '••••••••••••••••'}
                          </span>
                        </div>
                      </td>
                      <td className="tw-p-3 tw-text-right">
                        <div className="tw-relative">
                          <button
                            onClick={(e) => toggleMenu(key.id, e)}
                            data-testid={`ellipsis-button-${key.id}`}
                            className="tw-text-gray-500 hover:tw-text-gray-700 focus:tw-outline-none tw-bg-transparent tw-border-none tw-p-0"
                          >
                            <DotsVerticalIcon className="tw-h-5 tw-w-5" />
                          </button>
                          {openMenu === key.id && (
                            <div
                              ref={menuRef}
                              className="tw-fixed tw-w-48 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5"
                              style={{
                                top: `${menuPosition.top}px`,
                                left: `${menuPosition.left}px`,
                                zIndex: 2147483647, // Maximum possible z-index value
                              }}
                            >
                              <div
                                className="tw-py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                              >
                                <button
                                  onClick={() => toggleKeyVisibility(key.id)}
                                  className="tw-border-none hover:tw-bg-neutral-100 tw-bg-white tw-px-5 tw-py-2 tw-text-left tw-text-neutral-800 tw-w-full tw-cursor-pointer"
                                  role="menuitem"
                                >
                                  {isVisible ? 'Hide Key' : 'Show Key'}
                                </button>
                                {isVisible && (
                                  <button
                                    onClick={() => copyToClipboard(key.key)}
                                    className="tw-border-none hover:tw-bg-neutral-100 tw-bg-white tw-px-5 tw-py-2 tw-text-left tw-text-neutral-800 tw-w-full tw-cursor-pointer"
                                    role="menuitem"
                                  >
                                    Copy Key
                                  </button>
                                )}
                                <button
                                  onClick={() => handleDeleteToken(key)}
                                  className="tw-border-none hover:tw-bg-neutral-100 tw-bg-white tw-px-5 tw-py-2 tw-text-left tw-text-neutral-800 tw-w-full tw-cursor-pointer"
                                  role="menuitem"
                                >
                                  Delete Key
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No Integration Keys available. Create one using the form above.</p>
        )}
      </div>

      {showDeleteModal && (
        <div
          className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center"
          style={{ zIndex: 2147483647 }}
        >
          <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-max-w-md tw-w-full">
            <h5 className="tw-text-lg tw-font-bold tw-mb-4">Confirm Deletion</h5>
            <p className="tw-mb-6">
              Please confirm that you would like to delete this key. All integrations using this key will no longer have
              access.
            </p>
            <div className="tw-flex tw-justify-end">
              <Button color="neutralGray" onClick={() => setShowDeleteModal(false)} className="tw-mr-3">
                Cancel
              </Button>
              {/* @ts-ignore */}
              <Button color="danger" onClick={confirmDelete}>
                Confirm Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
