import { baseRequest, BaseResponse, bindJWT, deleteApi, getApi, patchApi, postApi, putApi } from 'lib/api/api'
import { Skill, SkillSize } from '../skills/skills'
import { Brand } from '../companies/companies'
import { User } from '../../../interfaces/user'
import { Ticket } from 'interfaces/ticket'
import { TicketFilters } from 'components/pages/requests/ticket-list-filters'
import { snakeCaseKeys } from 'lib/object/utils'
import {
  ActiveTab,
  SortTuple,
  SortDirection,
  DEFAULT_SORT_COLUMN,
  FetchTicketsParams,
} from 'providers/single-queue-provider'
import { CompanyTag } from '../company-tags/company-tags'

export interface Designer {
  id: number
  name: string
  avatar: string
}

export interface Collaborator {
  id: number
  name: string
  email: string
}

export type CollaboratorEditMode = EditMode.revise | EditMode.processing | EditMode.complete

export interface DesignRequest {
  availableFormats: string[]
  category: string
  currentVersion: number
  deliveredBy: string
  featuredFileId: number
  feedbackReasons?: string[]
  id: number
  isNewState: boolean
  lastDeliveredVersion: number
  meta: {
    pendingUpdates: number
    sharing: {
      id: string
      available: boolean
      segments?: string[]
      hasWhiteLabelSubscription: boolean
      customDomain?: CustomDomain
      collaborators: [Collaborator]
    }
  }
  preferredDesigner: Designer
  selectedBrand: Brand
  selectedFormats: string[]
  selectedSkillSizes: SkillSize[]
  size: string
  skill: Skill
  status: DesignRequestStatus
  subject: string
  submittedAt?: string
  submittedBy: User
  tags: CompanyTag[]
  thumbnail: { url: string; placeholder: boolean }
  updatedAt: string
}

interface DesignersResponse extends BaseResponse {
  designers: Designer[]
  error: unknown
  status: number
}

interface PaginatedTickets {
  tickets: Ticket[]
  perPage: number
  total: number
}

export interface SharedTicket {
  id: number
  currentVersion: number
  lastDeliveredVersion: number
  meta: {
    pendingUpdates: number
  }
  status: DesignRequestStatus
  subject: string
}

interface SharedTicketObjectResponse extends BaseResponse {
  data: {
    data: SharedTicket
    meta: {
      pendingUpdates: number
    }
  }
}

interface TicketResponse extends BaseResponse {
  ticket: DesignRequest | Ticket
}

interface TicketsFromCompanyResponse extends BaseResponse {
  ticketData: PaginatedTickets
  error: unknown
  status: number
}

interface TicketPatchPayload {
  skill_id: number
  skill_sizes: SkillSize[]
  formats: string[]
  subject: string
}

interface CustomDomain {
  domain?: string
  logo?: string
  primary_color?: string
}

export enum DesignRequestStatus {
  archived = 'Archived',
  attention = 'Attention',
  canceled = 'Canceled',
  completed = 'Completed',
  draft = 'Draft',
  inProgress = 'In Progress',
  onDeck = 'On Deck',
  qualityReview = 'Quality Review',
  review = 'For Review',
  wipOnDeck = 'WIP - On Deck',
  preview = 'Preview',
}

export enum EditMode {
  draft = 'draft',
  processing = 'processing',
  revise = 'revise',
  complete = 'complete',
}

const baseUrl = '/api/internal/tickets'
const ticketsBaseUrl = '/api/internal/tickets/tickets'
const requestsApiUrl = '/api/internal/requests'
const ticketsApiUrl = '/api/internal/tickets'

export async function createTicket(ticket: Record<string, unknown>): Promise<TicketResponse> {
  const response = await baseRequest(ticketsBaseUrl, postApi, ticket)

  return {
    error: response.error,
    status: response.status,
    ticket: response.data as unknown as DesignRequest,
  }
}

export async function deleteTicket(id: number): Promise<unknown> {
  const url = `${ticketsBaseUrl}/${id}`
  const response = await baseRequest(url, deleteApi)
  return {
    error: response.error,
    status: response.status,
  }
}

export async function cancelTicket(id: number): Promise<unknown> {
  const url = `${ticketsBaseUrl}/${id}/cancel`
  const response = await baseRequest(url, patchApi)
  return {
    error: response.error,
    status: response.status,
  }
}

export async function getDesigners(ticketId: number): Promise<DesignersResponse> {
  const url = `${baseUrl}/${ticketId}/designers`
  const response = await baseRequest(url, getApi)

  return {
    error: response.error,
    status: response.status,
    designers: response.data as unknown as Designer[],
  }
}

export async function getSharedTicket(uuid: string, token: string): Promise<SharedTicket> {
  const getApiWithToken = bindJWT(getApi, token)
  const response = (await baseRequest(`${baseUrl}/share/${uuid}`, getApiWithToken)) as SharedTicketObjectResponse
  return {
    ...response.data.data,
    meta: response.data.meta,
  }
}

export async function getTicket(ticketId: number): Promise<TicketResponse> {
  const response = await baseRequest(`${baseUrl}/${ticketId}`, getApi)

  return {
    error: response.error,
    status: response.status,
    ticket: response.data.data as unknown as Ticket,
  }
}

export async function getTicketRequest(id: number): Promise<TicketResponse> {
  const response = await baseRequest(`${ticketsBaseUrl}/${id}`, getApi)
  return {
    error: response.error,
    status: response.status,
    ticket: response.data as unknown as DesignRequest,
  }
}

const ticketsFromCompanyBaseUrl = `/api/internal/production_coordinators/tickets`
export async function getTicketsFromCompany(companyId: number, page: number): Promise<TicketsFromCompanyResponse> {
  const { data, error, status } = await baseRequest(
    `${ticketsFromCompanyBaseUrl}?company_id=${companyId}&page=${page}`,
    getApi
  )

  return {
    error,
    status,
    ticketData: data as unknown as PaginatedTickets,
  }
}

export async function submitCollaboratorFeedback(ticketId: number, token: string): Promise<unknown> {
  const postApiWithToken = bindJWT(postApi, token)
  const url = `${baseUrl}/${ticketId}/submissions/feedback`
  const response = await baseRequest(url, postApiWithToken)

  return {
    error: response.error,
    status: response.status,
    ticket: response.data as unknown,
  }
}

export async function submitTicket(ticketId: number): Promise<unknown> {
  const url = `${baseUrl}/${ticketId}/submissions`
  const response = await baseRequest(url, postApi)

  return {
    error: response.error,
    status: response.status,
    ticket: response.data as unknown,
  }
}

export async function completeTicket(ticketId: number): Promise<unknown> {
  const url = `${baseUrl}/${ticketId}/complete`
  const response = await baseRequest(url, postApi)

  return {
    error: response.error,
    status: response.status,
    ticket: response.data as unknown,
  }
}

export async function archiveTicket(ticketId: number): Promise<unknown> {
  const url = `${baseUrl}/${ticketId}/archive`
  const response = await baseRequest(url, postApi)

  return {
    error: response.error,
    status: response.status,
    ticket: response.data as unknown,
  }
}

export async function updateTicket(id: number, payload: Partial<TicketPatchPayload>): Promise<TicketResponse> {
  const url = `${ticketsBaseUrl}/${id}`
  const response = await baseRequest(url, patchApi, payload)
  return {
    ticket: response.data as unknown as DesignRequest,
    error: response.error,
    status: response.status,
  }
}

export async function shareTicket(ticketId: number, enabled: boolean): Promise<TicketResponse> {
  const url = `${baseUrl}/${ticketId}/share`
  const response = await baseRequest(url, putApi, { enabled })

  return {
    error: response.error,
    status: response.status,
    ticket: response.data.data as unknown as DesignRequest,
  }
}

export async function assetsDownloadUrl(ticketId: number, ticketVersion?: number): Promise<string> {
  const response = await getApi(
    `${baseUrl}/${ticketId}/download${ticketVersion ? `?ticket_version=${ticketVersion}` : ''}`
  )
  return response.data.download_url
}

export function duplicateTicketUrl(ticketId: number) {
  return `/tickets/${ticketId}/duplications`
}

function getParamAsNumber(params: URLSearchParams, key: string): number {
  const value = params.get(key)
  return value ? parseInt(value) : null
}

function getParamAsString(params: URLSearchParams, key: string, defaultValue = ''): string {
  return params.get(key) || defaultValue
}

export interface UrlState {
  activeTab: ActiveTab
  currentPage: number
  filters: TicketFilters
  sort: SortTuple
}

interface ApiFiltersParams extends Record<string, unknown> {
  'q[brand_id_eq]'?: number
  'q[friendly_status]'?: string
  'q[skill_skill_category_subscription_type_eq]'?: string
  'q[title_query]'?: string
  'q[user_id_eq]'?: number
}

interface ApiParams extends ApiFiltersParams {
  first_render?: string
  page: number
  sort_column: string
  sort_direction: SortDirection
  tab: ActiveTab
  user_id: number
}

interface SingleQTicketsAndRequestsParams {
  params: FetchTicketsParams
  userId: number
  dataLoaded: boolean
}

export type TicketData = {
  tickets: Ticket[]
  per_page: number
  count: number
  statuses: string[]
  categories: string[]
  users: User[]
  brands: Brand[]
}

interface SingleQResponse extends Omit<BaseResponse, 'data'> {
  data: TicketData
  column: string
  direction: SortDirection
}

export function getStateFromQueryString(): UrlState {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const activeTab = getParamAsString(params, 'tab', 'active') as ActiveTab
  const sort = params.has('sort') ? (params.get('sort').split('|') as SortTuple) : DEFAULT_SORT_COLUMN[activeTab]
  return {
    activeTab,
    currentPage: getParamAsNumber(params, 'page') || 1,
    filters: {
      brandIdEq: getParamAsNumber(params, 'brand'),
      friendlyStatus: getParamAsString(params, 'status'),
      skillSkillCategorySubscriptionTypeEq: params.get('skill'),
      titleQuery: getParamAsString(params, 'title'),
      userIdEq: getParamAsNumber(params, 'user'),
    },
    sort,
  }
}

const SORTABLE_COLUMNS = {
  active: [
    'position',
    'subject',
    'friendly_status_name',
    'queue_name',
    'designer_id',
    'user_id',
    'last_updated_for_user',
  ],
  draft: ['subject', 'friendly_status_name', 'queue_name', 'user_id'],
  archived: ['subject', 'friendly_status_name', 'queue_name', 'overall_rating', 'user_id', 'last_updated_for_user'],
}

const DEFAULT_SORT_DIRECTION: Record<string, SortDirection> = {
  active: 'ASC',
  draft: 'ASC',
  archived: 'DESC',
}

function sortableColumn(tab: ActiveTab, sort: SortTuple): SortTuple {
  const [column, direction] = sort
  if (SORTABLE_COLUMNS[tab].includes(column)) {
    if (direction === null) {
      return [column, DEFAULT_SORT_DIRECTION[tab]]
    }
    return [column, direction]
  } else {
    return DEFAULT_SORT_COLUMN[tab]
  }
}

export async function getSingleQTicketsAndRequests({
  params,
  userId,
  dataLoaded,
}: SingleQTicketsAndRequestsParams): Promise<SingleQResponse> {
  const urlState = getStateFromQueryString()

  const [column, direction]: SortTuple = urlState.sort
  const [sort_column, sort_direction] = sortableColumn(urlState.activeTab, urlState.sort)

  const apiParams: ApiParams = {
    page: urlState.currentPage,
    sort_column,
    sort_direction,
    tab: urlState.activeTab,
    user_id: userId,
    ...snakeCaseKeys(urlState.filters, 'q[', ']'),
    ...params,
  }

  if (!dataLoaded) {
    apiParams.first_render = 'true'
  }

  const url = urlState.activeTab === 'draft' ? requestsApiUrl : ticketsApiUrl
  const { data, error, status } = await baseRequest(url, getApi, apiParams)
  return { data, column, direction, error, status } as unknown as SingleQResponse
}

export interface UpdateShareLinkPayload {
  share: {
    segments: string[]
  }
}

export async function updateShareLink(ticketId: string, shareId: string, data: UpdateShareLinkPayload) {
  const putWithJWT = bindJWT(putApi, null)
  const response = await putWithJWT(`/api/internal/tickets/${ticketId}/share/${shareId}`, { data })
  return response.data
}

export interface collaboratorsResponse {
  data: Collaborator[]
}

export async function getCollaborators(ticketId: string) {
  const getWithJWT = bindJWT(getApi, null)
  const response = await getWithJWT(`/api/internal/tickets/${ticketId}/collab_invitations`)
  return response.data as collaboratorsResponse
}

export async function inviteCollaborators(ticketId: string, emails: string[]) {
  const postWithJWT = bindJWT(postApi, null)
  const response = await postWithJWT(`/api/internal/tickets/${ticketId}/collab_invitations`, { data: { emails } })
  return response.data as collaboratorsResponse
}
