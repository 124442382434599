import React, { ReactElement, useEffect } from 'react'
import DropdownMenu from 'components/elements/dropdown-menu'

interface pageHeaderProps {
  children: React.ReactNode
  title: string
  icon?: ReactElement
  hideDropdown?: boolean
}

export default function PageHeader({ children, title, icon, hideDropdown }: pageHeaderProps): JSX.Element {
  useEffect(() => {
    // This is legacy from our haml pages, while we don't move them to React we need this hack
    // to remove the top-padding of the container of the main layout (haml)
    // $('.container-top-padding').removeClass('container-top-padding')
    document.querySelector('.container-top-padding')?.classList.remove('container-top-padding')
  }, [])

  const Body = () => {
    if (hideDropdown) return <div className="tw-mt-2.5">{children}</div>

    return (
      <div className="tw-mt-2.5">
        <DropdownMenu dropDownType={'no-bg'}>{children}</DropdownMenu>
      </div>
    )
  }

  return (
    <div className="tw-flex tw-justify-between tw-mt-1">
      <h4>
        {icon}
        {title}
      </h4>
      <Body />
    </div>
  )
}
