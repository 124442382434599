import { isStatusCompleted, isStatusActive, isStatusDraft } from 'lib/ticket/utils'
import Button from 'components/core/button'
import { MenuButtonAction } from 'providers/confirmation-modal-provider'

interface BulkActionsButtonsProps {
  status: string
  ticketIds: Array<number | bigint>
  menuActionBtn: MenuButtonAction
}

export default function BulkActionsButtons({ status, ticketIds, menuActionBtn }: BulkActionsButtonsProps): JSX.Element {
  const bulkRequestActionUrl = window.Routes.apiInternalRequestUrl('bulk')
  const markArchivedTicketUrl = window.Routes.apiInternalMarkArchivedUrl()
  const cancelTicketUrl = window.Routes.cancelApiInternalTicket('bulk')
  const bulkActionData = { tickets: ticketIds }
  const bulkActionArchive = {
    ticket_ids: ticketIds,
    update_action: 'mark_archived',
  }
  const cancelModalData = {
    title: 'Cancel Request?',
    message: `Are you sure you want to cancel all selected requests?`,
    confirmBtnText: 'Yes, Cancel',
    confirmAction: () => {
      menuActionBtn(cancelTicketUrl, bulkActionData, 'patch')
    },
  }
  const deleteDraftModalData = {
    title: 'Are you sure?',
    message: `Drafts will be deleted permanently and cannot be undone`,
    confirmBtnText: 'Yes, delete',
    confirmAction: () => {
      menuActionBtn(bulkRequestActionUrl, bulkActionData, 'DELETE')
    },
  }

  return (
    <>
      {isStatusActive(status) && (
        <Button
          type={'button'}
          color={'lightGray'}
          size={'cut'}
          onClick={() => menuActionBtn(cancelTicketUrl, bulkActionData, 'PATCH', cancelModalData)}
        >
          <i className="fas fa-ban"></i>
          <span className="tw-pl-1.5">Cancel</span>
        </Button>
      )}
      {isStatusDraft(status) && (
        <Button
          type={'button'}
          color={'lightGray'}
          size={'cut'}
          onClick={() => menuActionBtn(bulkRequestActionUrl, bulkActionData, 'DELETE', deleteDraftModalData)}
        >
          <i className="fas fa-ban"></i>
          <span className="tw-pl-1.5">Delete</span>
        </Button>
      )}
      {isStatusCompleted(status) && (
        <Button
          type={'button'}
          color={'lightGray'}
          size={'cut'}
          onClick={() => {
            menuActionBtn(markArchivedTicketUrl, bulkActionArchive, 'PATCH')
          }}
        >
          <i className="far fa-check"></i>
          <span className="tw-pl-1.5">Archive</span>
        </Button>
      )}
    </>
  )
}
