export function asDay(at: string): string {
  const numberOfDaysAgo = asDays(at)
  if (numberOfDaysAgo === 0) {
    return 'Today'
  } else if (numberOfDaysAgo === 1) {
    return 'Yesterday'
  }
  return new Date(at).toLocaleDateString()
}

function asDays(at: string): number {
  const date = new Date(at)
  const now = new Date()
  const diff = now.getTime() - date.getTime()
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days < 5) {
    const atDate = date.getDate()
    const nowDate = now.getDate()
    if (atDate <= nowDate) {
      return nowDate - atDate
    }
  }
  return days
}

export function asDaysAgo(at: string): string {
  const numberOfDaysAgo = asDays(at)
  if (numberOfDaysAgo === 0) {
    return 'Today'
  } else if (numberOfDaysAgo === 1) {
    return 'Yesterday'
  }
  return `${numberOfDaysAgo} days`
}

export function asTimeDuration(minutes: number): string {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = Math.round(minutes - hours * 60)
  const paddedMinutes = remainingMinutes.toString().padStart(2, '0')
  return `${hours}:${paddedMinutes}`
}

export function displayDate(date: string | number, tz = true): string {
  if (!date) {
    return ''
  }

  return new Date(date).toLocaleString('default', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h12',
    ...(tz ? { timeZoneName: 'short' } : {}),
  })
}

export function displayHoursAndMinutes(date: string): string {
  return new Date(date).toLocaleTimeString('default', {
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h24',
  })
}

export function getHoursFromMinutes(minutes: number): string {
  const m = minutes % 60
  const h = (minutes - m) / 60
  return `${h}h${m.toString().padStart(2, '0')}`
}

export function decimalHoursToMinutes(hours: number) {
  return hours / 0.0167
}
