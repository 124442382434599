import { baseRequest, getApi } from '../api'

// This list is not a full list of all features
// Feel free to add more as needed
export type features =
  | 'collaborative_review'
  | 'company_tags'
  | 'custom_branding'
  | 'gen_ai_inpainting'
  | 'merging'
  | 'preview_incomplete_files'
  | 'production_ticket_experience'
  | 'universal_search'
  | 'universal_search_update_pipeline'
  | 'white_label_ga'

export type featureFlags = Record<features, boolean>[]

export async function isEnabled(key: string): Promise<boolean> {
  const { data } = await baseRequest('/api/internal/features', getApi)
  return data.features[key]
}

export async function getFeatureFlags(): Promise<featureFlags> {
  const { data } = await baseRequest('/api/internal/features', getApi)
  return data.features as featureFlags
}
