import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query'

import { ReactNode } from 'react'

interface QueryClientProviderProps {
  children: ReactNode
}

export default function QueryClientProvider({ children }: QueryClientProviderProps) {
  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>
}
export const queryClient = new QueryClient()
