import { LegacyRef, ReactNode } from 'react'

interface TableProps {
  children: ReactNode
  innerRef: LegacyRef<HTMLTableElement>
}

export default function Table(props: TableProps): JSX.Element {
  return (
    <div className="tw-flex tw-flex-col">
      <div>
        <div className="tw-py-2 tw-align-middle tw-inline-block tw-min-w-full tw-sm:px-6 tw-lg:px-8">
          <div className="tw-border-gray-200 tw-sm:rounded-lg tw-border-0">
            <table ref={props.innerRef} className="tw-min-w-full tw-table-auto tw-w-full">
              {props.children}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
