import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface BreadcrumbProps {
  text: string
  href: string
  className?: string
}

export default function Breadcrumb({ text, href, className }: BreadcrumbProps) {
  return (
    <div className={className}>
      <a href={href} className="tw-text-neutral-400 hover:tw-text-cornflower-500">
        <FontAwesomeIcon icon={['far', 'chevron-left']} className="tw-mr-3" />
        {text}
      </a>
    </div>
  )
}
