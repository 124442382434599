import SortableTableHeader from 'components/core/sortable-table-header'
import React, { ReactNode } from 'react'

interface CancellationsListProps {
  children: ReactNode
  headers: string[]
  sortColumn: string
  sortDirection: 'ASC' | 'DESC'
  sortBy: (column?: string, direction?: 'ASC' | 'DESC') => void
}

export default function CancellationsList(props: CancellationsListProps): JSX.Element {
  const { headers, sortColumn, sortDirection, sortBy } = props
  const nonSortable = ['Designers', 'Supporting Documentation', 'Status Log', '']

  const Headers = () => (
    <tr data-testid="cancellation-list-header">
      {headers.map((header) => (
        <SortableTableHeader
          key={header}
          column={header}
          sortedClassName="tw-text-peppercorn-800"
          onClick={sortBy}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          isSortable={!nonSortable.includes(header)}
        >
          {/* @ts-ignore */}
          {header}
        </SortableTableHeader>
      ))}
    </tr>
  )

  return (
    <div className="tw-bg-white tw-p-4 tw-overflow-x-auto">
      <table className="tw-w-full tw-table-auto">
        <thead className="tw-text-peppercorn-800">
          <Headers />
        </thead>
        <tbody className="tw-bg-white tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-black">
          {props.children}
        </tbody>
      </table>
    </div>
  )
}
