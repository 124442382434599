import { useState } from 'react'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import { Format } from 'interfaces/format'
import { Skill } from 'interfaces/skill'
import { Switch } from '@headlessui/react'
import { SkillCategory } from 'interfaces/skill-category'
import SelectBox from 'lib/components/dropdown/select-box'
import TextInput from 'components/core/text-input/text-input'
import Button from 'components/core/button'
import { routes, updateSkill } from 'lib/api/admin/skills/skills'
import { LoadingScreen } from 'components/pages/requests/empty-screens'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  option: 'tw-mx-1 tw-flex-grow tw-text-right',
  select: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  switch: {
    disabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-neutral-200',
    enabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-gherkin-500',
  },
  switchGroup: 'tw-flex tw-items-center',
  switchLabel: 'tw-mr-1 tw-mb-0 tw-text-black tw-uppercase',
  switchToggle: {
    disabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw--translate-x-1',
    enabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-translate-x-4',
  },
  switchWrapper: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md tw-grid tw-grid-cols-3 tw-gap-4',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

interface SkillEditPageProps {
  skill?: Skill
  skillCategories: SkillCategory[]
  formats: Format[]
}

function AdminSkillsEdit({ skill, skillCategories, formats }: SkillEditPageProps) {
  const [name, setName] = useState<string>(skill.name)
  const [permalink, setPermalink] = useState<string>(skill.permalink)
  const [averageTime, setAverageTime] = useState<number>(+skill.averageTime)
  const [skillCategoryId, setSkillCategoryId] = useState<number>(skill.skillCategoryId)
  const [selectedFormats, setSelectedFormats] = useState<Format[]>(skill.formats)
  const [image, setImage] = useState<File | null>(null)
  const [updating, setUpdating] = useState<boolean>(false)

  const { alert, notice } = useToastContext()

  const scopeOfService = () =>
    skillCategories.find(({ id }) => id === skillCategoryId)?.scopeOfService || 'No Category Selected'
  const options = () =>
    skillCategories.map(({ id, name }) => ({
      value: id.toString(),
      displayElement: <SkillCategoryOption categoryName={name} />,
    }))

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setUpdating(true)
    const params = new FormData()
    params.append('name', name)
    params.append('average_time', averageTime?.toString())
    params.append('permalink', permalink)
    params.append('skill_category_id', skillCategoryId?.toString())
    if (image !== null) {
      params.append('image', image)
    }
    selectedFormats?.forEach(({ id }) => params.append('format_ids[]', id.toString()))

    try {
      const { status } = await updateSkill(skill.id, params)
      if (status === 200) {
        notice('Skill updated successfully')
        window.open(routes.index, '_self')
      }
    } catch (error) {
      console.error(error)
      alert('Something went wrong - please try again.')
    } finally {
      setUpdating(false)
    }
  }

  if (updating) {
    return <LoadingScreen />
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <a href={routes.index} className="tw-text-gherkin-500 tw-text-sm tw-mb-4 tw-block">
          Back to Skills
        </a>
        <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">{`Edit ${skill.name}`}</h1>
        <form data-testid="edit-form" onSubmit={handleSubmitForm}>
          <div className="tw-flex tw-flex-col tw-pb-2">
            <label htmlFor="image" className="tw-font-semibold tw-text-peppercorn-700">
              Image
            </label>
            {skill ? <img src={skill.imageUrl} alt={skill.name} className="tw-max-w-lg tw-pb-2" /> : null}
            <input type="file" name="image" onChange={(e) => setImage(e.target.files[0])} />
          </div>
          <TextInput
            data-testid="name"
            label="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            name="name"
          />
          <TextInput
            label="Permalink"
            onChange={(e) => setPermalink(e.target.value)}
            value={permalink}
            name="permalink"
          />
          <TextInput
            label="Average Skill Time"
            value={averageTime}
            onChange={(e) => setAverageTime(+e.target.value)}
            min={0}
            step={1}
            type="number"
            name="averageTime"
          />
          <div className={classNames.select}>
            <SelectBox
              data-testid="skill-category"
              label="Skill Category"
              handleChange={(skillCategoryId) => setSkillCategoryId(+skillCategoryId)}
              selectedValue={skillCategoryId?.toString()}
              options={options()}
              className="tw-min-w-48"
            />
          </div>
          <h5 className="tw-mt-4">
            Scope of Service:
            <span className="tw-ml-2 tw-text-peppercorn">{scopeOfService()}</span>
          </h5>
          <h5 className="tw-mt-4">Formats</h5>
          <FormatList formats={formats} selectedFormats={selectedFormats} setSelectedFormats={setSelectedFormats} />
          <div className="tw-mt-8"></div>
          <Button data-testid="submit-button" color="green" type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

function SkillCategoryOption({ categoryName }: { categoryName: string }) {
  return <div className={classNames.option}>{categoryName}</div>
}

interface FormatListProps {
  formats: Format[]
  selectedFormats: Format[]
  setSelectedFormats: (formats: Format[]) => void
}

function FormatList({ formats, selectedFormats, setSelectedFormats }: FormatListProps) {
  const selectedFormat = (formatId) => selectedFormats?.map(({ id }) => id).includes(formatId)

  return (
    <div className={classNames.switchWrapper}>
      {formats.map((format) => (
        <Switch.Group key={format.id}>
          <div className={classNames.switchGroup}>
            <Switch.Label className={classNames.switchLabel}>{format.name}</Switch.Label>
            <Switch
              checked={selectedFormat(format.id)}
              onChange={(checked) =>
                setSelectedFormats(
                  checked ? [...selectedFormats, format] : selectedFormats.filter(({ id }) => id !== format.id)
                )
              }
              className={selectedFormat(format.id) ? classNames.switch.enabled : classNames.switch.disabled}
              style={{ width: '38px', height: '18px' }}
            >
              <span className="sr-only">{format.id}</span>
              <span
                className={
                  selectedFormat(format.id) ? classNames.switchToggle.enabled : classNames.switchToggle.disabled
                }
                aria-hidden="true"
                style={{
                  width: '14px',
                  height: '14px',
                  transform: `translateX(${selectedFormat(format.id) ? '14px' : '-4px'})`,
                }}
              ></span>
            </Switch>
          </div>
        </Switch.Group>
      ))}
    </div>
  )
}

export default function AdminSkillsEditPage(props: SkillEditPageProps) {
  return (
    <ToastProvider>
      <AdminSkillsEdit {...props} />
    </ToastProvider>
  )
}
