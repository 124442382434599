import Pagination from 'components/elements/pagination'

import { useTable } from './use-table'
import { flexRender } from '@tanstack/react-table'
import Breadcrumb from 'components/core/breadcrumb'
import { AnimatedLoadingScreen, EmptySearchScreen } from '../requests/empty-screens'
import Filters from './filters'
import Button from 'components/core/button'
import { ArrowDown, ArrowDownUp, ArrowUp, LucideRefreshCcw } from 'lucide-react'
import { useFirstRender } from './use-first-render'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'

const sortIcons = {
  asc: <ArrowUp className="lu-sm tw-text-neutral-500 tw-mr-4" />,
  desc: <ArrowDown className="lu-sm tw-text-neutral-500 tw-mr-4" />,
  false: <ArrowDownUp className="lu-sm tw-text-neutral-500 tw-mr-4" />,
}

export const Search = () => {
  const { pagination, query, count, loading, error, table } = useTable()

  const { isModalVisible, modalData, setModalVisible } = useConfirmationModalContext()

  const { loading: loadingFirstRender } = useFirstRender()

  if (loading || loadingFirstRender) {
    return (
      <div className="tw-h-screen-60 tw-flex tw-items-center tw-justify-center">
        <AnimatedLoadingScreen
          variant="binoculars"
          text="We're searching your requests..."
          subText="Hang tight while we locate these."
        />
      </div>
    )
  }

  const emptyScreen = query.data?.data?.length === 0 && <EmptySearchScreen />
  const errorScreen = error && (
    <>
      <AnimatedLoadingScreen
        variant="errorHole"
        text="Uh oh! Looks like your search didn't load..."
        subText="You can retry or head back to your queue."
      />
      <Button
        onClick={() => {
          window.location.reload()
        }}
        color="purple"
        className="tw-mt-4"
      >
        <LucideRefreshCcw className="tw-mr-1" /> Retry
      </Button>
    </>
  )

  return (
    <>
      <ConfirmationModal
        title={modalData['title']}
        message={modalData['message']}
        confirmBtnText={modalData['confirmBtnText']}
        confirmAction={modalData['confirmAction']}
        cancelAction={() => setModalVisible(false)}
        visible={isModalVisible}
      />
      <Breadcrumb className="tw-mt-3" text="Back to requests" href={`/requests`} />
      <div className="tw-flex tw-flex-row">
        <h1 className="tw-text-2xl">
          {table.getColumn('query').getFilterValue()
            ? `Results for "${table.getColumn('query').getFilterValue()}"`
            : 'Results'}{' '}
          ({count})
        </h1>
      </div>
      <div className="tw-flex">
        <Filters columns={table.getAllColumns()} table={table} />
      </div>
      {errorScreen || emptyScreen ? (
        <div className="tw-items-center tw-justify-center tw-flex tw-flex-col"> {errorScreen || emptyScreen}</div>
      ) : (
        <>
          <table className="tw-w-full tw-bg-white">
            <>
              <thead className="tw-bg-neutral-50 tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-t tw-border-b-2 tw-border-neutral-200">
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr className="" key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            className="tw-p-2 tw-uppercase tw-text-xs tw-font-medium tw-text-black"
                            key={header.id}
                            id={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.column.getCanSort() && sortIcons[(header.column.getIsSorted() as string) ?? null]}
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      className="tw-bg-neutral-50 tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-t-2 tw-border-b-2 tw-border-neutral-200"
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </>
          </table>
          <Pagination
            pageCount={query.data?.meta?.total / pagination.pageSize}
            onPageChange={({ selected }) => {
              table.setPageIndex(selected + 1)
            }}
            forcePage={pagination.pageIndex - 1}
          />
        </>
      )}
    </>
  )
}
