import AdminFilters, { SearchTitleFilter } from 'components/elements/admin-filters'
import { Dispatch } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CheckboxFilter, DateFilter } from '../shared/filters/filters'

import { fetchTransfersProps } from './transfers-page'

import Button from 'components/core/button'

import styles from '../shared/filters/filters.module.scss'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import SingleSelect from 'components/core/single-select'
import { humanize } from 'lib/string/utils'

const dateFilters = ['transferStartDate', 'transferEndDate', 'transferRequestStartDate', 'transferRequestEndDate']

interface TransfersFilterProps {
  setFilters: Dispatch<TransferFilters>
  filters: TransferFilters
  setLabel: string
  textFilterPlaceholder: string
  transferReasonsOptions: string[]
  transferTypesOptions: string[]
  fetchTransfers: (params?: fetchTransfersProps) => void
  isDownloading: boolean
}

export interface TransferFilters {
  transferUnassigned: boolean | string
  transfersReasonEq: string
  companyNameCont: string
  transfersTypeEq: string
  transferStartDate: Date
  transferEndDate: Date
  transferRequestStartDate: Date
  transferRequestEndDate: Date
}

export const parsedFilterValues = (filters: TransferFilters): TransferFilters => {
  const newFilters = { ...filters }

  const {
    transferUnassigned,
    transfersReasonEq,
    transfersTypeEq,
    transferStartDate,
    transferEndDate,
    transferRequestStartDate,
    transferRequestEndDate,
  } = newFilters

  if (transferUnassigned) newFilters.transferUnassigned = transferUnassigned === 'true'
  if (transfersReasonEq) newFilters.transfersReasonEq = transfersReasonEq
  if (transfersTypeEq) newFilters.transfersTypeEq = transfersTypeEq
  // @ts-ignore
  if (transferStartDate) newFilters.transferStartDate = new Date(Date.parse(transferStartDate as string))
  // @ts-ignore
  if (transferEndDate) newFilters.transferEndDate = new Date(Date.parse(transferEndDate as string))
  if (transferRequestStartDate)
    // @ts-ignore
    newFilters.transferRequestStartDate = new Date(Date.parse(transferRequestStartDate as string))
  // @ts-ignore
  if (transferRequestEndDate) newFilters.transferRequestEndDate = new Date(Date.parse(transferRequestEndDate as string))

  return newFilters
}

interface TransferReasonsFilterProps {
  transferReasonsOptions: string[]
  filters: TransferFilters
  filterKey: string
  setFilters: (TransferFilters) => void
}

export function TransferReasonsFilter({
  transferReasonsOptions,
  filters,
  setFilters,
  filterKey,
}: TransferReasonsFilterProps): JSX.Element {
  return (
    <>
      <label className={styles.filterTitle} htmlFor="cancellation-reasons">
        Transfer Reasons
      </label>
      {transferReasonsOptions ? (
        <SingleSelect
          name="transfer-reasons"
          inputId="transfer-reasons"
          className={styles.input}
          value={
            filters[filterKey] && {
              label: humanize(filters[filterKey]),
              value: filters[filterKey],
            }
          }
          placeholder="Select Transfer Reason"
          options={transferReasonsOptions.map((option) => ({
            value: option,
            label: humanize(option),
          }))}
          onChange={(e) => {
            const newFilter = { ...filters }
            newFilter[filterKey] = String(e.value)
            setFilters({
              ...newFilter,
            })
          }}
        />
      ) : (
        <FontAwesomeIcon icon="spinner-third" spin />
      )}
    </>
  )
}

interface TransferTypesFilterProps {
  transferTypesOptions: string[]
  filters: TransferFilters
  filterKey: string
  setFilters: (TransferFilters) => void
}

const transfer_types_map = {
  customer_or_cs_initiated: 'CS/Customer',
  production_initiated: 'Production',
}

export function TransferTypesFilter({
  transferTypesOptions,
  filters,
  setFilters,
  filterKey,
}: TransferTypesFilterProps): JSX.Element {
  return (
    <>
      <label className={styles.filterTitle} htmlFor="transfer-type">
        Transfer Types
      </label>
      {transferTypesOptions ? (
        <SingleSelect
          name="transfer-types"
          inputId="transfer-types"
          className={styles.input}
          value={
            filters[filterKey] && {
              label: humanize(filters[filterKey]),
              value: filters[filterKey],
            }
          }
          placeholder="Select Transfer Type"
          options={transferTypesOptions.map((option) => ({
            value: transfer_types_map[option],
            label: transfer_types_map[option],
          }))}
          onChange={(e) => {
            const newFilter = { ...filters }
            newFilter[filterKey] = String(e.value)
            setFilters({
              ...newFilter,
            })
          }}
        />
      ) : (
        <FontAwesomeIcon icon="spinner-third" spin />
      )}
    </>
  )
}

export const emptyTransferFilters: TransferFilters = {
  transferUnassigned: false,
  transfersReasonEq: '',
  companyNameCont: '',
  transfersTypeEq: '',
  transferStartDate: null,
  transferEndDate: null,
  transferRequestStartDate: null,
  transferRequestEndDate: null,
}

export default function TransfersFilters(props: TransfersFilterProps): JSX.Element {
  const {
    transferReasonsOptions,
    transferTypesOptions,
    filters,
    setFilters,
    textFilterPlaceholder,
    setLabel,
    fetchTransfers,
    isDownloading,
  } = props

  const { transferStartDate, transferEndDate, transferRequestStartDate, transferRequestEndDate } = filters

  const setTransferStartDate = (date: Date) => {
    setFilters({ ...filters, transferStartDate: date })
  }

  const setTransferEndDate = (date: Date) => {
    setFilters({ ...filters, transferEndDate: date })
  }

  const setTransferRequestStartDate = (date: Date) => {
    setFilters({ ...filters, transferRequestStartDate: date })
  }

  const setTransferRequestEndDate = (date: Date) => {
    setFilters({ ...filters, transferRequestEndDate: date })
  }

  const copyFiltersToClipboard = async () => {
    const sanitizedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (!value) return acc
      if (dateFilters.includes(key)) {
        return [...acc, [key, value?.toISOString()]]
      }
      return [...acc, [key, value]]
    }, [])
    const urlParams = new URLSearchParams(sanitizedFilters).toString()
    await navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}?${urlParams}`)
    window.alert('Filters copied to clipboard!')
  }

  const DownloadCSV = () => (
    <div>
      <Button
        className="tw-ml-2"
        size="cut"
        color="green"
        type="button"
        onClick={() => fetchTransfers({ formatParams: { format: 'csv' } })}
      >
        <FontAwesomeIcon icon={'download'} className="tw-mr-1" />
      </Button>
    </div>
  )

  return (
    <AdminFilters
      emptyFilters={emptyTransferFilters}
      filters={filters}
      setFilters={setFilters}
      handleSubmit={() => fetchTransfers()}
      downloadCSV={isDownloading ? () => <LoadingScreen /> : DownloadCSV}
      onClear={fetchTransfers}
    >
      <SearchTitleFilter
        value={filters.companyNameCont}
        updateValue={(value) =>
          setFilters({
            ...filters,
            companyNameCont: value,
          })
        }
        placeholder={textFilterPlaceholder}
      />

      <TransferReasonsFilter
        transferReasonsOptions={transferReasonsOptions}
        filters={filters}
        filterKey="transfersReasonEq"
        setFilters={setFilters}
      />

      <TransferTypesFilter
        transferTypesOptions={transferTypesOptions}
        filters={filters}
        filterKey="transfersTypeEq"
        setFilters={setFilters}
      />

      <label className={styles.filterTitle} htmlFor="transfer-date-range">
        Transfer Date Range
      </label>

      <DateFilter selected={transferStartDate} onChange={setTransferStartDate} placeholder="Start Date" />
      <DateFilter selected={transferEndDate} onChange={setTransferEndDate} placeholder="End Date" />

      <label className={styles.filterTitle} htmlFor="transfer-requested-date-range">
        Transfer Requested Date Range
      </label>

      <DateFilter selected={transferRequestStartDate} onChange={setTransferRequestStartDate} placeholder="Start Date" />
      <DateFilter selected={transferRequestEndDate} onChange={setTransferRequestEndDate} placeholder="End Date" />

      {/* @ts-ignore */}
      <CheckboxFilter filterKey="transferUnassigned" filters={filters} setFilters={setFilters} setLabel={setLabel} />

      <Button color="outlineDark" type="button" className="tw-mb-4" onClick={copyFiltersToClipboard}>
        Share filters
      </Button>
    </AdminFilters>
  )
}
