import { Column, Table } from '@tanstack/react-table'
import MultiSelect, { ValueType } from 'components/core/multi-select'
import Search from 'components/core/search'
import UserAvatar from 'components/elements/user-avatar'
import { fileTypeSrc } from 'lib/ticket/utils'
import DateRange from './date-range'
import { useMemo, useState } from 'react'
import { useFirstRender } from './use-first-render'
import { debounce } from 'lodash'

interface FiltersProps<T> {
  columns: Column<T>[]
  table: Table<T>
}

interface ColumnMeta {
  filterKey: string
  filterVariant: FilterInput
  filterTitle: string
  filterStartKey?: string
  filterEndKey?: string
}

export interface FilterProps {
  title?: string
  value: ValueType[] | string
  onChange: (value: unknown) => void
  table: Table<any>
  meta: ColumnMeta
}

export type FilterInput = keyof typeof filters

const DebouncedSearch = ({ title, value, onChange }: FilterProps) => {
  const [search, setSearch] = useState(value?.[0] || '')

  const debouncedOnChange = useMemo(() => debounce(onChange, 1000), [onChange])

  const handleChange = (value: string) => {
    setSearch(value)
    debouncedOnChange(value)
  }

  return <Search value={search} onChange={handleChange} placeholder={title} />
}

const BrandFilter = ({ title, value, onChange }: FilterProps) => {
  const { brands } = useFirstRender()

  return (
    <MultiSelect
      title={title}
      value={value as ValueType[]}
      options={brands?.map((brand) => ({ value: String(brand.id), label: brand.name, data: brand })) || []}
      onChange={onChange}
      multi
    />
  )
}

const StatusFilter = ({ title, value, onChange }: FilterProps) => {
  const { statuses } = useFirstRender()

  return (
    <MultiSelect
      title={title}
      value={value as ValueType[]}
      options={statuses?.map((status) => ({ value: status, label: status })) || []}
      onChange={onChange}
      multi
    />
  )
}

const SkillFilter = ({ title, value, onChange }: FilterProps) => {
  const { skills } = useFirstRender()

  return (
    <MultiSelect
      title={title}
      value={value as ValueType[]}
      options={skills?.map((status) => ({ value: status, label: status })) || []}
      onChange={onChange}
      multi
    />
  )
}

const CreatedBy = ({ title, value, onChange }: FilterProps) => {
  const { users } = useFirstRender()

  return (
    <MultiSelect
      title={title}
      value={value as ValueType[]}
      options={users?.map((user) => ({ value: String(user.id), label: user.fullName, data: user })) || []}
      onChange={onChange}
      optionComponent={(option) => {
        return <UserAvatar name={option.data.fullName} avatar={option.data?.avatar} />
      }}
      multi
    />
  )
}

const Format = ({ title, value, onChange }: FilterProps) => {
  const fileTypes = ['pdf', 'jpg', 'png']

  return (
    <MultiSelect
      title={title}
      value={value as ValueType[]}
      options={fileTypes?.map((fileType) => ({ value: fileType, label: `.${fileType}` })) || []}
      onChange={onChange}
      optionComponent={(option) => {
        return <UserAvatar name={option.value} avatar={fileTypeSrc(option.value)} />
      }}
      multi
    />
  )
}

const filters: Record<string, React.FC<Partial<FilterProps>>> = {
  search: DebouncedSearch,
  multiSelect: MultiSelect,
  brand: BrandFilter,
  status: StatusFilter,
  skill: SkillFilter,
  createdBy: CreatedBy,
  format: Format,
  date: DateRange,
}

export default function Filters<T>({ columns, table }: FiltersProps<T>) {
  return (
    <>
      {columns.map((column) => {
        if (!column.columnDef.meta) {
          return null
        }

        const { filterVariant, filterTitle } = column.columnDef.meta as ColumnMeta
        const FilterComponent = filters[filterVariant]

        if (!FilterComponent) {
          return null
        }

        return (
          <FilterComponent
            key={filterTitle}
            title={filterTitle}
            value={column.getFilterValue() as ValueType[]}
            onChange={column.setFilterValue}
            table={table}
            meta={column.columnDef.meta as ColumnMeta}
          />
        )
      })}
    </>
  )
}
