import { Draggable, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { DraggableTableRow } from 'components/core/table-row'
import DropdownMenu from 'components/elements/dropdown-menu'
import Avatar from 'components/core/avatar'
import Checkbox from 'components/core/checkbox'
import ActiveTabMenu from 'components/elements/dropdown/menus/active-tab-menu'
import DraftTabMenu from 'components/elements/dropdown/menus/draft-tab-menu'
import ArchivedTabMenu from 'components/elements/dropdown/menus/archived-tab-menu'
import { Ticket } from 'interfaces/ticket'
import { hasOnlyFalsyValues } from 'lib/object/utils'

import { EmptyTicketsScreen, EmptySearchScreen, LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import { isStatusOnDeck } from 'lib/ticket/utils'
import TicketLinkElement from './ticket-link-element'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import RatingRow from './rating-row'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import UserAvatar from 'components/elements/user-avatar'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'

interface TicketRowProps {
  providedDragHandleProps: DraggableProvidedDragHandleProps
  ticket: Ticket
  priorityMode?: boolean
}

export const TicketRow = ({ priorityMode, providedDragHandleProps, ticket }: TicketRowProps): JSX.Element => {
  const {
    activeTab,
    dragDisable,
    bulkSelect,
    bulkStateSelected,
    bulkActionIds,
    priorityModeEnabled,
    userCanSort,
    fetchTickets,
    setBulkActionIds,
    setBulkStateSelected,
  } = useSingleQueueContext()

  const { menuButtonActionWithCallbacks } = useConfirmationModalContext()

  const {
    id,
    position,
    subject,
    friendlyStatusName,
    category,
    designer,
    user,
    url,
    filesDownloadUrl,
    ticketFilesCount,
    lastUpdatedForUser,
  } = ticket

  const menuButtonAction = menuButtonActionWithCallbacks(fetchTickets, () => {
    setBulkActionIds([])
    setBulkStateSelected(null)
  })

  return (
    <>
      {activeTab === 'active' && (
        <td
          className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-break-normal tw-text-peppercorn-100 active:focus:tw-peppercorn-200"
          {...providedDragHandleProps}
        >
          {userCanSort && !(dragDisable || !isStatusOnDeck(friendlyStatusName)) && (
            <span>
              <i className="fas fa-grip-vertical tw-color-peppercorn-50"></i>
            </span>
          )}
        </td>
      )}

      {activeTab === 'draft' && <td className="tw-pl-4 tw-px-6 tw-py-4"></td>}
      {activeTab !== 'archived' && (
        <td>
          {!priorityMode && (
            <Checkbox
              id={id.toString()}
              checked={bulkActionIds.includes(id)}
              label={''}
              disabled={bulkStateSelected !== null && bulkStateSelected !== friendlyStatusName}
              onChange={(event) => {
                bulkSelect(event, id, friendlyStatusName)
              }}
            />
          )}
        </td>
      )}
      {activeTab === 'active' && (
        <td className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-font-semibold tw-break-normal tw-text-xs">
          {position}
        </td>
      )}

      <td className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-font-semibold tw-break-normal tw-text-sm tw-text-gray-900">
        {priorityMode ? ticket.subject : <TicketLinkElement ticket={ticket} showTags={true} />}
      </td>

      <td className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-break-normal tw-min-w-130 tw-border-1">
        <TicketStatusBadge status={friendlyStatusName}></TicketStatusBadge>
      </td>
      <td className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-break-normal tw-text-xs tw-font-medium tw-font-gray-700">
        {category}
      </td>
      {activeTab === 'archived' && (
        <td className="tw-pl-4 tw-pr-2 tw-py-2">
          <RatingRow ticket={ticket} />
        </td>
      )}
      {activeTab === 'active' && (
        <td className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-break-normal tw-text-xs">
          <UserAvatar
            priorityModeEnabled={priorityModeEnabled}
            name={designer?.name}
            avatar={designer?.avatar}
            isDesigner={true}
          />
        </td>
      )}
      <td className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-break-normal tw-text-xs">
        <div className="tw-flex tw-items-center">
          <div className="tw-flex-shrink-0 tw-h-8 tw-w-8">
            <Avatar src={user?.avatar} name={user?.fullName} />
          </div>
          <div className="tw-ml-4">
            <div className="tw-font-medium tw-text-gray-700 tw-text-xs">{user?.fullName}</div>
          </div>
        </div>
      </td>
      {activeTab !== 'draft' && (
        <td className="tw-pl-4 tw-px-6 tw-py-4 tw-whitespace-normal tw-font-semibold tw-break-normal tw-text-xs">
          {lastUpdatedForUser}
        </td>
      )}
      <td className="tw-w-12">
        {!priorityMode && (
          <DropdownMenu>
            {activeTab === 'active' && (
              <ActiveTabMenu
                id={id}
                subject={subject}
                filesDownloadUrl={filesDownloadUrl}
                status={friendlyStatusName}
                userId={user?.id}
                menuActionBtn={menuButtonAction}
                ticketFilesCount={ticketFilesCount}
              />
            )}
            {activeTab === 'draft' && (
              <DraftTabMenu
                requestId={id}
                url={url}
                subject={subject}
                userId={user.id}
                menuActionBtn={menuButtonAction}
              />
            )}
            {activeTab === 'archived' && (
              <ArchivedTabMenu
                id={id}
                filesDownloadUrl={filesDownloadUrl}
                menuActionBtn={menuButtonAction}
                status={friendlyStatusName}
                subject={subject}
                ticketFilesCount={ticketFilesCount}
                userId={user?.id}
              />
            )}
          </DropdownMenu>
        )}
      </td>
    </>
  )
}

const TableContent = (): JSX.Element => {
  const { dragDisable, tickets } = useSingleQueueContext()

  return (
    <>
      {tickets.map((ticket, index) => {
        return (
          <Draggable
            key={ticket.id.toString()}
            draggableId={ticket.id.toString()}
            index={index}
            isDragDisabled={dragDisable || !isStatusOnDeck(ticket.friendlyStatusName)}
          >
            {(provided) => (
              <DraggableTableRow innerRef={provided.innerRef} draggableProvided={provided}>
                <TicketRow providedDragHandleProps={provided.dragHandleProps} ticket={ticket} />
              </DraggableTableRow>
            )}
          </Draggable>
        )
      })}
    </>
  )
}

const PriorityModeTableContent = (): JSX.Element => {
  const { tickets } = useSingleQueueContext()
  const displayedTickets = tickets.filter((ticket) => isStatusOnDeck(ticket.friendlyStatusName))

  return (
    <>
      {displayedTickets.map((ticket, index) => {
        return (
          <Draggable key={ticket.id.toString()} draggableId={ticket.id.toString()} index={index} isDragDisabled={false}>
            {(provided) => (
              <DraggableTableRow innerRef={provided.innerRef} draggableProvided={provided}>
                <TicketRow providedDragHandleProps={provided.dragHandleProps} ticket={ticket} priorityMode />
              </DraggableTableRow>
            )}
          </Draggable>
        )
      })}
    </>
  )
}

const TicketListTableContent = ({ priorityMode = false }: { priorityMode?: boolean }): JSX.Element => {
  const { activeTab, tickets, loading, filters } = useSingleQueueContext()

  if (loading) {
    return <LoadingScreenTableRow />
  }

  if (tickets.length === 0 && hasOnlyFalsyValues(filters)) {
    return <EmptyTicketsScreen activeTab={activeTab} />
  }

  if (tickets.length === 0 && !hasOnlyFalsyValues(filters)) {
    return <EmptySearchScreen />
  }

  if (priorityMode) {
    return <PriorityModeTableContent />
  }

  return <TableContent />
}

export default TicketListTableContent
