import { useSingleQueueContext } from 'providers/single-queue-provider'
import Modal from 'lib/components/modals/modal'
import Button from 'components/core/button'
import { useToastContext } from 'providers/toast-provider'
import { isStatusOnDeck } from 'lib/ticket/utils'

const classNames = {
  bodyWrapper: 'tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600 tw-mt-8',
  buttonWrapper: 'tw-flex tw-flex-row tw-gap-2 tw-justify-end tw-items-center tw-mt-4',
}

export function PriorityCancelModal() {
  const { fetchTickets, priorityCancelModal, setPriorityMode, setPriorityCancelModal } = useSingleQueueContext()
  const { notice } = useToastContext()

  const handleDiscardChanges = () => {
    setPriorityMode(false)
    setPriorityCancelModal(false)
    fetchTickets()
    notice('Request priority changes have been discarded')
  }

  return (
    <Modal setOpen={setPriorityCancelModal} open={priorityCancelModal} size="md">
      <Modal.Header as="div">
        <h4>Discard Request Priority Changes?</h4>
      </Modal.Header>
      <Modal.Body setOpen={setPriorityCancelModal} closeButton>
        <div className={classNames.bodyWrapper}>
          <span>Are you sure you want to cancel request re-prioritization and discard any changes?</span>
          <div className={classNames.buttonWrapper}>
            <Button color="lightGray" type="button" onClick={() => setPriorityCancelModal(false)}>
              Go back
            </Button>
            <Button color="red" type="button" onClick={handleDiscardChanges}>
              Discard Changes
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export function PriorityConfirmModal() {
  const { priorityConfirmModal, setPriorityMode, setPriorityConfirmModal, sortTickets, tickets } =
    useSingleQueueContext()
  const onDeckTickets = tickets.filter((ticket) => isStatusOnDeck(ticket.friendlyStatusName))

  const handleConfirmChanges = () => {
    setPriorityMode(false)
    setPriorityConfirmModal(false)
    sortTickets(onDeckTickets)
  }

  return (
    <Modal setOpen={setPriorityConfirmModal} open={priorityConfirmModal} size="md">
      <Modal.Header as="div">
        <h4>Change Your Queue Priority?</h4>
      </Modal.Header>
      <Modal.Body setOpen={setPriorityConfirmModal} closeButton>
        <div className={classNames.bodyWrapper}>
          <span>This will reassign your requests based on priority and potentially:</span>
          <ol>
            <li>Unassign any existing assigned requests that are now lower in priority</li>
            <li>Assign any previously unassigned requests that are now higher in</li>
          </ol>
          <div className={classNames.buttonWrapper}>
            <Button color="lightGray" type="button" onClick={() => setPriorityConfirmModal(false)}>
              Go back
            </Button>
            <Button color="purple" type="button" onClick={handleConfirmChanges}>
              Change priority
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
