import { useMemo, useState } from 'react'

import { CustomSize, SkillSize } from 'lib/api/skills/skills'
import Dropdown from 'lib/components/dropdown/dropdown'
import { convertStringToCustomSize, getSizesTitle } from 'lib/util/skill-sizes/skill-sizes'
import { cn } from 'lib/util/cn'

import { ErrorLabel, TitleWithErrorIcon } from './header-errors'
import SkillSizes from '../skills/skill-sizes'

interface RequestSizePopoverProps {
  skillSizes: SkillSize[]
  ticketSkillSizes: SkillSize[]
  ticketCustomSizes: string
  onChange: (sizes: { skillSize?: SkillSize[]; customSizes?: CustomSize[] }) => void
  showValidationError: boolean
  className?: string
}

const labelText = 'Size(s)*'
export default function RequestSizePopover({
  skillSizes,
  ticketSkillSizes,
  ticketCustomSizes,
  onChange,
  showValidationError,
  className = '',
}: RequestSizePopoverProps): JSX.Element {
  const [openDropdown] = useState(false)

  const sizeTitle = useMemo(() => {
    return getSizesTitle(ticketSkillSizes, ticketCustomSizes, 'Select Sizes')
  }, [ticketCustomSizes, ticketSkillSizes])

  const customSize = skillSizes?.length === 0 ? { width: '550px', maxHeight: '600px' } : null

  const customSizes = useMemo(() => convertStringToCustomSize(ticketCustomSizes), [ticketCustomSizes])

  return (
    <div className={cn('tw-px-4', className)}>
      <Dropdown
        icon="chevron"
        title={showValidationError ? <TitleWithErrorIcon title={sizeTitle} /> : sizeTitle}
        label={showValidationError ? <ErrorLabel label={labelText} /> : labelText}
        customSize={customSize}
        openByDefault={openDropdown}
      >
        <SkillSizes
          skillSizes={skillSizes}
          onSelect={onChange}
          ticketSkillSizes={ticketSkillSizes}
          ticketCustomSizes={customSizes}
        />
      </Dropdown>
    </div>
  )
}
