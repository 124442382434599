import SkillsProvider from '../skills/skills-provider'
import SkillCategories from '../skills/skill-categories'
import { SkillCategorySkill } from 'lib/api/skills/skills'
import Dropdown from 'lib/components/dropdown/dropdown'
import { ErrorLabel, TitleWithErrorIcon } from './header-errors'
import SearchSkillsByName from '../skills/search-skills-by-name'
import Tooltip from 'lib/components/tooltip/tooltip'
import { ReactNode } from 'react'

interface RequestTypePopoverProps {
  onChange: (skill: SkillCategorySkill) => void
  skill: SkillCategorySkill | null
  showValidationError: boolean
  disabled: boolean
}

const label = 'Request Type*'

export default function RequestTypePopover({
  onChange,
  skill,
  showValidationError,
  disabled,
}: RequestTypePopoverProps): JSX.Element {
  function handleSelect(selectedSkill: SkillCategorySkill, close: () => void) {
    if (selectedSkill.id !== skill?.id) {
      onChange(selectedSkill)
      close()
    }
  }

  const title = skill?.name || 'Select Request Type'

  function TooltipWrapper({ children }: { children: ReactNode }) {
    return disabled ? (
      <Tooltip
        content="Request type cannot be changed after a designer has been assigned"
        direction="right"
        width={400}
      >
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    )
  }

  return (
    <SkillsProvider>
      <div className="tw-px-4">
        <Dropdown
          icon="chevron"
          title={showValidationError ? <TitleWithErrorIcon title={title} /> : title}
          label={showValidationError ? <ErrorLabel label={label} /> : label}
          disabled={disabled}
          Tooltip={TooltipWrapper}
          render={(close) => {
            return (
              <>
                <SearchSkillsByName />
                <SkillCategories
                  onSelect={(selectedSkill) => handleSelect(selectedSkill, close)}
                  size="md"
                  selectedSkill={skill}
                />
              </>
            )
          }}
        />
      </div>
    </SkillsProvider>
  )
}
