import { useCallback, useEffect, useState } from 'react'
import { getAutoAssignerTimeZones } from 'lib/api/auto-assigner-time-zones/auto-assigner-time-zones'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import { displayHoursAndMinutes } from 'lib/util/date'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  header: 'tw-flex tw-justify-between navbar-custom',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

function AutoAssignerTimeZones() {
  const [timeZones, setTimeZones] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { alert } = useToastContext()

  const fetchTimeZones = useCallback(async () => {
    setIsLoading(true)

    try {
      const { autoAssignerTimeZones } = await getAutoAssignerTimeZones()
      setTimeZones(autoAssignerTimeZones)
    } catch (error) {
      console.error('AA Time Zones could not be fetched.', error)
      alert('AA Time Zones could not be fetched.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchTimeZones()
  }, [])

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Time Zones</h4>
      </div>
      <div className={classNames.container}>
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <TableHeader />
            <tbody>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                timeZones.map(({ id, name, startTime, endTime, position }) => (
                  <tr key={id} className={classNames.tableRow}>
                    <td className={classNames.tableCell}>{name}</td>
                    <td className={classNames.tableCell}>{displayHoursAndMinutes(startTime)}</td>
                    <td className={classNames.tableCell}>{displayHoursAndMinutes(endTime)}</td>
                    <td className={classNames.tableCell}>{position}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const TableHeader = () => (
  <thead>
    <tr>
      <th className={classNames.tableHeadCell}>Name</th>
      <th className={classNames.tableHeadCell}>Start Time</th>
      <th className={classNames.tableHeadCell}>End Time</th>
      <th className={classNames.tableHeadCell}>Position</th>
    </tr>
  </thead>
)

export default function AutoAssignerTimeZonesPage() {
  return (
    <ToastProvider>
      <AutoAssignerTimeZones />
    </ToastProvider>
  )
}
