import Dropdown, { defaultClassNames } from 'lib/components/dropdown/dropdown'
import { cn } from 'lib/util/cn'

import { useCompanyTaggingContext } from 'providers/company-tagging-provider'
import CompanyTags from './company-tags'
import CompanyTaggingPopoverTitle from './company-tagging-popover-title'
import { Pencil } from 'lucide-react'

interface CompanyTaggingPopoverProps {
  type?: 'small' | 'large'
}

export default function CompanyTaggingPopover({ type = 'small' }: CompanyTaggingPopoverProps) {
  return type === 'small' ? <SmallCompanyTaggingPopover /> : <LargeCompanyTaggingPopover />
}

export function SmallCompanyTaggingPopover() {
  const { selectedTags } = useCompanyTaggingContext()

  return (
    <Dropdown
      icon="chevron"
      title={<CompanyTaggingPopoverTitle selectedTags={selectedTags} emptyTagTitleElement={<>Select tag(s)</>} />}
      label="Tags"
      openByDefault={false}
      size="xs"
      render={(close) => <CompanyTags closePopover={close} />}
    />
  )
}

function LargeCompanyTaggingPopover() {
  const { selectedTags } = useCompanyTaggingContext()

  const classNames = {
    ...defaultClassNames,
    popoverPanel: cn(defaultClassNames.popoverPanel, 'tw--mt-72 tw-left-full tw-ml-4'),
  }

  return (
    <Dropdown
      buttonRingOnOpen
      classNames={classNames}
      icon={selectedTags?.length > 0 ? <Pencil className="lu-md" /> : null}
      openByDefault={false}
      render={(close) => <CompanyTags closePopover={close} />}
      size="sm"
      title={
        <CompanyTaggingPopoverTitle
          selectedTags={selectedTags}
          emptyTagTitleElement={<div className="tw-text-xs tw-text-neutral-500 tw-p-0 tw-m-0">+ Add tag(s)</div>}
        />
      }
    />
  )
}
