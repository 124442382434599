import {
  ChevronRight,
  ClipboardList,
  Clock,
  Cog,
  createIcons,
  ExternalLink,
  File,
  FileBarChart2,
  HelpCircle,
  Home,
  Phone,
  Share2,
  Tags,
  Ticket,
  Trash2,
  UserRound,
  UsersRound,
  Wrench,
} from 'lucide'

export default function InitializeLucide() {
  createIcons({
    icons: {
      ChevronRight,
      ClipboardList,
      Clock,
      Cog,
      ExternalLink,
      File,
      FileBarChart2,
      HelpCircle,
      Home,
      Phone,
      Share2,
      Tags,
      Ticket,
      Trash2,
      UserRound,
      UsersRound,
      Wrench,
    },
  })
}
