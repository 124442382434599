import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'

import { CompanyTag, createCompanyTag, deleteCompanyTag, updateCompanyTag } from 'lib/api/company-tags/company-tags'
import { useToastContext } from 'providers/toast-provider'

import { CompanyTagging, TaggableType, updateCompanyTagging } from 'lib/api/company-tagging/company-tagging'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'

export enum ChangeTagAction {
  Delete = 'delete',
  Update = 'update',
  Select = 'select',
  Unselect = 'unselect',
}

export interface CompanyTaggingContextProps {
  children: ReactNode
  selectedTags: CompanyTag[]
  taggableId: number
  taggableType: TaggableType
  updateTaggableState: (tag: CompanyTag, action: ChangeTagAction) => void
}

export enum ViewStates {
  Search = 'Search',
  Edit = 'Edit',
  Delete = 'Delete',
}

export interface View {
  viewState: ViewStates
  tag?: CompanyTag
}

export type CompanyTaggingContextValue = {
  companyTags: CompanyTag[]
  createAndSelectCompanyTag: (name: string) => Promise<void>
  currentView: View
  deleteCompanyTag: (tag: CompanyTag) => Promise<void>
  searchResults: CompanyTag[]
  searchTerm: string
  selectCompanyTag: (tag: CompanyTag) => Promise<void>
  selectedTags: CompanyTag[]
  setCurrentView: Dispatch<SetStateAction<View>>
  setSearchTerm: Dispatch<SetStateAction<string>>
  unselectCompanyTag: (tag: CompanyTag) => Promise<void>
  updateCompanyTag: (tag: CompanyTag, name: string) => Promise<CompanyTag>
}

const CompanyTagsContext = createContext({})

export function useCompanyTaggingContext() {
  return useContext(CompanyTagsContext) as CompanyTaggingContextValue
}

export default function CompanyTaggingProvider({
  taggableType,
  taggableId,
  updateTaggableState,
  selectedTags,
  children,
}: CompanyTaggingContextProps) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<CompanyTag[]>([])
  const [currentView, setCurrentView] = useState<View>({ viewState: ViewStates.Search })
  const { companyTags, setCompanyTags } = useAllCompanyTagsContext()

  const { alert, notice } = useToastContext()

  async function selectCompanyTag(tag: CompanyTag): Promise<void> {
    try {
      const companyTagging: CompanyTagging = {
        taggableType: taggableType,
        taggableId: taggableId,
        companyTagId: tag.id,
      }
      const selectedCompanyTaggings = selectedTags.map((tag) => ({
        taggableType: taggableType,
        taggableId: taggableId,
        companyTagId: tag.id,
      }))
      await updateCompanyTagging([...selectedCompanyTaggings, companyTagging])
      updateTaggableState(tag, ChangeTagAction.Select)
    } catch (error) {
      console.error('Error selecting company tag', error)
      alert('Error selecting company tag')
    }
  }

  async function unselectCompanyTag(tag: CompanyTag): Promise<void> {
    try {
      const selectedCompanyTaggings = selectedTags
        .filter((selectedTag) => selectedTag.id !== tag.id)
        .map((tag) => ({
          taggableType: taggableType,
          taggableId: taggableId,
          companyTagId: tag.id,
        }))

      await updateCompanyTagging(
        selectedCompanyTaggings.length > 0 ? selectedCompanyTaggings : [{ taggableType, taggableId }]
      )

      updateTaggableState(tag, ChangeTagAction.Unselect)
    } catch (error) {
      console.error('Error unselecting company tag', error)
      alert('Error unselecting company tag')
    }
  }

  async function createAndSelectCompanyTag(name: string): Promise<void> {
    try {
      const companyTag = await createCompanyTag(name)
      await selectCompanyTag(companyTag)
      setSearchTerm('')
      setCompanyTags([...companyTags, companyTag])
      notice('Tag created successfully')
    } catch (error) {
      console.error('Error creating company tag', error)
      alert('Error creating company tag')
    }
  }

  async function saveCompanyTag(tag: CompanyTag, name: string): Promise<CompanyTag> {
    try {
      const updatedTag = await updateCompanyTag(tag.id, name)
      setCompanyTags(companyTags.map((t) => (t.id === updatedTag.id ? updatedTag : t)))
      updateTaggableState(updatedTag, ChangeTagAction.Update)
      notice('Tag updated successfully')
      setCurrentView({ viewState: ViewStates.Search })
      return updatedTag
    } catch (error) {
      console.error('Error updating tag', error)
      alert('Error updating tag')
      return tag
    }
  }

  async function destroyCompanyTag(tag: CompanyTag) {
    try {
      await deleteCompanyTag(tag.id)
      setCompanyTags(companyTags.filter((t) => t.id !== tag.id))
      updateTaggableState(tag, ChangeTagAction.Delete)
      notice('Tag deleted successfully')
      setCurrentView({ viewState: ViewStates.Search })
    } catch (error) {
      console.error('Error deleting tag', error)
      alert('Error deleting tag')
    }
  }

  useEffect(() => {
    setSearchResults(
      searchTerm
        ? companyTags.filter((tag) => tag.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : companyTags.slice(0, 5)
    )
  }, [searchTerm, companyTags])

  const contextValue: CompanyTaggingContextValue = {
    companyTags,
    createAndSelectCompanyTag,
    currentView,
    deleteCompanyTag: destroyCompanyTag,
    searchResults,
    searchTerm,
    selectCompanyTag,
    selectedTags,
    setCurrentView,
    setSearchTerm,
    unselectCompanyTag,
    updateCompanyTag: saveCompanyTag,
  }

  return <CompanyTagsContext.Provider value={contextValue}>{children}</CompanyTagsContext.Provider>
}
