import { Designer } from 'lib/api/tickets/tickets'
import Dropdown from 'lib/components/dropdown/dropdown'
import TicketDesigners from '../ticket-designers/ticket-designers'

interface RequestPreferredDesignerPopoverProps {
  onChange: (brand: Designer) => void
  possibleDesigners: Designer[]
  preferredDesigner: Designer
}

export default function RequestPreferredDesignerPopover({
  onChange,
  possibleDesigners,
  preferredDesigner,
}: RequestPreferredDesignerPopoverProps): JSX.Element {
  const title = preferredDesigner?.name || 'No Preference'

  return (
    <div className="tw-px-4">
      <Dropdown icon="chevron" label="Preferred Designer" title={title} size="sm">
        <TicketDesigners possibleDesigners={possibleDesigners} ticketDesigner={preferredDesigner} onSelect={onChange} />
      </Dropdown>
    </div>
  )
}
