import Button from 'components/core/button'
import Modal, { BaseModalProps } from 'lib/components/modals/modal'
import { AlertTriangle } from 'lucide-react'

export default function CustomBrandingUrlWarningModal({
  open,
  setOpen,
  callback,
  oldDomain,
  newDomain,
}: {
  open: BaseModalProps['open']
  setOpen: BaseModalProps['setOpen']
  callback: () => void
  oldDomain: string
  newDomain?: string
}) {
  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <div className="tw-flex tw-flex-col tw-gap-4">
        <h5>{newDomain ? 'Change' : 'Remove'} Your Custom Domain?</h5>
        <div className="tw-flex tw-gap-2 tw-p-4 tw-rounded tw-bg-squash-100 tw-text-squash-800 tw-items-start">
          <AlertTriangle className="lu-sm tw-flex-shrink-0 tw-mt-0.5" />
          <div>
            <strong>Any share & review links using your old domain will stop working</strong>
            <br />
            {newDomain
              ? `This will change your domain from '${oldDomain}' to '${newDomain}'`
              : `This will disconnect your domain: '${oldDomain}'`}
          </div>
        </div>
        <span>Be sure to send any existing collaborators your new link once your new domain has been connected.</span>
        <div className="tw-flex tw-justify-end tw-gap-2 tw-items-center">
          <Button
            color="neutralGray"
            onClick={() => {
              setOpen(false)
            }}
          >
            Go back
          </Button>
          <Button color="red" onClick={callback}>
            {newDomain ? 'Change' : 'Remove'} domain
          </Button>
        </div>
      </div>
    </Modal>
  )
}
