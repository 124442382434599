import Dropdown, { DropdownClassNames } from 'lib/components/dropdown/dropdown'
import { useEffect, useRef, useState } from 'react'
import { SelectableFormat } from './formats-filter'
import { SelectableSource } from './sources-filter'

const dropdownClassNames: DropdownClassNames = {
  chevron: `
    tw-w-5
    tw-h-4
  `,
  chevronContainer: `
    tw-mr-1
    tw-ml-2
    tw-p-2
    tw-rounded-full
    tw-self-end
  `,
  chevronHoverColor: ``,
  popover: `
    tw-relative
    tw-inline-block
    tw-text-left
  `,
  popoverButton: `
    tw-bg-white
    tw-text-gray-700
    tw-px-4
    tw-bg-white
    tw-text-lg
    tw-block
    tw-appearance-none
    tw-rounded
    tw-border
    tw-border-solid
    tw-shadow-none
    tw-w-48
  `,
  popoverItems: `
    tw-absolute
    tw-origin-top-left
    tw-left-0
    tw-mt-2
    tw-w-30
    tw-rounded-md
    tw-shadow-lg
    tw-ring-1
    tw-ring-black
    tw-ring-opacity-5
    tw-bg-white
    focus:tw-outline-none
  `,
  popoverPanel: `
    tw-absolute
    tw-z-10
    tw-block
    tw-overflow-y-auto
    tw-bg-white
    tw-p-8
    tw-rounded-md
  `,
  title: `
    tw-text-neutral-600 tw-text-base tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden
  `,
  titleContainer: `
    tw-text-neutral-800 tw-flex tw-items-center
  `,
  label: `
    tw-text-neutral-600 tw-font-sm tw-pb-1
  `,
}

interface FilterDropdownProps {
  onSelectFilter: (options: string[]) => void
  filterOptions: string[]
  selectedFilterOptions: string[]
  title: string
}

function FilterDropdownButton({
  children,
  size = 'sm',
  title,
}: {
  children: JSX.Element
  size?: 'md' | 'sm' | 'lg'
  title: string
}) {
  return (
    <div className="tw-px-4">
      <Dropdown icon="chevron" title={title} classNames={dropdownClassNames} size={size}>
        {children}
      </Dropdown>
    </div>
  )
}

function Content({ onSelectFilter, filterOptions, selectedFilterOptions, title }: FilterDropdownProps) {
  const value = useRef([])
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  function onFilterSelect(option: string) {
    if (selectedOptions.find((s) => s === option)) {
      const updatedValues = selectedOptions.filter((s) => s !== option)

      value.current = updatedValues
      setSelectedOptions(updatedValues)
    } else {
      const updatedValues = [...selectedOptions, option]

      value.current = updatedValues
      setSelectedOptions(updatedValues)
    }
  }

  useEffect(() => {
    if (selectedFilterOptions) {
      value.current = selectedFilterOptions
      setSelectedOptions(selectedFilterOptions)
    }
  }, [selectedFilterOptions])

  useEffect(() => {
    // Gets called when dropdown is dismissed
    return function () {
      if (value.current !== selectedFilterOptions) {
        onSelectFilter(value.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function isSelected(option: string) {
    return selectedOptions.includes(option)
  }

  function selectableOption(option: string) {
    if (title === 'Format(s)') {
      return <SelectableFormat format={option} onClick={onFilterSelect} isSelected={isSelected(option)} key={option} />
    } else if (title === 'Source(s)') {
      return <SelectableSource source={option} onClick={onFilterSelect} isSelected={isSelected(option)} key={option} />
    }
  }

  return (
    <>
      <h3>{title}</h3>
      <p>Select all that apply</p>
      <div className="tw-flex tw-gap-3 tw-flex-wrap">{filterOptions.map((option) => selectableOption(option))}</div>
    </>
  )
}

export const FilterDropdown = Object.assign(FilterDropdownButton, { Content })
