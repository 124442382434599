import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Popover } from '@headlessui/react'

import { cn } from 'lib/util/cn'
import { ChevronDown, ChevronUp } from 'lucide-react'

export interface DropdownClassNames {
  chevron: string
  chevronContainer: string
  chevronHoverColor: string
  popover: string
  popoverButton: string
  popoverItems: string
  popoverPanel: string
  titleContainer: string
  title: string
  label: string
}

interface DropdownIconProps {
  classNames?: DropdownClassNames
  hovering: boolean
  icon?: ReactElement | 'chevron'
  isUp: boolean
}

interface CustomSize {
  width?: string
  height?: string
  maxWidth?: string
  maxHeight?: string
  minWidth?: string
  minHeight?: string
}

const sizes = {
  xs: {
    maxHeight: '600px',
    width: '300px',
  },
  sm: {
    maxHeight: '600px',
    width: '412px',
  },
  md: {
    maxHeight: '600px',
    width: '760px',
  },
  lg: {
    maxHeight: '670px',
    width: '760px',
  },
  auto: {},
}

export type DropdownButton = (open: boolean) => JSX.Element

interface DropdownProps {
  buttonRingOnOpen?: boolean
  children?: ReactElement | string
  classNames?: DropdownClassNames
  customSize?: CustomSize
  disabled?: boolean
  icon?: ReactElement | 'chevron'
  label?: string | ReactElement
  openByDefault?: boolean
  render?: (close: () => void) => ReactElement
  size?: keyof typeof sizes
  title?: ReactNode
  button?: DropdownButton
  Tooltip?: ({ children }: { children: ReactNode }) => ReactElement
}

export const defaultClassNames = {
  chevron: `
    tw-w-5
    tw-h-4
  `,
  chevronContainer: `
    tw-relative
    tw--top-2
    tw-mr-1
    tw-ml-2
    tw-p-2
    tw-rounded-full
    tw-self-end
  `,
  chevronHoverColor: `
    tw-bg-neutral-100
  `,
  popover: `
    tw-relative
    tw-inline-block
    tw-text-left
  `,
  popoverButton: `
    tw-flex
    tw-flex-col
    tw-bg-white
    tw-text-gray-700
    tw-border-none
    tw-py-2
  `,
  popoverItems: `
    tw-absolute
    tw-origin-top-left
    tw-left-0
    tw-mt-2
    tw-w-30
    tw-rounded-md
    tw-shadow-lg
    tw-ring-1
    tw-ring-black
    tw-ring-opacity-5
    tw-bg-white
    focus:tw-outline-none
  `,
  popoverPanel: `
    tw-absolute
    tw-z-20
    tw-block
    tw-overflow-y-auto
    tw-bg-white
    tw-p-8
    tw-mt-2
    tw-rounded-md
  `,
  titleContainer: `
    tw-text-neutral-800
    tw-flex
    tw-align-center
    tw-gap-2
  `,
  title: `
    tw-font-semibold
  `,
  label: `
    tw-text-neutral-600 tw-font-sm tw-pb-1
  `,
}

function DropdownIcon({ icon, isUp = true, hovering = false, classNames = defaultClassNames }: DropdownIconProps) {
  function Container({ children }: { children: ReactElement }): ReactElement {
    return (
      <div className={`${classNames.chevronContainer} ${hovering ? classNames.chevronHoverColor : ''}`}>{children}</div>
    )
  }

  if (icon === 'chevron') {
    if (isUp) {
      return (
        <Container>
          <ChevronUp className={classNames.chevron} aria-hidden="true" />
        </Container>
      )
    }

    return (
      <Container>
        <ChevronDown className={classNames.chevron} aria-hidden="true" />
      </Container>
    )
  }

  if (icon) {
    return <div>{icon}</div>
  }

  return null
}

export default function Dropdown({
  classNames = defaultClassNames,
  title,
  label,
  render,
  size = 'md',
  customSize,
  children,
  Tooltip,
  openByDefault = false,
  disabled = false,
  buttonRingOnOpen = false,
  icon,
  button,
}: DropdownProps): ReactElement {
  const [hovering, setHovering] = useState(false)
  const popoverButtonRef = useRef<HTMLButtonElement>()

  const inlineSize = customSize ?? sizes[size]

  useEffect(() => {
    if (openByDefault) {
      popoverButtonRef.current.click()
    }
  }, [openByDefault, popoverButtonRef])

  const disabledButtonStyles = { cursor: 'default', opacity: 0.6 }

  function ButtonWrapper({ children, ...otherProps }) {
    if (disabled) {
      return (
        <button {...otherProps} style={disabledButtonStyles}>
          {children}
        </button>
      )
    }

    return (
      <Popover.Button {...otherProps} ref={popoverButtonRef}>
        {children}
      </Popover.Button>
    )
  }

  function popoverButton(open: boolean) {
    return (
      <ButtonWrapper
        className={cn(classNames.popoverButton, {
          'tw-ring-1 tw-ring-cornflower-500 tw-rounded': open && buttonRingOnOpen,
        })}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {label && <div className={classNames.label}>{label}</div>}
        <div className={classNames.titleContainer}>
          {title && <div className={classNames.title}>{title}</div>}
          {icon && <DropdownIcon isUp={open} hovering={hovering && !disabled} classNames={classNames} icon={icon} />}
        </div>
      </ButtonWrapper>
    )
  }

  return (
    <Popover as="div" className={classNames.popover}>
      {({ close, open }) => (
        <>
          {button ? (
            <ButtonWrapper className={classNames.popoverButton}>{button(open)}</ButtonWrapper>
          ) : Tooltip ? (
            <Tooltip>{popoverButton(open)}</Tooltip>
          ) : (
            <>{popoverButton(open)}</>
          )}
          <Popover.Panel
            className={classNames.popoverPanel}
            style={{ ...inlineSize, boxShadow: '0 10px 50px -12px' }}
            data-testid="popover-panel"
          >
            {render && render(close)}
            {children && children}
          </Popover.Panel>
          <Popover.Overlay />
        </>
      )}
    </Popover>
  )
}
