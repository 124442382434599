import { AppSettings } from 'interfaces/app'
import { bindJWT, getApi } from 'lib/api/api'
import ConfirmationModalProvider from 'providers/confirmation-modal-provider'
import QueryClientProvider from 'providers/query-client-provider'
import ToastProvider from 'providers/toast-provider'
import { Search } from './search'

export interface SearchPageProps {
  settings: AppSettings
}

export default function SearchPage() {
  return (
    <QueryClientProvider>
      <ToastProvider>
        <ConfirmationModalProvider>
          <Search />
        </ConfirmationModalProvider>
      </ToastProvider>
    </QueryClientProvider>
  )
}

// Testing
export async function getSearch() {
  const getWithJWT = bindJWT(getApi, null)
  const response = await getWithJWT(`/api/internal/search?query=test`)
  return response.data
}
