import { GenAiRequest } from 'lib/api/gen-ai/gen-ai-requests'
import { Annotation } from 'lib/api/annotations/annotations'

export default interface DetailTask {
  id: number
  annotation?: Annotation | null
  createdAt: string
  description: string
  genAiRequest?: GenAiRequest
  private: boolean
  status: string
  taskId: number
  taskType: DetailTaskType
  ticketVersion: number
}

export enum DetailTaskType {
  COPY = 'copy',
  GEN_AI_REQUEST = 'gen_ai_request',
  IMAGE_ANNOTATION = 'annotation',
  TASK = 'direction',
  TXT2IMG = 'txt2img',
  VIDEO_ANNOTATION = 'video_annotation',
}
