import { bindJWT, getApi, patchApi, postApi } from 'lib/api/api'
import { snakeCaseKeys } from 'lib/object/utils'

export interface CannedResponse {
  id: number
  body: string
  subject: string
  cannedResponseFolderId: number
}
export interface CannedResponseFolder {
  id: number
  name: string
  cannedResponses: CannedResponse[]
}

export async function getCannedResponses(jwt: string = null): Promise<CannedResponseFolder[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const url = `/api/internal/admin/canned_responses`

  const response = await getWithJWT(url)
  return response.data.data
}

interface CreateCannedResponseParams {
  body: string
  subject: string
  cannedResponseFolderId: number
}

interface UpdateCannedResponseParams extends CreateCannedResponseParams {
  id: number
}

export async function updateCannedResponse(
  params: UpdateCannedResponseParams,
  jwt: string = null
): Promise<CannedResponse> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const url = `/api/internal/admin/canned_responses/${params.id}`

  const snakeCaseParams = snakeCaseKeys(params)
  const response = await patchWithJWT(url, {
    canned_response: { ...snakeCaseParams },
  })

  return response.data.data
}

export async function createCannedResponse(
  params: CreateCannedResponseParams,
  jwt: string = null
): Promise<CannedResponse> {
  const postWithJWT = bindJWT(postApi, jwt)
  const url = `/api/internal/admin/canned_responses`

  const snakeCaseParams = snakeCaseKeys(params)
  const response = await postWithJWT(url, {
    canned_response: { ...snakeCaseParams },
  })

  return response.data.data
}
