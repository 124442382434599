import { ReactElement, useMemo } from 'react'
import { Clock } from 'lucide-react'
import { TicketProductionStateBadge } from 'lib/components/badge/badge'
import AdminTicketListProvider, { useAdminTicketListContext } from './admin-ticket-list-provider'
import { routes } from 'lib/api/admin/tickets/admin-tickets'
import { AdminTicketListItem } from 'lib/api/admin/tickets/admin-tickets'
import { asDay, asDaysAgo, asTimeDuration } from 'lib/util/date'

interface CompanyLinkProps {
  companyId: number
  companyName: string
}

interface TicketTableProps {
  showDesignTime?: boolean
  tickets: AdminTicketListItem[]
}

interface CustomerLinkProps {
  userId: number
  userName: string
}

const classNames = {
  table: 'tw-w-full tw-table-auto tw-rounded tw-bg-white tw-overflow-hidden tw-shadow-sm tw-mb-12 tw-text-neutral-800',
  cells: {
    default: 'tw-py-4 tw-px-2 tw-w-16 lg:tw-px-6 xl:tw-w-28',
    flexible: 'tw-py-4 tw-px-2 lg:tw-px-6',
    large: 'tw-py-4 tw-px-2 tw-w-24 lg:tw-px-6 xl:tw-w-44',
    medium: 'tw-py-4 tw-px-2 tw-w-24 lg:tw-px-6 xl:tw-w-36',
    optional: 'tw-hidden xl:tw-table-cell',
    small: 'tw-py-4 tw-px-2 tw-w-12 lg:tw-px-6',
  },
  rows: {
    body: 'tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 last:tw-border-b-0 tw-bg-white tw-transition-shadow',
    header: 'tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-font-semibold',
  },
}

export default function AdminTicketList(): ReactElement {
  return (
    <AdminTicketListProvider>
      <Content />
    </AdminTicketListProvider>
  )
}

function CompanyLink({ companyId, companyName }: CompanyLinkProps): ReactElement {
  return (
    <div>
      (
      <a
        href="#"
        onClick={(e) => e.preventDefault()}
        data-class="admin_company_details_link"
        data-title="Company Details"
        data-toggle="ajax-modal"
        data-url={`/admin/companies/${companyId}`}
      >
        {companyName}
      </a>
      )
    </div>
  )
}

function Content(): ReactElement {
  const { ticketsInQueue, ticketsInReview, ticketsInQueueLength, ticketsInReviewLength } = useAdminTicketListContext()
  return (
    <div>
      <QueueHeader />
      {ticketsInQueueLength > 0 && <TicketTable tickets={ticketsInQueue} showDesignTime />}
      {ticketsInQueueLength === 0 && <p>{`You don't have any tickets assigned today.`}</p>}
      <ReviewHeader />
      {ticketsInReviewLength > 0 && <TicketTable tickets={ticketsInReview} />}
      {ticketsInReviewLength === 0 && <p>{`You don't have any tickets in quality review.`}</p>}
    </div>
  )
}

function CustomerLink({ userId, userName }: CustomerLinkProps): ReactElement {
  return (
    <div>
      <a
        href="#"
        onClick={(e) => e.preventDefault()}
        data-class="user-edit-modal"
        data-title={userName}
        data-toggle="ajax-modal"
        data-url={`/admin/users/${userId}`}
      >
        {userName}
      </a>
    </div>
  )
}

function DayAndTime({ at }: { at: string }): ReactElement {
  const days = useMemo(() => at && asDay(at), [at])
  const date = useMemo(() => at && new Date(at).toLocaleString(), [at])
  const time = useMemo(() => at && new Date(at).toLocaleTimeString(), [at])

  if (!at) {
    return null
  }

  return (
    <div className="tw-px-2 tw-text-sm tw-cursor-default" title={date}>
      {days}
      <br />
      {time}
    </div>
  )
}

function DaysAgo({ at }: { at: string }): ReactElement {
  const days = useMemo(() => at && asDaysAgo(at), [at])
  const date = useMemo(() => at && new Date(at).toLocaleString(), [at])

  if (!at) {
    return null
  }

  return (
    <div className="tw-px-2 tw-text-sm tw-text-gray-400 tw-cursor-default" title={date}>
      {days}
    </div>
  )
}

function QueueHeader(): ReactElement {
  const { ticketsInQueueLength, cumulativeDesignTime } = useAdminTicketListContext()
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-4">
      <h1 className="tw-text-2xl">Assigned To Me {ticketsInQueueLength ? ` (${ticketsInQueueLength})` : ''}</h1>
      {ticketsInQueueLength > 0 && (
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-font-semibold">
          <Clock className="lu-sm" />
          <span>{`Today's workload: ${asTimeDuration(cumulativeDesignTime)}`}</span>
        </div>
      )}
    </div>
  )
}

function ReviewHeader(): ReactElement {
  const { ticketsInReviewLength } = useAdminTicketListContext()
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-4">
      <h1 className="tw-text-2xl">
        My Tickets in Quality Review {ticketsInReviewLength ? ` (${ticketsInReviewLength})` : ''}
      </h1>
    </div>
  )
}

function TicketTable({ showDesignTime = false, tickets }: TicketTableProps): ReactElement {
  return (
    <div className="tw-pb-2">
      <table className={classNames.table}>
        <thead>
          <tr className={classNames.rows.header}>
            <th className={`${classNames.cells.small} ${classNames.cells.optional}`}>#</th>
            <th className={classNames.cells.flexible}>Request Name</th>
            <th className={classNames.cells.small}>Customer Queue Position</th>
            <th className={classNames.cells.default}>Status</th>
            <th className={`${classNames.cells.medium} ${classNames.cells.optional}`}>Submitted By</th>
            <th className={classNames.cells.medium}>{showDesignTime && 'Design Time'}</th>
            <th className={`${classNames.cells.large} ${classNames.cells.optional}`}>Request Type</th>
            <th className={`${classNames.cells.medium} ${classNames.cells.optional}`}>Submitted At</th>
            <th className={`${classNames.cells.medium} ${classNames.cells.optional}`}>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket, index) => (
            <tr className={classNames.rows.body} key={ticket.id}>
              <td className={`${classNames.cells.small} ${classNames.cells.optional}`}>{index + 1}</td>
              <td className={classNames.cells.flexible}>
                <a href={`${routes.show}/${ticket.id}`}>
                  #{ticket.id}
                  <br />
                  {ticket.subject}
                </a>
              </td>
              <td className={classNames.cells.small}>{ticket?.position}</td>
              <td className={classNames.cells.default}>
                <TicketProductionStateBadge state={ticket.state} />
                <DaysAgo at={ticket.stateChangedAt} />
              </td>
              <td className={`${classNames.cells.medium} ${classNames.cells.optional}`}>
                <CustomerLink userId={ticket.submittedBy.id} userName={ticket.submittedBy.fullName} />
                <CompanyLink companyId={ticket.company.id} companyName={ticket.company.name} />
              </td>
              <td className={classNames.cells.medium}>
                {showDesignTime && (
                  <div className="tw-text-right tw-display-block tw-mx-auto tw-w-12">
                    {asTimeDuration(ticket.designTime)}
                  </div>
                )}
              </td>
              <td className={`${classNames.cells.large} ${classNames.cells.optional}`}>{ticket.requestType}</td>

              <td className={`${classNames.cells.medium} ${classNames.cells.optional}`}>
                <DayAndTime at={ticket.submittedAt} />
              </td>
              <td className={`${classNames.cells.medium} ${classNames.cells.optional}`}>
                <DayAndTime at={ticket.updatedAt} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
