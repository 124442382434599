import { useState, useMemo } from 'react'
import { LinkLikeButton } from 'components/core/button'
import { Pencil } from 'lucide-react'
import { HTMLAttributes } from 'react'
import TextInput from 'lib/components/text-input/text-input'
import { useRequestContext } from '../../providers/request-provider'

export default function CustomizeLink({
  className,
  shareUrl,
  currentSegments,
  onUpdateSegments,
  isCustomDomainEnabled,
}: {
  className?: HTMLAttributes<HTMLDivElement>['className']
  shareUrl: string
  currentSegments: string[]
  onUpdateSegments: (newSegments: string[]) => void
  isCustomDomainEnabled: boolean
}) {
  const { ticket } = useRequestContext()
  const [isEditing, setIsEditing] = useState(false)
  const [localSegments, setLocalSegments] = useState(currentSegments)
  const hasWhiteLabelSubscription = !!ticket?.meta?.sharing?.hasWhiteLabelSubscription

  const toggleEdit = () => setIsEditing(!isEditing)

  const handleInputChange = (index: number, value: string) => {
    const alphaNumericHyphenUnderscoreRegex = /[^a-zA-Z0-9-_]/g
    const validatedValue = value.replace(alphaNumericHyphenUnderscoreRegex, '')
    const newSegments = [...localSegments]
    newSegments[index] = validatedValue
    setLocalSegments(newSegments)
  }

  const discardChanges = () => {
    setLocalSegments(currentSegments)
    setIsEditing(false)
  }

  const saveChanges = () => {
    onUpdateSegments(localSegments)
    setIsEditing(false)
  }

  const baseUrl = shareUrl.split('/share/')[0] + '/share/'
  const shareId = shareUrl.split('/').pop()

  const previewUrl = useMemo(() => {
    const segments = localSegments.filter(Boolean)
    return `${baseUrl}${segments.length > 0 ? segments.join('/') + '/' : ''}${shareId}`
  }, [baseUrl, localSegments, shareId])

  return (
    <div className={`tw-flex tw-flex-col tw-gap-2 ${className}`}>
      <div className="tw-bg-gray-100 tw-p-4 tw-flex tw-flex-col tw-items-stretch tw-gap-4 tw-rounded-md">
        {isEditing ? (
          <>
            <div className="tw-flex tw-items-center tw-gap-1 tw-text-sm tw-overflow-x-auto tw-whitespace-nowrap">
              <span>https://.../share/</span>
              <TextInput
                type="text"
                name="slug_segment_1"
                placeholder="Enter slug (Optional)"
                value={localSegments[0] || ''}
                onChange={(e) => handleInputChange(0, e.target.value)}
                className="tw-inline-block tw-w-40 tw-min-w-0 tw-my-0"
              />
              <span>/</span>
              <TextInput
                type="text"
                name="slug_segment_2"
                placeholder="Enter slug (Optional)"
                value={localSegments[1] || ''}
                onChange={(e) => handleInputChange(1, e.target.value)}
                className="tw-inline-block tw-w-40 tw-min-w-0  tw-my-0"
              />
              <span>/..</span>
            </div>
            <div className="tw-text-sm tw-text-center">{previewUrl}</div>
          </>
        ) : (
          <div className="tw-text-center">{shareUrl}</div>
        )}
        {hasWhiteLabelSubscription ? (
          <>
            {isEditing ? (
              <div className="tw-flex tw-justify-center tw-gap-4">
                <LinkLikeButton onClick={discardChanges} color="destructive">
                  Discard changes
                </LinkLikeButton>
                <LinkLikeButton onClick={saveChanges} color="primary">
                  Save changes
                </LinkLikeButton>
              </div>
            ) : (
              <LinkLikeButton color="primary" onClick={toggleEdit}>
                <Pencil className="tw-w-4 tw-mr-1 tw--mt-1" />
                Customize Link
              </LinkLikeButton>
            )}
          </>
        ) : (
          <div className="tw-text-center tw-text-peppercorn-900">
            Want to use your own domain?{' '}
            <a href="/companies/white_label" target="_blank">
              Learn more about white labelling
            </a>
          </div>
        )}
      </div>
      {hasWhiteLabelSubscription && (
        <div className="tw-flex tw-gap-2 tw-items-center">
          <div>Custom Domain:</div>
          {isCustomDomainEnabled ? (
            <span className="tw-font-bold tw-text-gherkin-500">On</span>
          ) : (
            <span className="tw-font-bold">Off</span>
          )}
        </div>
      )}
    </div>
  )
}
