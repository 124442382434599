import { Popover } from '@headlessui/react'
import { AdminTicketFile } from 'lib/api/admin/ticket-files/admin-ticket-files'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { DimensionUnit } from 'lib/util/dimension-unit'
import { displayFilesize } from 'lib/util/file/file'
import { ChevronDown, File, Palette, Ruler, ScanEye } from 'lucide-react'
import { ReactElement, useState } from 'react'

interface FileMetadataProps {
  selectedFile: AdminTicketFile | TicketFile
}

const classNames = {
  container: 'tw-flex tw-items-center tw-gap-2 tw-w-full',
  item: 'tw-flex tw-items-center tw-justify-center tw-gap-2',
  dimension: {
    button: 'tw-bg-transparent tw-border-none tw-text-blue-800 tw-flex tw-items-center tw-cursor-pointer',
    panel: 'tw-absolute tw-z-30 tw-bg-white tw-p-2 tw-rounded-md tw-text-black',
  },
}

function FileMetadata({ selectedFile }: FileMetadataProps): ReactElement {
  const Dimensions = () => {
    const [unit, setUnit] = useState<DimensionUnit>(DimensionUnit.pixels)
    const height =
      unit === DimensionUnit.inches ? Number(selectedFile.height / selectedFile.dpi).toFixed(2) : selectedFile.height
    const width =
      unit === DimensionUnit.inches ? Number(selectedFile.width / selectedFile.dpi).toFixed(2) : selectedFile.width

    return (
      <>
        {width} x {height}
        {selectedFile?.dpi ? (
          <Popover>
            <Popover.Button className={classNames.dimension.button}>
              {unit}
              <ChevronDown className="lu-light lu-md" />
            </Popover.Button>
            <Popover.Panel className={classNames.dimension.panel}>
              {Object.entries(DimensionUnit).map(([key, value]) => (
                <div key={key} className="hover:tw-bg-gray-200 tw-p-2 tw-cursor-pointer" onClick={() => setUnit(value)}>
                  {key}
                </div>
              ))}
            </Popover.Panel>
          </Popover>
        ) : (
          'px'
        )}
      </>
    )
  }

  return (
    <div className={classNames.container}>
      {selectedFile?.colorspace && (
        <div className={classNames.item}>
          <Palette className="lu-light lu-md" />
          {selectedFile.colorspace}
        </div>
      )}
      {selectedFile?.dpi && (
        <div className={classNames.item}>
          <ScanEye className="lu-light lu-md" />
          {selectedFile.dpi} DPI
        </div>
      )}
      {selectedFile.fileSize && (
        <div className={classNames.item}>
          <File className="lu-light lu-md" />
          {displayFilesize(selectedFile.fileSize)}
        </div>
      )}
      {selectedFile?.width && selectedFile?.height && (
        <div className={classNames.item}>
          <Ruler className="lu-light lu-md" />
          {selectedFile?.dpi ? <Dimensions /> : `${selectedFile.width} x ${selectedFile.height} px`}
        </div>
      )}
    </div>
  )
}

export default FileMetadata
