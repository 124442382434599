import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'

import { Annotation, AnnotationRecord } from 'lib/api/annotations/annotations'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import IconButton from 'lib/components/buttons/icon-button'
import Textarea from 'lib/components/textarea/textarea'
import { useToastContext } from 'providers/toast-provider'

import { useAnnotationsContext } from '../providers/annotations-provider'
import { DetailTaskType } from '../types/detail-task'

interface TimelineAnnotationEditProps {
  annotation: Annotation
  disableEditMode: () => void
}

export default function TimelineAnnotationEdit({
  annotation,
  disableEditMode,
}: TimelineAnnotationEditProps): ReactElement {
  const [value, setValue] = useState(annotation.body || '')
  const textareaRef = useRef(null)
  const { highlightedAnnotation, saveAnnotation, selectAnnotation, setHighlightedAnnotation, updateVideoAnnotation } =
    useAnnotationsContext()
  const { alert } = useToastContext()

  function hasMinimumLength() {
    return value.trim().length >= 3
  }

  async function save() {
    if (value.trim() === annotation.body) {
      disableEditMode()
    } else if (hasMinimumLength()) {
      try {
        if (annotation.type === DetailTaskType.VIDEO_ANNOTATION) {
          if ('comments' in annotation.data) {
            annotation.data.comments[0].body = value
          }
          updateVideoAnnotation(annotation.id, annotation.uuid, value)
        } else {
          await saveAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>, value)
        }
        disableEditMode()
      } catch (error) {
        console.error('TimelineAnnotationEdit Error:', error)
        alert('There was an error updating the annotation. Refresh and try again.')
      }
    }
  }

  function onChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setValue(e.target.value)
  }

  useEffect(() => {
    textareaRef.current.focus()
    textareaRef.current.scrollTop = textareaRef.current.scrollHeight
    textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length)
    selectAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    setHighlightedAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleClick(e: MouseEvent) {
    e.stopPropagation()

    if (highlightedAnnotation?.uuid !== annotation?.uuid && highlightedAnnotation?.id !== annotation?.id) {
      setHighlightedAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
      selectAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    }
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-2 tw-w-full">
      <Textarea
        value={value}
        onChange={onChange}
        placeholder="Edit Annotation here"
        error={!hasMinimumLength()}
        onClick={handleClick}
        ref={textareaRef}
      />
      <div style={{ minWidth: '40px' }}>
        <IconButton color="secondary" icon={['far', 'check']} onClick={save} />
      </div>
      <span onMouseDown={(e) => e.preventDefault()}>
        <IconButton color="secondary" icon={['far', 'times']} onClick={disableEditMode} />
      </span>
    </div>
  )
}
