import { StylesConfig } from 'react-select'

type OptionType = {
  label: string
  value: string
}
type isMulti = false

export const selectStyles: StylesConfig<OptionType, isMulti> = {
  // @ts-ignore
  menuList: (provided) => ({
    ...provided,
    background: '#ffffff',
  }),
  // @ts-ignore
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
  // @ts-ignore
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#00BC8F' : '#ffffff',
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isSelected ? '#ffffff' : '#000000',
    cursor: state.isDisabled ? 'default' : 'pointer',
    '&:hover': {
      background: state.isDisabled ? '#ffffff' : '#00BC8F',
      color: state.isDisabled ? '#000000' : '#ffffff',
    },
  }),
  // @ts-ignore
  control: (styles, state) => ({
    ...styles,
    background: '#ffffff',
    cursor: 'pointer',
    // @ts-ignore
    borderColor: state.isSelected ? '#00BC8F' : '#dee2e6',
    boxShadow: state.isFocused ? '#00BC8F' : '#00BC8F',
    '&:hover': {
      borderColor: state.isFocused ? '#00BC8F' : '#00BC8F',
    },
  }),
}
