import { TicketFile, VideoJSAnnotation } from 'lib/api/ticket-files/ticket-files'
import { AnnotationRecord } from 'lib/api/annotations/annotations'
import { DetailTaskType } from '../types/detail-task'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'

export function getCurrentlyViewedFile(selectedFile: TicketFile, extractedPreviewIndex: number): TicketFile {
  if (selectedFile?.isExtractable) {
    return selectedFile?.extractedPages[extractedPreviewIndex]
  }
  return selectedFile
}

export function getPageNumber(selectedFile: TicketFile, annotation: AnnotationRecord<AnnotoriousAnnotation>) {
  return selectedFile.extractedPages.findIndex((page) => page.id === annotation.assetId) + 1
}

export function determineAnnotationType(selectedFile: TicketFile) {
  if (selectedFile?.isVideo) {
    return DetailTaskType.VIDEO_ANNOTATION
  } else if (selectedFile?.hasImagePreview || selectedFile?.isExtractable) {
    return DetailTaskType.IMAGE_ANNOTATION
  } else {
    return null
  }
}

/**
 * @deprecated
 */
export function getAnnotations(ticketFile: TicketFile) {
  const annotationType = determineAnnotationType(ticketFile)

  if (annotationType === DetailTaskType.IMAGE_ANNOTATION) {
    if (ticketFile.isExtractable) {
      return ticketFile?.extractedPages?.flatMap((page) => page.annotations)
    }

    return ticketFile.annotations
  }

  if (annotationType === DetailTaskType.VIDEO_ANNOTATION) {
    const annotationRecords = ticketFile.annotations as AnnotationRecord<VideoJSAnnotation>[]
    return getVideoAnnotations(annotationRecords)
  }

  return []
}

/**
 * @deprecated
 */
function getVideoAnnotations(annotations: AnnotationRecord<VideoJSAnnotation>[]) {
  return annotations.reduce((acc, annotation) => {
    annotation.data.comments.forEach((comment) => {
      acc.push({
        body: comment.body,
        createdAt: annotation.createdAt,
        createdBy: annotation.createdBy,
        detailTaskId: annotation.detailTaskId,
        data: annotation.data,
        id: annotation.id,
        status: annotation.status,
        time: annotation.data.range.start,
        type: DetailTaskType.VIDEO_ANNOTATION,
        uuid: comment.id,
      })
    })
    return acc.sort((a, b) => a.time - b.time) // Sort by time
  }, [])
}
