import { SkillCategory } from 'interfaces/skill-category'
import { baseRequest, BaseResponse, getApi, Metadata, postApi, putApi } from 'lib/api/api'

export const routes = {
  edit: (id: number) => `/admin/skill_categories/${id}/edit`,
  index: '/admin/skill_categories',
  new: '/admin/skill_categories/new',
}

export interface SkillCategoriesResponse extends BaseResponse {
  skillCategories: SkillCategory[]
  meta: Metadata
}

export async function getSkillCategories(params): Promise<SkillCategoriesResponse> {
  const response = await baseRequest('/api/internal/admin/skill_categories', getApi, params)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    skillCategories: data as unknown as SkillCategory[],
    meta: meta as Metadata,
  }
}

export async function updateSkillCategory(id: number, params): Promise<BaseResponse> {
  return await baseRequest(`/api/internal/admin/skill_categories/${id}`, putApi, params)
}

export async function createSkillCategory(params): Promise<BaseResponse> {
  return await baseRequest('/api/internal/admin/skill_categories', postApi, params)
}
