import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'lib/components/modals/modal'
import Button from 'components/core/button'
import { LucideLoaderCircle } from 'lucide-react'
import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { useToastContext } from 'providers/toast-provider'
import {
  Collaborator,
  DesignRequest,
  getCollaborators,
  inviteCollaborators,
  shareTicket,
  updateShareLink,
} from 'lib/api/tickets/tickets'
import CopyButton from './share-modal-copy-button'
import ShareToggle from './share-modal-toggle'
import CustomizeLink from './share-modal-customize-link'
import CollaboratorsList from './share-modal-collaborators-list'
import InviteCollaboratorsInput from './share-modal-invite-input'

interface ShareModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const generateShareUrl = (origin: string, segments: string[], shareId: string) => {
  const filteredSegments = segments.filter(Boolean)
  const segmentPath = filteredSegments.length > 0 ? `/${filteredSegments.join('/')}` : ''
  return `${origin}/share${segmentPath}/${shareId ?? ''}`
}

const useShareLink = (ticket: DesignRequest) => {
  const { setTicket } = useRequestContext()
  const { notice, alert } = useToastContext()
  const [isShared, setIsShared] = useState((ticket.meta.sharing.id && ticket.meta.sharing.available) || false)
  const [segments, setSegments] = useState(ticket.meta.sharing?.segments ?? [])
  const [isCustomDomainEnabled, setIsCustomDomainEnabled] = useState(ticket.meta.sharing.customDomain != null)

  const toggleShare = useCallback(async () => {
    try {
      setIsShared(!isShared)
      const response = await shareTicket(ticket.id, !isShared)
      setTicket((prevTicket) => {
        // Type guard to check if the response has the expected structure
        if ('ticket' in response && 'meta' in response.ticket && 'sharing' in response.ticket.meta) {
          return {
            ...prevTicket,
            meta: {
              ...prevTicket.meta,
              sharing: {
                ...prevTicket.meta.sharing,
                ...response.ticket.meta.sharing,
              },
            },
          } as DesignRequest
        } else {
          console.warn('Unexpected response structure from shareTicket')
          return prevTicket
        }
      })
      notice(isShared ? 'Sharing disabled' : 'Sharing enabled')
    } catch (error) {
      setIsShared(isShared)
      alert('Failed to toggle sharing')
      console.error('Failed to toggle sharing ', error)
    }
  }, [isShared, ticket.id, setTicket, notice, alert])

  const updateSegments = useCallback(
    async (newSegments: string[]) => {
      const oldSegments = segments
      try {
        setSegments(newSegments)
        const response = await updateShareLink(String(ticket.id), ticket.meta.sharing.id, {
          share: { segments: newSegments },
        })
        setTicket(
          (prevTicket) =>
            ({
              ...prevTicket,
              meta: {
                ...prevTicket.meta,
                sharing: {
                  ...prevTicket.meta.sharing,
                  ...(response.ticket?.meta?.sharing ?? response.meta?.sharing ?? {}),
                },
              },
            } as DesignRequest)
        )
        notice('Custom link updated')
      } catch (error) {
        setSegments(oldSegments)
        alert('Failed to update custom link')
        console.error('Failed to update custom link ', error)
      }
    },
    [ticket.id, ticket.meta.sharing.id, setTicket, notice, alert, segments]
  )

  return { isShared, segments, isCustomDomainEnabled, toggleShare, updateSegments, setIsCustomDomainEnabled }
}

export default function ShareModal({ open, setOpen }: ShareModalProps) {
  const { ticket } = useRequestContext()
  const { isShared, segments, isCustomDomainEnabled, toggleShare, updateSegments } = useShareLink(ticket)
  const { notice, alert } = useToastContext()
  const [collaborators, setCollaborators] = useState<Collaborator[]>([])
  const [invitedCollaborators, setInvitedCollaborators] = useState<Collaborator['email'][]>([])
  const [pendingInvitedCollaborators, setPendingInvitedCollaborators] = useState<Collaborator['email'][]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)

  const shareUrl = generateShareUrl(
    ticket.meta.sharing?.customDomain?.domain ?? window.location.origin,
    segments ?? [''],
    ticket.meta.sharing.id
  )

  function setCollaboratorState(collaborators: Collaborator[]) {
    setCollaborators(collaborators.filter((c) => c.id != null))
    setInvitedCollaborators(collaborators.filter((c) => c.id == null).map((c) => c.email))
  }

  useEffect(() => {
    const fetchCollaborators = async () => {
      setIsLoading(true)
      try {
        const data = await getCollaborators(String(ticket.id))
        setCollaboratorState(data.data)
      } catch (error) {
        alert('Failed to load collaborators, please refresh the page.')
        console.error('Failed to load collaborators, please refresh the page. ', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchCollaborators()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleSend() {
    setIsSending(true)
    try {
      const data = await inviteCollaborators(String(ticket.id), pendingInvitedCollaborators)
      setCollaboratorState(data.data)
      setPendingInvitedCollaborators([])
      notice('Invites sent!')
    } catch (error) {
      alert('Failed to load collaborators, please refresh the page.')
      console.error('Failed to load collaborators, please refresh the page. ', error)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <div className="tw-relative tw-overflow-hidden">
        {isLoading && (
          <div
            className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-white tw-z-10"
            data-testid="share-modal-loader"
          >
            <LucideLoaderCircle className="tw-animate-spin tw-w-10 tw-h-10 tw-opacity-50" />
          </div>
        )}
        <span className="flash_message_holder tw-mx-20" />
        <Modal.Header as="div">
          <h4 className="tw-mb-10">Invite Collaborators</h4>
        </Modal.Header>
        <Modal.Body setOpen={setOpen} closeButton>
          <ShareToggle isShared={isShared} onToggle={toggleShare} />
          <div className={`tw-mt-4${!isShared ? 'tw-opacity-30 tw-pointer-events-none tw-filter tw-saturate-0' : ''}`}>
            <InviteCollaboratorsInput
              allCollaborators={[...collaborators.map((c) => c.email), ...invitedCollaborators]}
              collaborators={pendingInvitedCollaborators}
              setCollaborators={setPendingInvitedCollaborators}
              className="tw-my-6"
            />
            <CollaboratorsList
              className="tw-mt-4"
              collaborators={collaborators}
              invitedCollaborators={invitedCollaborators}
            />
            <div className="tw-border-0 tw-border-b tw-border-solid tw-my-6" />
          </div>
          <CustomizeLink
            className="tw-mt-4"
            shareUrl={shareUrl}
            currentSegments={segments}
            onUpdateSegments={updateSegments}
            isCustomDomainEnabled={isCustomDomainEnabled}
          />
          <div className={`${!isShared ? 'tw-opacity-30 tw-pointer-events-none tw-filter tw-saturate-0' : ''}`}>
            <div className="tw-flex tw-justify-between tw-items-center tw-mt-6">
              <CopyButton isShared={isShared} shareUrl={shareUrl} />
              <Button
                color="purple"
                type="button"
                onClick={handleSend}
                disabled={pendingInvitedCollaborators.length === 0 || isSending}
                className="tw-flex tw-items-center tw-gap-2"
              >
                {isSending && <LucideLoaderCircle className="tw-animate-spin" />}
                Send
              </Button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}
