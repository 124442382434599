import { SearchTicket } from 'interfaces/ticket'
import { TicketData } from './tickets/tickets'

interface Endpoint {
  path: string
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'BLOB'
  baseUrl?: string
}

export const endpoints: Record<string, Endpoint> = {
  getSearch: { path: '/search', method: 'GET' },
  getTickets: { path: '/tickets', method: 'GET' },
}

interface Endpoints {
  getSearch: {
    data: SearchTicket[]
    meta: {
      nextPage: string
      pageSize: number
      previousPage: string
      total: number
    }
  }
  getTickets: TicketData
}

interface Queries {
  getSearch: {
    query?: string
    page?: string
    sort?: string
    page_size?: number
    brand_id?: number
  }
  getTickets: {
    first_render?: string
    page: number
    sort_column?: string
    user_id?: number
  }
}

export const request = async <T extends keyof Endpoints>(
  endpoint: keyof typeof endpoints & T,
  query?: Queries[T],
  requestOptions?: RequestInit
): Promise<Endpoints[T]> => {
  const { path, method, baseUrl } = endpoints[endpoint]

  const url = getUrl(`${baseUrl || ''}/api/internal${path}`, query)

  const req = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...requestOptions?.headers,
    },
    ...requestOptions,
  })

  return req.json()
}

// Makes use-query snytax better
export const requestQuery =
  <T extends keyof Endpoints>(endpoint: keyof typeof endpoints & T, query?: Queries[T], requestOptions?: RequestInit) =>
  (): Promise<Endpoints[T]> => {
    return request(endpoint, query, requestOptions)
  }

const getUrl = <T extends keyof Queries>(url: string, query: Queries[T]) => {
  if (!query) {
    return url
  }

  const newQuery = {}

  // Replace placeholders in the URL with the query parameters and encode them, any other query parameters are added as query string at the end of the URL
  const encodedPath = Object.entries(query).reduce((acc, [key, value]) => {
    if (acc.includes(`:${key}`)) {
      return acc.replace(`:${key}`, encodeURIComponent(value as string))
    }

    newQuery[key] = value
    return acc
  }, url)

  const queryString = decodeURIComponent(new URLSearchParams(newQuery).toString())
  const fullUrl = queryString ? `${encodedPath}?${queryString}` : encodedPath

  return fullUrl
}
