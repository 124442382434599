import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { getMostUsedSkills, getSkills, SkillCategory, SkillCategorySkill } from 'lib/api/skills/skills'

interface ContextProps {
  children: JSX.Element
}

interface SkillsContextValue {
  filteredSkillCategories: SkillCategory[]
  filterCategoriesBySkillName: (searchTerm: string) => void
  isLoading: boolean
  otherSkills: SkillCategorySkill[]
  getSkillsByCategory: () => void
}

const SkillsContext = createContext({})

export function useSkillsContext(): SkillsContextValue {
  return useContext(SkillsContext) as SkillsContextValue
}

export default function SkillsProvider({ children }: ContextProps): JSX.Element {
  const [skillsByCategory, setSkillsByCategory] = useState<SkillCategory[]>([])
  const [filteredSkillCategories, setFilteredSkillCategories] = useState<SkillCategory[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const otherSkills = useMemo<SkillCategorySkill[]>(
    () =>
      skillsByCategory
        ?.flatMap((skillCategory) => skillCategory.skills)
        ?.filter((skill) => skill.name.toLowerCase().includes('other')) || [],
    [skillsByCategory]
  )
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getSkillsByCategory = useCallback(async () => {
    setIsLoading(true)
    try {
      const { skillCategories } = await getSkills()
      const { skills } = await getMostUsedSkills()

      if (skills.length > 0) {
        const mostUsedSkillCategory = {
          name: 'Most Used',
          skills,
        } as SkillCategory

        skillCategories.unshift(mostUsedSkillCategory)
      }

      if (mounted.current) {
        const enabledSkillCategories = skillCategories
          .map((skillCategory) => {
            skillCategory.skills = skillCategory?.skills?.filter((s) => s.enabled)
            return skillCategory
          })
          .filter((skillCategory) => skillCategory.skills?.length > 0)
        setSkillsByCategory(enabledSkillCategories)
        setFilteredSkillCategories(enabledSkillCategories)
      }
    } catch (e) {
      console.error('Skills could not be fetched.', e)
    } finally {
      if (mounted.current) {
        setIsLoading(false)
      }
    }
  }, [])

  function filterCategoriesBySkillName(searchTerm) {
    const lowercaseSearchTerm = searchTerm.toLowerCase()

    const resultSkillNames = []

    const newFilteredSkills = skillsByCategory
      .map((skillCategory) => {
        const newSkills = skillCategory.skills.filter(
          (skill) => skill.name.toLowerCase().includes(lowercaseSearchTerm) && !resultSkillNames.includes(skill.name)
        )

        resultSkillNames.push(...newSkills.map((skill) => skill.name))
        return { ...skillCategory, skills: newSkills }
      })
      .filter((skillCategory) => skillCategory.skills.length > 0)

    setFilteredSkillCategories(newFilteredSkills)
  }

  const context: SkillsContextValue = {
    filteredSkillCategories,
    filterCategoriesBySkillName,
    isLoading,
    otherSkills,
    getSkillsByCategory,
  }

  return <SkillsContext.Provider value={context}>{children}</SkillsContext.Provider>
}
