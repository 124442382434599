import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { requestQuery } from 'lib/api/fetch-api'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  ColumnDef,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table'
import { SearchTicket } from 'interfaces/ticket'
import { useEffect, useState } from 'react'
import { displayDate } from 'lib/util/date'
import UserAvatar from 'components/elements/user-avatar'
import MultiSelect from 'components/core/multi-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'

import SearchOverflowMenu from './overflow-menu'

const columnIds = {
  query: 'query',
  ticket_state: 'ticket_states[]',
  skill_name: 'skill_ids[]',
  brand_ids: 'brand_ids[]',
  user_ids: 'user_ids[]',
  designer_ids: 'designer_ids[]',
  ticket_updated_date: 'ticket_updated_date',
  format: 'format',
  actions: 'actions',
}

const defaultColumnVisibility = {
  [columnIds.query]: true,
  [columnIds.ticket_state]: true,
  [columnIds.skill_name]: true,
  [columnIds.brand_ids]: true,
  [columnIds.user_ids]: false,
  [columnIds.designer_ids]: true,
  [columnIds.ticket_updated_date]: true,
  [columnIds.format]: false,
  [columnIds.actions]: true,
}

const LOCAL_STORAGE_SEARCH_VISIBILITY_KEY = 'search-table-column-visibility'

const columns: ColumnDef<SearchTicket>[] = [
  {
    accessorKey: 'title',
    header: 'Title',
    id: columnIds.query,
    cell: ({ row }) => {
      return (
        <a className="tw-text-base tw-font-semibold" href={`/tickets/${row.original.ticket_id}`}>
          {row.original.title}
        </a>
      )
    },
    meta: {
      filterKey: 'query',
      filterVariant: 'search',
    },
  },
  {
    accessorKey: 'ticket_status',
    header: 'Status',
    id: columnIds.ticket_state,
    meta: {
      filterVariant: 'status',
      filterTitle: 'Status',
    },
  },
  {
    accessorKey: 'skill.name',
    header: 'Request Type',
    id: columnIds.skill_name,
    meta: {
      filterVariant: 'skill',
      filterTitle: 'Request Type',
    },
  },
  {
    accessorKey: 'brand.name',
    header: 'Brand',
    id: columnIds.brand_ids,
    meta: {
      filterVariant: 'brand',
      filterTitle: 'Brand',
    },
  },
  {
    accessorKey: 'ticket_created_by.name',
    header: 'Created By',
    id: columnIds.user_ids,
    meta: {
      filterVariant: 'createdBy',
      filterTitle: 'Created By',
    },
    cell: ({ row }) => {
      return <UserAvatar name={row.original.ticket_created_by.name} avatar={row.original.ticket_created_by.avatar} />
    },
  },
  {
    accessorKey: 'designer.name',
    header: 'Designer',
    cell: ({ row }) => {
      return <UserAvatar isDesigner={true} name={row.original.designer?.name} avatar={row.original.designer?.avatar} />
    },
  },
  {
    accessorKey: 'ticket_updated_at',
    header: 'Last Updated',
    cell: ({ row }) => {
      return displayDate(row.original.ticket_updated_at, false).toLowerCase()
    },
    meta: {
      filterTitle: 'updated',
      filterVariant: 'date',
      filterStartKey: 'ticket_updated_at_gte',
      filterEndKey: 'ticket_updated_at_lte',
    },
  },
  {
    accessorKey: 'format',
    header: 'Format',
    meta: {
      filterVariant: 'format',
      filterTitle: 'Format',
    },
    enableSorting: false,
  },
  {
    id: 'actions',
    enableSorting: false,
    cell: ({ cell }) => {
      return <SearchOverflowMenu ticket={cell.row.original} />
    },
    header: ({ table }) => {
      return (
        <MultiSelect
          searchable={false}
          button={() => <FontAwesomeIcon icon={['fas', 'sliders-h']} />}
          title="Columns"
          options={[
            { label: 'Request Type', value: 'skill_name' },
            { label: 'Brand', value: 'brand_name' },
            { label: 'Created By', value: 'user_id' },
            { label: 'Designer', value: 'designer_name' },
            { label: 'Last Updated', value: 'ticket_updated_at' },
            { label: 'Format', value: 'format' },
          ]}
          multi
          value={table.getVisibleFlatColumns().map((column) => column.id)}
          onChange={(selected) => {
            const visibility = table.getAllColumns().reduce((acc, column) => {
              acc[column.id] = selected.includes(column.id)
              return acc
            }, {})

            localStorage.setItem(LOCAL_STORAGE_SEARCH_VISIBILITY_KEY, JSON.stringify(visibility))

            table.setColumnVisibility(visibility)
          }}
          footer={
            <Button
              color="transparent"
              onClick={() => {
                localStorage.removeItem(LOCAL_STORAGE_SEARCH_VISIBILITY_KEY)
                table.setColumnVisibility(defaultColumnVisibility)
              }}
            >
              Restore defaults
            </Button>
          }
        />
      )
    },
  },
]

const DEFAULT_PAGE_SIZE = 20

const getFilters = () => {
  const url = new URL(window.location.href)
  const newFilters = []
  url.searchParams.forEach((value, key) => {
    if (key === 'page' || key === 'sort') {
      return
    }
    if (!value) {
      return
    }
    newFilters.push({
      id: key,
      value: value.split(','),
    })
  })

  return newFilters
}

const getSort = () => {
  const url = new URL(window.location.href)
  const sort = url.searchParams.get('sort')
  if (!sort) {
    return []
  }
  const [id, desc] = sort.split(':')
  return [{ id, desc: desc === 'desc' }]
}

export const useTable = () => {
  const [columnVisibility, setColumnVisibility] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_VISIBILITY_KEY)) || defaultColumnVisibility
  )

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(new URL(window.location.href).searchParams.get('page')) || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const [sorting, setSorting] = useState<SortingState>(getSort())

  const [columnFilters, setcolumnFilters] = useState<ColumnFiltersState>(getFilters())

  const query = useQuery({
    queryKey: ['search', pagination, columnFilters, sorting],
    queryFn: requestQuery('getSearch', {
      ...columnFilters.reduce((acc, filter) => {
        if (Array.isArray(filter.value)) {
          if (filter.value.length === 0) {
            return acc
          }
          acc[filter.id] = filter.value.join(',')
          return acc
        }
        acc[filter.id] = filter.value
        return acc
      }, {}),
      page: String(pagination.pageIndex),
      // Placeholder for sorting, waiting for backend to implement
      // sort: sorting.map((sort) => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`)[0], not used yet
    }),
    placeholderData: keepPreviousData,
  })

  useEffect(() => {
    const url = new URL(window.location.href)
    url.search = ''
    columnFilters.forEach((filter) => {
      if (Array.isArray(filter.value) && filter.value.length === 0) {
        return
      }
      url.searchParams.set(filter.id, String(filter.value))
    })
    url.searchParams.set('page', String(pagination.pageIndex))
    if (sorting.length > 0) {
      url.searchParams.set('sort', sorting.map((sort) => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`)[0])
    }
    url.search = decodeURIComponent(url.search)
    window.history.pushState({}, '', url.toString())
  }, [pagination.pageIndex, columnFilters, sorting])

  const table = useReactTable({
    columns,
    data: query?.data?.data || [],
    state: {
      pagination,
      columnFilters,
      columnVisibility,
      sorting,
    },
    maxMultiSortColCount: 1,
    onColumnFiltersChange: setcolumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
  })

  return {
    pagination,
    query,
    count: query?.data?.meta?.total,
    loading: query.isLoading,
    table,
    error: query.error,
  }
}
